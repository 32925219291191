

//const envision="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/news_img/envision_2024.webp"
const sih="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/news_img/sih.webp";
const project_demo="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/news_img/project_demo_build.webp"
const oracle="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/news_img/oracle.webp"
const edc="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/about/home/news_img/edc.webp"
const ga="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/about/home/news_img/ga.webp"
const nlp="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/about/home/news_img/nlp.webp"
const stnd_day="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/about/home/news_img/stnd_day.webp"
const ececover="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Ece/microchip.webp"
const nba_visit="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/about/home/news_img/NBA_visit.webp"
const build_workshop ="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/about/home/news_img/Build_workshop.webp"
const conference_iitmrp="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/about/home/news_img/Conference_iitmrp.webp"
const Nexora_pic ="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/about/home/news_img/Nexora_pic.webp"
const iste_workshop="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/about/home/news_img/iste_workshop.webp"


export const news_info=[
    // {
    //     'id':'9',
    //     'heading':"Tarcin MOU",
    //     'sub-head':'Memorandum of Understanding between ACGCET, Karaikudi and Garuda Aerospace Pvt.ltd, Chennai.',
    //     'pic':garuda,
    //     'content_1':'',
    //     'content_2':'',
    //     'butt_text':'viewpdf',
    //     'pdflink':'https://drive.google.com/file/d/11LFsCN6pOHrvZbtWlwkq12rl8sz8G_Ce/view?usp=sharing',
        
    // },
    // {
    //     'id':'8',
    //     'heading':"Tarcin Signature",
    //     'sub-head':'Memorandum of understanding is earned between Tarcin Robotic LLP, Madurai and ACGCET.',
    //     'pic':tarcinsign,
    //     'content_1':'',
    //     'content_2':'',
    //     'butt_text':'viewpdf',
    //     'pdflink':'https://drive.google.com/file/d/1aYah80F6D942XSe_jhtJNBbgULft3iKD/view?usp=sharing',
    // },
    {
        'id':'1',
        'heading':"ISTE Workshop",
        'sub-head':'Career Guidance Workshop for 1st year and 2nd year students.',
        'pic':iste_workshop,
        'content_1':'We are delighted to share the successful completion of the workshop on "Choice is yours: Postgraduate Studies, Government Jobs, Private Sector Careers, or Entrepreneurship" conducted on March 17, 2025, at the Virtual Hall of the EEE Department. The session was held by Dr.M.Manikandan M.E.,Ph.D, Co-founder of Maga Tech Gramam Pvt. Ltd..',
        'content_2':'The workshop was organized by the Indian Society for Technical Education (ISTE) and the speaker provided valuable guidance on the various career options available after completing a bachelor’s degree, helping students navigate the decision-making process.Through interactive discussions and real-world examples, participants gained clarity on choosing between pursuing higher education, preparing for government jobs, exploring opportunities in the private sector, or embarking on an entrepreneurial journey.',
        'butt_text':'knowmore',
    },
    {
        'id':'2',
        'heading':"NBA Visit",
        'sub-head':'NBA visit for Mechanical, Civil and EEE departments.',
        'pic':nba_visit,
        'content_1':'',
        'content_2':'A 3-member NBA committee visited our institution on 08-06-2024 to renew the NBA accreditation for Civil, Mechanical and EEE Departments.',
        'butt_text':'knowmore',
    },

    {
        'id':'3',
        'heading':"Oracle's AI/ML Mastery Session",
        'sub-head':'Oracle AI/ML Orientation Program: Navigating the Frontiers of Artificial Intelligence and Machine Learning',
        'pic':oracle,
        'content_1':'On January 27, 2024, Oracle, with a decade of expertise, conducted an immersive orientation program at our campus. The team from Oracle, Bangalore, led the session, delving into Artificial Intelligence and Machine Learning, providing hands-on practical sessions from 9:30 am to 6:00 pm. This firsthand experience allowed students to explore technology\'s forefront and gain insights from industry experts.',
        'content_2':'The Oracle orientation program not only immersed our students in the intricacies of Artificial Intelligence and Machine Learning but also provided them with hands-on practical sessions. This groundbreaking initiative, steered by the 1999 batch alumni, served as a transformative platform for our students to gain invaluable exposure to cutting-edge technologies.This Program includes informative lectures on Java, Oracle databases, and offers valuable insights for career development, providing participants with a holistic learning experience.It went beyond theoretical concepts, allowing them to delve into real-world applications and fostering a deeper understanding of the dynamic landscape of technology. A seamless blend of industry expertise and interactive sessions, this program opened new horizons for career growth and technological prowess.',
        'butt_text':'knowmore',
    },

    // {
    //     'id':'3',
    //     'heading':"Envision Conference at IITMRP",
    //     'sub-head':"Empowering Tomorrow's Leaders: Insights from the Envision Energy Conference at IITMRP",
    //     'pic':envision,
    //     // 'content_1':'A set of nine 2nd year students of our college recently had the opportunity to attend a dynamic conference themed "Envision Energy Conference" at IITMRP, on the dates 4th and 5th January, 2024, through the build club, which is being a great source of immense opportunities for our students. The conference proved to be an enlightening experience, that brought together professionals from diverse fields to exchange insights and ideas. The keynote sessions were particularly captivating, featuring industrial and educational delegates who shared their captivating and futuristic ideas. The interactive panel discussions were a highlight, allowing experts to engage in thought-provoking conversations. This groundbreaking conference not only provided valuable exposure to visionary ideas but also served as a platform for our students to broaden their understanding of the dynamic world around them',
    //     'content_2':'A set of nine 2nd year students of our college recently had the opportunity to attend a dynamic conference themed "Envision Energy Conference" at IITMRP, on the dates 4th and 5th January, 2024, through the build club, which is being a great source of immense opportunities for our students. The conference proved to be an enlightening experience, that brought together professionals from diverse fields to exchange insights and ideas. The keynote sessions were particularly captivating, featuring industrial and educational delegates who shared their captivating and futuristic ideas. The interactive panel discussions were a highlight, allowing experts to engage in thought-provoking conversations. This groundbreaking conference not only provided valuable exposure to visionary ideas but also served as a platform for our students to broaden their understanding of the dynamic world around them.',
    //     'butt_text':'knowmore',
    // },
    {
        'id':'4',
        'heading':" SIH winner",
        'sub-head':'Engineering Excellence: Triumph at Smart India Hackathon Hardware Edition 2023',
        'pic':sih,
        'content_1':'From our college , 5 students of mechanical engineering and 1 student of Electrical and Electronics Engineering ,3 rd years  won   prize in Smart India hackathon hardware edition 2023 conducted at  Nalla malla Reddy Engineering College , Hyderabad  from dec 19 to 23 ,2023 .',
        'content_2':'The 14.6 km long cable belt conveyor is supported on pulleys. A steel rope runs over these pulleys while the belt sits over them. The conveyor is operated by pulling the steel ropes with a drive motor. The rope gets elongated due to pulling force leading to breakage of its strands. Similarly, the belt also gets worn out causing stoppage of conveyor operation unpredictably. Solution Desired: By capturing the past reasons for wear and tear including visuals and using a suitable ML application, try to predict the condition of the Rope and belt well in advance such that corrective and preventive actions can be taken before its failure preventing loss of production.',
        'butt_text':'knowmore',
    },
    // {
    //     'id':'5',
    //     'heading':"General Aptitude Training",
    //     'sub-head':'Specialized weekend aptitude program for 60 third year students for academic & career success.',
    //     'pic':ga,
    //     'content_1':'',
    //     'content_2':"Commencing on the 9th of September, a specialized general aptitude training program has been organized for 60 third-year students over the weekends. Taking place on both Saturdays and Sundays, this initiative seeks to nurture the students' problem-solving skills and overall aptitude. By dedicating their weekends to this training, participants are preparing themselves for a competitive edge in their academic pursuits and future career endeavors. This comprehensive program is designed to instill valuable knowledge and capabilities, enabling these students to excel academically and navigate the complexities of the professional world with confidence. It serves as a valuable investment in their personal and intellectual growth."
    // },
    // {
    //     'id':'5',
    //     'heading':"World Standards Day, 2023",
    //     'sub-head':'Join us for an exhilarating journey of learning, unlearning and relearning on 09-10-2023.',
    //     'pic':stnd_day,
    //     'content_1':'Alagappa Chettiar Government College of Engineering and Technology, Sivaganga, hosts diverse events: writing, essay competitions, quizzes, debates, and nukkad natak. Winners eagerly await felicitation on World Standards Day in Madurai, a celebration of academic excellence and achievement.',
    //     'content_2':"A shared vision for a better world embodies the collective aspirations of individuals, communities, and nations to create a more equitable, sustainable, and harmonious global society. It is a beacon of hope that unites people across diverse backgrounds, inspiring collaboration and action. This vision encompasses ideals such as social justice, environmental stewardship, and peace, fostering a sense of responsibility to leave a lasting positive impact for future generations. It is a powerful force that drives humanity toward a brighter and more inclusive future."
    // },

    // {
    //     'id':'6',
    //     'heading':"NLP For 3rd years",
    //     'sub-head':"College's 3rd-year students benefited from 3-week NLP program sponsored by 1998 Alumni.",
    //     'pic':nlp,
    //     'content_1':'',
    //     'content_2':"The 3rd year students of our college attended a program called the Neuro- Linguistic Programming for three weeks on weekends from August 18,2023 to September 2,2023. This program consisted six engaging sessions and is related to communication and personality development. Our Alumni of 1998 batch sponsored for this program. A set of 32 students attended the program which was referred to as the 'Pilot Batch'. The students were greatly benefited by this program and a big thanks to our Alumni for arranging such a productive program for the students."
    // },

    {
        'id':'7',
        'heading':"Project Demonstration by Build Club",
        'sub-head':"College's 2nd year students from Build Club conducted Project Demonstration",
        'pic':project_demo,
        'content_1':'The IIT Madras Incubation Cell organized the "BUILD to INNOVATE" CHALLENGE (First Edition) for students in its partner institutes. This first edition featured challenges in the "Rural-Agri" space, providing a platform to introduce college students to innovating and building technology solutions for real-world problems, focusing on indigenous solutions for local challenges.',
        'content_2':'In this regard, our students (7 teams) participated in the preliminary round, and out of these, 3 teams were selected for the semi-finals, which took place at Thiagarajar College of Engineering, Madurai, on April 18, 2024. Following that, the Build Club of ACGCET hosted a Projects Demonstration Program as part of the "BUILD to INNOVATE CHALLENGE" on May 2, 2024, at the Corrosion Lab in Civil Engineering. First-year and second-year students attended the demonstration.',
        'butt_text':'knowmore',

    },

    {
        'id':'8',
        'heading':"Line Follower Robot Workshop – BuildClub ",
        'sub-head':"Workshop to equip students with hands-on knowledge of autonomous robotics.",
        'pic':build_workshop,
        'content_1':'BuildClub organized a Line Follower Robot Workshop to equip students with hands-on knowledge of autonomous robotics.This session provided participants with insights into sensor integration, motor control, and algorithm implementation, helping them understand the fundamentals of line-following robots.',
        'content_2':'Held on 20.02.25, the workshop served as a foundation for students who participated in Nexora on 27.02.25, enhancing their skills and confidence in tackling the Line Follower Robot Challenge.',
        'butt_text':'knowmore',
    },
    {
        'id':'9',
        'heading':"Envision Conference at IITMRP ",
        'sub-head':'Our Students recently had the opportunity to attend the "Envision Energy Conference" at IITMRP,',
        'pic':conference_iitmrp,
        'content_1':'A set of Ten 2nd and 3rd year students of our college recently had the opportunity to attend a dynamic conference themed "Envision Energy Conference" at IITMRP, on the dates 30th and 31st January  2025, through the build club, which is being a great source of immense opportunities for our students. The conference proved to be an enlightening experience, that brought together professionals from diverse fields to exchange insights and ideas',
        'content_2':'The keynote sessions were particularly captivating, featuring industrial and educational delegates who shared their captivating and futuristic ideas. The interactive panel discussions were a highlight, allowing experts to engage in thought-provoking conversations. This groundbreaking conference not only provided valuable exposure to visionary ideas but also served as a platform for our students to broaden their understanding of the dynamic world around them.',
        'butt_text':'knowmore',
    },
    {
        'id':'10',
        'heading':"Nexora",
        'sub-head':'Nexora – A Multi-Challenge Celebration of Innovation',
        'pic':Nexora_pic,
        'content_1':"Nexora brought together budding engineers and tech enthusiasts from first to third year, with nearly 40 teams competing in three distinct challenges that showcased their diverse skills and creativity.Element Explorer: A challenge in identifying and integrating missing elements to enhance analytical skills.\n"+
        "Ohm-Code-Oku: A Sudoku-inspired puzzle testing logical reasoning and interdisciplinary knowledge.\n"+
        "Line Follower Robot Challenge: Teams built and programmed robots to navigate a course using predefined paths.\n",     
        'content_2':'Held on 27.02.25 from 2:00 PM to 5:00 PM, Nexora was not just a competition—it was a vibrant platform for collaboration, learning, and innovation. The event has set a high standard for future BuildClub initiatives and continues to inspire students to explore the limitless possibilities of technology.',
        'butt_text':'knowmore',
    }
]