import React from 'react'
import { Underline } from '../../widgets';
import {
 
    sdg_coordinator,plannedactivities
  
  } from "../../constants/sdgclub";
  import { Listformat} from "../../components";

  import { Profilecard } from "../../widgets";
import './Sdgclub.css';
import sdg from '../../assets/pictures/sdg.png';
import sdg2 from '../../assets/pictures/sdg2.png';
const Sdgclub = () => {
  return (
    <div className='sdgclub'>
        <div className='sdg_acgcet'>
        <div className="sdg_heading1">
        <Underline heading="SDG club of ACGCET " />
        </div>
          <div className="sdg_heading">
                
            <p>The SDG Club at ACGCET was established to foster involvement of youth in social, economic and environmental initiatives at college level and sensitize students on the importance of sustainable living practices making them socially and environmentally responsible future citizen. It also promotes awareness and understanding of the United Nations' Sustainable Development Goals (SDGs) among students and faculty members, emphasizing their core themes and timely importance. </p>
            </div>
        </div>
        <div className="sdg_officers">
        <Underline heading="SDG Coordinators" />
        <div className="sdgholder">
          <div className="sdgcards">
            {sdg_coordinator.map((item) => (
              <Profilecard
                key={item.id}
                cover={item.cover}
                dp={item.dp}
                name={item.name}
                Designation={item.Designation}
                mailid={item.mailid}
              />
            ))}
          </div>
        </div>
      </div>
      <Underline heading="About Sustainable Development Goal"/>
      <div className='sdg_developement'>
       
        <p className='sdg_dev'>The Sustainable Development Goals (SDGs), adopted in 2015, build on the foundation of the Millennium Development Goals (MDGs), which were established in 2000 to address extreme poverty, education, health, and gender equality. While the MDGs focused primarily on developing countries, the SDGs are universal, applying to all nations and emphasizing a holistic approach to sustainability. The SDGs expand on the MDGs by incorporating broader issues like climate action, clean energy, innovation, and inequality. They aim to complete the unfinished agenda of the MDGs while addressing emerging global challenges, ensuring a more inclusive and sustainable future for all by 2030.</p>
     <div className='sdg_img'>
     <img src ={sdg} alt ="sdg"></img>
     </div>
     <p className='sdg_dev1'>The Sustainable Development Goals (SDGs) are a set of 17 global goals with 169 targets. The SDGs emphasize collaboration among governments, businesses, and individuals to create a more equitable and sustainable future. 
     The targets and indicators for each of the 17 SDGs offer precise and quantifiable benchmarks for gauging progress. The United Nations has called on all countries, regardless of economic level, to take action on the SDGs and collaborate to create prosperity while protecting the environment. They believe that actions in one area impact outcomes in other areas and that development must balance social, economic, and environmental sustainability. </p>
      </div>
      <div className='SDG_he'>
        <Underline heading="SDGs and Higher Education Institution"/>
        <p className='sdg_he_para'>
        Higher Education Institutions are crucial in achieving the Sustainable Development Goals (SDGs) through education, research, and innovation. They cultivate future leaders, promote sustainability, and develop solutions to global challenges. By integrating SDGs into curricula, fostering community engagement, and influencing policies, these institutions drive social, economic, and environmental progress, ensuring a more sustainable and equitable future for all. Their role in knowledge dissemination and capacity building is essential for lasting impact.
        </p>
      </div>
      <div className='sdgandaccget'>
        <Underline heading="ACGCET and SDGs"/>
        <p className='sdgandaccet'>
        ACGCET-Karaikudi is deeply engaged to global actions through its research and innovations. Faculty members and students have consistently undertaken responsible research and related activities, focusing on all three pillars of sustainability—social, economic, and environmental. The institution’s Scopus page dedicated to SDGs stands as clear evidence of our unwavering commitment to these global goals. Quality research publications have been made on almost all the goals. 
        </p>
        <div className='sdg_img2'>
        <img src ={sdg2} alt ="sdg"></img>
        </div>
      </div>
      <div className='sdg_planned'>
      <div className="ncccore">
        <Underline heading="Planned Activities" />
        <Listformat points={plannedactivities} />
      </div>
      </div>
    </div>
  )
}

export default Sdgclub