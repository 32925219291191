export const sidenav=[
  {
  id:"1",
  dashboard_path1:"/dashboard",
  dashboard_name1:"Profile",
},
{
  id:"2",
  dashboard_path1:"/results",
  dashboard_name1:"Result",
},
]

export const radmenu=[
  {
    id: "1",
    path: "/dashboard",
    link: "Profile",
    arl: "l",
  },
  {
    id: "2",
    path: "/results",
    link: "Result",
    arl: "l",
  },
]

export const subjects = {

  "CIV_subs": {
    "22HSE11": { "credits": 3, "subname": "Professional English-I" },
    "22MAB12": { "credits": 4, "subname": "Matrices and Calculus" },
    "22PHB14": { "credits": 3, "subname": "Engineering Physics" },
    "22CYB15": { "credits": 3, "subname": "Engineering Chemistry" },
    "22EEG16": { "credits": 3, "subname": "Basic Electrical, Electronics and Instrumentation Engineering" },
    "22HST17": { "credits": 1, "subname": "Heritage of Tamils" },
    "22EEL18": { "credits": 1.5, "subname": "Basic Electrical, Electronics and Instrumentation Engineering Laboratory" },
    "22PHL19": { "credits": 1.5, "subname": "Physics Laboratory" },
    "22CYL1A": { "credits": 1.5, "subname": "Chemistry Laboratory" },
    "22HSL1B": { "credits": 1.5, "subname": "English Laboratory" },
    "22HSE21": { "credits": 2, "subname": "Professional English-II" },
    "22MAB22": { "credits": 4, "subname": "Statistics and Numerical Methods" },
    "22PHB2A": { "credits": 4, "subname": "Physics for Civil Engineering" },
    "22CSG16": { "credits": 3, "subname": "Problem Solving and Python Programming" },
    "22MEG24": { "credits": 4, "subname": "Engineering Graphics" },
    "22HST26": { "credits": 1, "subname": "Tamils and Technology" },
    "22NCC01": { "credits": 2, "subname": "NCC Credit Course Level 1" },
    "22EPL27": { "credits": 2, "subname": "Engineering Practices Laboratory" },
    "22CSL18": { "credits": 1.5, "subname": "Problem Solving and Python Programming Laboratory" },
    "22HSL29": { "credits": 1.5, "subname": "Communication Laboratory / Foreign Language" },
    "22NM042": { "credits": 2, "subname": "Cambridge English Upskill Course (Naan Mudhalvan Course)" },
    "22MAB31": { "credits": 4, "subname": "Transforms and Partial Differential Equations" },
    "22CEG32": { "credits": 3, "subname": "Engineering Mechanics" },
    "22CEG33": { "credits": 3, "subname": "Fluid Mechanics" },
    "22CEC34": { "credits": 3, "subname": "Construction Materials and Technology" },
    "22CEC35": { "credits": 3, "subname": "Surveying I" },
    "22CEC36": { "credits": 3, "subname": "Water Supply Engineering" },
    "22CEL37": { "credits": 1.5, "subname": "Survey Laboratory I" },
    "22CEL38": { "credits": 1.5, "subname": "Computer Aided Building Drawing" },
    "22CEL39": { "credits": 1.5, "subname": "Environmental Engineering Laboratory" },
    "22CEZ3A": { "credits": 1, "subname": "Professional Development" },
    "22CEG41": { "credits": 4, "subname": "Solid Mechanics" },
    "22CEC42": { "credits": 3, "subname": "Highway Engineering" },
    "22CEC43": { "credits": 3, "subname": "Applied Hydraulics Engineering" },
    "22CEC44": { "credits": 3, "subname": "Waste Water Engineering" },
    "22CEC45": { "credits": 3, "subname": "Concrete Technology" },
    "22CYB46": { "credits": 2, "subname": "Environmental Sciences and Sustainability" },
    "22CEB47": { "credits": 3, "subname": "Surveying II" },
    "22CEL48": { "credits": 1.5, "subname": "Survey Laboratory II" },
    "22CEL49": { "credits": 1.5, "subname": "Strength of Materials Laboratory" },
    "22CEL4A": { "credits": 1.5, "subname": "Fluid Mechanics and Machinery Laboratory" },
    "22NCC02": { "credits": 3, "subname": "NCC Credit Course Level 2" },
    "22NM002": { "credits": 2, "subname": "Employability Skills (Nan Mudhalvan Course)" },
    "19MAB12": { "credits": 4, "subname": "Mathematics I" },
    "19CHB21": { "credits": 4, "subname": "Chemistry" },
    "19MAB22": { "credits": 4, "subname": "Mathematics II" },
    "19CEG31": { "credits": 4, "subname": "Solid Mechanics - I" },
    "19CEH31": { "credits": 3, "subname": "Professional Practices, Ethics and Building By-laws" },
    "19MAB31": { "credits": 4, "subname": "Mathematics-III" },
    "19CEC42": { "credits": 3, "subname": "Soil Mechanics" },
    "19CEG41": { "credits": 4, "subname": "Solid Mechanics - II" },
    "19CEL41": { "credits": 1.5, "subname": "Computer Aided Civil Engineering Drawing" },
    "19CEM41": { "credits": 0, "subname": "Disaster Management and Mitigation (Mandatory Course)" },
    "19CEC51": { "credits": 4, "subname": "Structural Analysis I" },
    "19CEC52": { "credits": 4, "subname": "Design of RC Elements" },
    "19CEC53": { "credits": 3, "subname": "Irrigation Engineering and Hydraulic Structures" },
    "19CEC54": { "credits": 3, "subname": "Environmental Engineering - I" },
    "19CEC55": { "credits": 3, "subname": "Foundation Engineering" },
    "19CEC61": { "credits": 4, "subname": "Structural Analysis II" },
    "19CEC62": { "credits": 3, "subname": "Environmental Engineering — II" },
    "19CEC63": { "credits": 4, "subname": "Design of Reinforced Concrete Structures and Masonry" },
    "19CEC64": { "credits": 3, "subname": "Applied Hydraulics and Fluid Machinery" },
    "19CEE65": { "credits": 3, "subname": "Design of Steel Structures" },
    "19CEL61": { "credits": 1.5, "subname": "Fluid Mechanics and Machinery Laboratory" },
    "19CEL62": { "credits": 1.5, "subname": "Irrigation and Environmental Engineering Drawing lab" },
    "19CEM61": { "credits": 0, "subname": "Constitution of India (Mandatory Course)" },
    "19CEZ51": { "credits": 0, "subname": "Soft Skills and Enhancement Training" },
    "19EEP01": { "credits": 3, "subname": "Renewable Energy Resources" },
    "19NM038": { "credits": 2, "subname": "Energy Efficient Buildings (Green Cement, Sustainability) (Naan Mudhalvan Course)" },
    "19SHA01": { "credits": 0, "subname": "Professional Communication (Audit Course)" },
    "19SHA02": { "credits": 0, "subname": "Error Analysis and Fundamental Physical Quantities (Audit Course)" },
    "NPTEL25": { "credits": 2, "subname": "Remote Sensing & GIS for Rural Development" }
  },
  
  "CSE_subs": {
    "19CMG15": { "credits": 4, "subname": "Basic Civil and Mechanical Engineering" },
    "19ENH11": { "credits": 3, "subname": "Communicative English" },
    "19MAB12": { "credits": 4, "subname": "Mathematics I" },
    "19MEL18": { "credits": 2, "subname": "Workshop Practice Laboratory" },
    "19PHB14": { "credits": 4, "subname": "Physics: Optics and Semiconductors" },
    "19PHL17": { "credits": 1.5, "subname": "Physics Laboratory" },
    "19CHB21": { "credits": 4, "subname": "Chemistry" },
    "19CHL26": { "credits": 1.5, "subname": "Chemistry Laboratory" },
    "19CSG23": { "credits": 3, "subname": "Python Programming" },
    "19CSL27": { "credits": 1.5, "subname": "Python Programming Laboratory" },
    "19EEG16": { "credits": 4, "subname": "Basic Electrical and Electronics Engineering" },
    "19MAB22": { "credits": 4, "subname": "Mathematics II" },
    "19SHA01": { "credits": 0, "subname": "Professional Communication" },
    "19SHA02": { "credits": 0, "subname": "Error Analysis and Fundamental Physical Quantities" },
    "19CSC31": { "credits": 3, "subname": "Programming and Data Structures" },
    "19CSC32": { "credits": 3, "subname": "Database Management Systems" },
    "19CSH31": { "credits": 3, "subname": "Professional Ethics and Human Values" },
    "19CSL31": { "credits": 1, "subname": "Programming and Data Structures Laboratory" },
    "19CSL32": { "credits": 1, "subname": "Database Management Systems Laboratory" },
    "19ECC43": { "credits": 3, "subname": "Microprocessor and Microcontroller" },
    "19MAB31": { "credits": 4, "subname": "Mathematics III" },
    "19NM018": { "credits": 2, "subname": "Microsoft Office Fundamentals (Naan Mudhalvan Course)" },
    "19CHM31": { "credits": 0, "subname": "Environmental Science and Engineering" },
    "19CSC41": { "credits": 3, "subname": "Advanced Data Structures" },
    "19CSC42": { "credits": 3, "subname": "Operating Systems" },
    "19CSC43": { "credits": 3, "subname": "Computer Organization and Architecture" },
    "19CSC44": { "credits": 3, "subname": "Design and Analysis of Algorithms" },
    "19CSG41": { "credits": 3, "subname": "Analog and Digital Communication" },
    "19CSL41": { "credits": 1, "subname": "Advanced Data Structures Laboratory" },
    "19CSL42": { "credits": 1, "subname": "Operating Systems Laboratory" },
    "19MAB43": { "credits": 4, "subname": "Discrete Mathematics" },
    "19NM023": { "credits": 2, "subname": "Foundation for AI, ML, FS (Naan Mudhalvan Course)" },
    "19NM024": { "credits": 2, "subname": "Network Essentials (Naan Mudhalvan Course)" },
    "19NM020": { "credits": 2, "subname": "Block Chain Technology (Naan Mudhalvan Course)" },
    "19CSC51": { "credits": 3, "subname": "Software Engineering" },
    "19CSC52": { "credits": 3, "subname": "Computer Networks" },
    "19CSC53": { "credits": 3, "subname": "Theory of Computation" },
    "19CSC54": { "credits": 3, "subname": "OOPS and Java Programming" },
    "19CSE16": { "credits": 3, "subname": "XML and Web Technology" },
    "19CSE53": { "credits": 3, "subname": "Internet of Things" },
    "19CSL51": { "credits": 1, "subname": "Networks Laboratory" },
    "19CSL52": { "credits": 1, "subname": "OOPS and Java Programming Laboratory" },
    "19CSZ51": { "credits": 1, "subname": "Technical Seminar - I" },
    "19MAB51": { "credits": 4, "subname": "Probability and Queuing Theory" },
    "19NM032": { "credits": 2, "subname": "Experience Based Practical Learning (Naan Mudhalvan Course)" },
    "19CEZ51": { "credits": 0, "subname": "Soft Skill Enhancement Training" },
    "19CSE17": { "credits": 3, "subname": "User Interface Design" },
    "19CSC61": { "credits": 3, "subname": "Artificial Intelligence" },
    "19CSC62": { "credits": 3, "subname": "Compiler Design" },
    "19CSC63": { "credits": 3, "subname": "Web Application Programming" },
    "19CSC64": { "credits": 3, "subname": "Object Oriented Analysis and Design" },
    "19CSC65": { "credits": 3, "subname": "Mobile and Pervasive Computing" },
    "19CSL61": { "credits": 1, "subname": "Compiler Design Laboratory" },
    "19CSL62": { "credits": 1, "subname": "CASE Tools Laboratory" },
    "19CSZ61": { "credits": 1, "subname": "Technical Seminar - II" },
    "19MEP04": { "credits": 3, "subname": "Principles of Management" },
    "19NM041": { "credits": 2, "subname": "Neural Networks and Deep Learning (Naan Mudhalvan Course)" },
    "19CSC71": { "credits": 3, "subname": "Graphics and Multimedia" },
    "19CSC72": { "credits": 3, "subname": "C# and .NET Technologies" },
    "19CSC73": { "credits": 3, "subname": "Cryptography and Network Security" },
    "19CSE32": { "credits": 3, "subname": "Grid and Cloud Computing" },
    "19CSL71": { "credits": 1, "subname": "Graphics and Multimedia Laboratory" },
    "19CSL72": { "credits": 1, "subname": "C# and .NET Laboratory" },
    "19CSZ71": { "credits": 3.5, "subname": "Mini Project" },
    "19MEP05": { "credits": 3, "subname": "Supply Chain Management" },
    "NPTEL90": { "credits": 2, "subname": "Software Quality Management" },
    "22IPM01": { "credits": 0, "subname": "Orientation Program" },
    "22CSC32": { "credits": 3, "subname": "Database Management Systems" },
    "22CSC33": { "credits": 3, "subname": "Data Structures using C" },
    "22CSC35": { "credits": 3, "subname": "Object Oriented Programming using Java" },
    "22CSC36": { "credits": 4, "subname": "Algorithms" },
    "22CSG31": { "credits": 4, "subname": "Digital Principles and Computer Organization" },
    "22CSL37": { "credits": 1.5, "subname": "Data Structures Laboratory" },
    "22CSL38": { "credits": 1.5, "subname": "Object Oriented Programming Laboratory" },
    "22CSL39": { "credits": 1.5, "subname": "Database Management Systems Laboratory" },
    "22CSZ3A": { "credits": 1, "subname": "Professional Development (Skill Based Course)" },
    "22MAB34": { "credits": 4, "subname": "Discrete Mathematics" },
    "22NM001": { "credits": 2, "subname": "Microsoft Essentials (Naan Mudhalvan Course)" },
    "22HSE11": { "credits": 3, "subname": "Professional English-I" },
    "22MAB13": { "credits": 4, "subname": "Matrices, Calculus and Laplace Transforms" },
    "22PHB14": { "credits": 3, "subname": "Engineering Physics" },
    "22CYB15": { "credits": 3, "subname": "Engineering Chemistry" },
    "22ENL17": { "credits": 1, "subname": "Professional Communication Laboratory" },
    "22PYL18": { "credits": 1.5, "subname": "Physics Laboratory" },
    "22CYL19": { "credits": 1.5, "subname": "Chemistry Laboratory" },
    "22MEL11": { "credits": 2, "subname": "Basic Mechanical Engineering" },
    "22WPL12": { "credits": 2, "subname": "Workshop Laboratory" },
    
      "22CSG16": { "credits": 3, "subname": "Problem Solving and Python Programming" },
      "22HST17": { "credits": 1, "subname": "Heritage of Tamils" },
      "22CSL18": { "credits": 1.5, "subname": "Problem Solving and Python Programming Laboratory" },
      "22PHL19": { "credits": 1.5, "subname": "Physics Laboratory" },
      "22CYL1A": { "credits": 1.5, "subname": "Chemistry Laboratory" },
      "22HSL1B": { "credits": 1.5, "subname": "English Laboratory" },
      "22HSE21": { "credits": 2, "subname": "Professional English-II" },
      "22MAB22": { "credits": 4, "subname": "Statistics and Numerical Methods" },
      "22PHB2E": { "credits": 4, "subname": "Physics for Information Science" },
      "22EEG16": { "credits": 3, "subname": "Basic Electrical, Electronics and Instrumentation Engineering" },
      "22MEG24": { "credits": 4, "subname": "Engineering Graphics" },
      "22CSC25": { "credits": 3, "subname": "Programming in C" },
      "22HST26": { "credits": 1, "subname": "Tamils and Technology" },
      "22NCC01": { "credits": 2, "subname": "NCC Credit Course Level 1" },
      "22EPL27": { "credits": 2, "subname": "Engineering Practices Laboratory" },
      "22CSL28": { "credits": 1.5, "subname": "Programming in C Laboratory" },
      "22HSL29": { "credits": 1.5, "subname": "Communication Laboratory / Foreign Language" },
      "22NM042": { "credits": 0, "subname": "Cambridge English Up skill course (Nan Mudhalvan course)" },
      "22CSC41": { "credits": 3, "subname": "Introduction to Operating Systems" },
      "22CSC42": { "credits": 3, "subname": "Computer Networks" },
      "22CSC43": { "credits": 3, "subname": "Theory of Computation" },
      "22CSC44": { "credits": 3, "subname": "Cryptography and Cyber Security" },
      "22CSC45": { "credits": 3, "subname": "Artificial Intelligence" },
      "22CYB46": { "credits": 2, "subname": "Environmental Sciences and Sustainability" },
      "22NCC02": { "credits": 3, "subname": "NCC Credit Course Level 2" },
      "22CSL47": { "credits": 1.5, "subname": "Operating Systems Laboratory" },
      "22CSL48": { "credits": 2, "subname": "Network and Cyber Security Lab" },
      "22CSL49": { "credits": 1.5, "subname": "Artificial Intelligence Lab" },
      "22NM003": { "credits": 2, "subname": "Experience based project learning (EBPL)(Naan Mudhalvan Course)" },
     
  },
  
  "EEE_subs": {
      "19CEZ51":{credits:0,subname:"Soft Skill Enhancement Training"},

      "19CMG15": {credits: 4,subname:"Basic Civil and Mechanical Engineering"},
      "19ENH11": {credits: 3,subname:"Communicative English"},
      "19MAB12": {credits: 4,subname:"Mathematics I"},
      "19MEL18": {credits: 2,subname:"Workshop Practice Laboratory"},
      "19PHB14": {credits: 4,subname:"Physics: Optics and Semiconductors"},
      "19PHL17": {credits: 1.5,subname:"Physics Laboratory"},

      "19CHL26": {credits:1.5,subname:"Chemistry Laboratory"},
      "19CSG23": {credits:3,subname:"Python Programming"},
      "19CSL27": {credits:1.5,subname:"Python Programming Laboratory"},
      "19MAB22": {credits:4,subname:"Mathematics II"},
      "19MEG24": {credits:3,subname:"Engineering Graphics" },
      "19SHA01": {credits:0,subname:"Professional Communication"},
      "19SHA02": {credits:0,subname:"Error Analysis and Fundamental Physical Quantities"},
      "19CHB21": {credits:4,subname:"Chemistry"},

      "19EEC31": {credits:4,subname:"Electric Circuit Analysis"},
    "19EEC32": {credits:3,subname:"Analog Electronics"},
    "19EEG31": {credits:3,subname:"Electromagnetic Theory"},
    "19EEG32": {credits:3,subname:"Measurements and Instrumentation"},
    "19EEL31": {credits:1.5,subname:"electric Circuits Laboratory"},
    "19EEL32": {credits:1.5,subname:"Analog Electronics Laboratory"},
    "19MAB31": {credits:4,subname:"Mathematics III"},
    "19NM018": {credits:2,subname:"Microsoft Office Fundamentals(Naan Mudhalvan Course)"},
    "19CHM31": {credits:0,subname:"Environmental Science and Engineering"},

    "19EEC41":{credits:4,subname:"D.C Machines and Transformers"},
    "19EEC42":{credits:4,subname:"Generation, Transmission and Distribution"},
    "19EEC43":{credits:3,subname:"Digital Logic Circuits"},
    "19EEC44":{credits:3,subname:"Analog Integrated Circuits and Appliations"},
    "19EEL41":{credits:1.5,subname:"D.C Machines and Transformers Laboratory"},
    "19EEL42":{credits:1.5,subname:"Analog and Digital Integrated Circuits Laboratory"},
    "19MAB41":{credits:3,subname:"Numerical Methods"},
    "19NM019": {credits:2,subname:"Robotic Process and Industrial Automation (Naan Mudhalvan Course)"},

    "19EEG51":{credits:4,subname:"Control System"},
    "19EEC51":{credits:4,subname:"AC Machines"},
    "19EEC52":{credits:3,subname:"Analysis of Signals and Systems"},
    "19EEP05":{credits:3,subname:"Internet of Things And its Applications"},
    "19EEE07":{credits:3,subname:"Electrical Energy Utilization and conservation"},
    "19EEE18":{credits:3,subname:"Electrical Safety Management and Energy Audit (FT)"},
    "19EEL51":{credits:1.5,subname:"Control and Instrumentation Laboratory"},
    "19EEL52":{credits:1.5,subname:"A.C Machines Laboratory"},
    "19EEZ51":{credits:3,subname:"Mini Project"},
      
    "19EEZ61":{credits:4,subname:"Power Systems Analysis"},
    "19EEC61":{credits:4,subname:"Power Electronics"},
    "19EEC62":{credits:3,subname:"Microcontrollers"},
    "19EEC63":{credits:1.5,subname:"Technical Seminar"},
    "19EEE03":{credits:3,subname:"High Voltage Engineering"},
    "19EEL61":{credits:1.5,subname:"Power Electronics Laboratory"},
    "19EEL62":{credits:1.5,subname:"Microcontroller Laboratory"},
    "19NM099":{credits:2,subname:"Artificial Intelligence and Machine Learning Fundamentals (Naan Mudhalvan Course)"},
    
      "19EEE16": { "credits": 3, "subname": "Embedded System Design" },
      "19EEE21": { "credits": 3, "subname": "Power System Operation and Control" },
    
    

      "22HSE21": { credits: 2, subname: "Professional English-II" },
      "22MAB22": { credits: 4, subname: "Statistics and Numerical Methods" },
      "22PHB2C": { credits: 4, subname: "Physics for Electrical Engineering" },
      "22CMG23": { credits: 3, subname: "Basic Civil and Mechanical Engineering" },
      "22MEG24": { credits: 4, subname: "Engineering Graphics" },
      "22EEC25": { credits: 4, subname: "Electric Circuit Analysis" },
      "22NCC01": { credits: 2, subname: "NCC Credit Course Level 1" },
      "22HST26": { credits: 1, subname: "Tamils and Technology" },
      "22EPL27": { credits: 2, subname: "Engineering Practices Laboratory" },
      "22EEL28": { credits: 1.5, subname: "Electric Circuit Laboratory" },
      "22HSL29": { credits: 1.5, subname: "Communication Laboratory/Foreign Language" },

      "22HSE11": {credits: 3, subname: "Professional English-I"},
    "22MAB13": {credits: 4, subname: "Matrices, Calculus and Laplace Transforms"},
    "22PHB14": {credits: 3, subname: "Engineering Physics"},
    "22CYB15": {credits: 3, subname: "Engineering Chemistry"},
    "22CSG16": {credits: 3, subname: "Problem Solving and Python Programming"},
    "22HST17": {credits: 1, subname: "தமிழர் மரபு / Heritage of Tamils"},
    "22CSL18": {credits: 1.5, subname: "Problem Solving and Python Programming Laboratory"},
    "22PHL19": {credits: 1.5, subname: "Physics Laboratory"},
    "22CYL1A": {credits: 1.5, subname: "Chemistry Laboratory"},
    "22HSL1B": {credits: 1.5, subname: "English Laboratory"},
    "22NM042": { credits: 0, subname: "Cambridge English Up skillcourse(Nan Mudhalvan course)" },

    "22MAB32": {credits: 4, subname: "Probability Complex Functions and Fourier Series"},
    "22ECG31": {credits: 4, subname: "Signals and Systems"},
    "22EEC33": {credits: 4, subname: "Electrical Machines - I"},
    "22EEC34": {credits: 3, subname: "Electronic Devices and Circuits"},
    "22EEC35": {credits: 3, subname: "Electromagnetic Fields"},
    "22EEG36": {credits: 3, subname: "C Programming and Data Structures"},
    "22EEL37": {credits: 1.5, subname: "Electronic Devices and Circuits Laboratory"},
    "22EEL38": {credits: 1.5, subname: "C Programming and Data Structures Laboratory"},
    "22EEL3A": {credits: 1.5, subname: "Electrical Machines Laboratory - I"},
    "22EEZ39": {credits: 1, subname: "Professional Development (Skill Based Course)"},

    "22EEC41": { credits: 4, subname: "Electrical Machines - II" },
    "22ECG43": { credits: 3, subname: "Linear Integrated Circuits" },
    "22EEC44": { credits: 3, subname: "Measurements and Instrumentation" },
    "22EEC45": { credits: 3, subname: "Microprocessor and Microcontroller" },
    "22CYB46": { credits: 2, subname: "Environmental Sciences and Sustainability" },
    "22NCC02": { credits: 3, subname: "NCC Credit Course Level 2" },
    "22EEL47": { credits: 1.5, subname: "Electrical Machines Laboratory – II" },
    "22EEL48": { credits: 1.5, subname: "Linear and Digital Integrated Circuits Laboratory" },
    "22EEL49": { credits: 1.5, subname: "Microprocessor and Microcontroller Laboratory" },
    "22EEC42": { credits: 3, subname: "Digital Logic Circuits" },
    },
  
    "ECE_subs": {
      "19MAB12": { "credits": 4, "subname": "Mathematics I" },
      "19MAB22": { "credits": 4, "subname": "Mathematics II" },
      "19MAB31": { "credits": 4, "subname": "Mathematics III" },
      "19MAB42": { "credits": 4, "subname": "Probability and Random Processes" },
      "19MAB13": { "credits": 4, "subname": "Matrices, Calculus and Laplace Transforms" },
      "19MAB33": { "credits": 4, "subname": "Random Processes and Linear Algebra" },
      "19CMG15": { "credits": 4, "subname": "Basic Civil and Mechanical Engineering" },
      "19ENH11": { "credits": 3, "subname": "Communicative English" },
      "19PHB14": { "credits": 4, "subname": "Physics: Optics and Semiconductors" },
      "19PHL17": { "credits": 1.5, "subname": "Physics Laboratory" },
      "19CHB21": { "credits": 4, "subname": "Chemistry" },
      "19CHL26": { "credits": 1.5, "subname": "Chemistry Laboratory" },
      "19CSG23": { "credits": 3, "subname": "Python Programming" },
      "19CSL27": { "credits": 1.5, "subname": "Python Programming Laboratory" },
      "19EEG25": { "credits": 3, "subname": "Basic Electrical Engineering" },
      "19ECC31": { "credits": 3, "subname": "Digital Electronics" },
      "19ECC32": { "credits": 3, "subname": "Electronic Circuits-I" },
      "19ECG31": { "credits": 4, "subname": "Signals and Systems" },
      "19ECL31": { "credits": 1, "subname": "Electron Devices and Circuits Laboratory" },
      "19ECL32": { "credits": 1, "subname": "Digital Electronics Laboratory" },
      "19ECC41": { "credits": 3, "subname": "Electronic Circuits-II" },
      "19ECC42": { "credits": 3, "subname": "RF Transmission Lines" },
      "19ECC43": { "credits": 3, "subname": "Microprocessor and Microcontroller" },
      "19ECH41": { "credits": 3, "subname": "Professional Ethics and Human Values" },
      "19ECL41": { "credits": 1, "subname": "Electronic Circuits and Simulation Laboratory" },
      "19ECL42": { "credits": 1, "subname": "Microprocessor and Microcontroller Laboratory" },
      "19EEG51": { "credits": 4, "subname": "Control Systems" },
      "19NM018": { "credits": 2, "subname": "Microsoft Office Fundamentals(Naan Mudhalvan Course)" },
      "19NM019": { "credits": 2, "subname": "Robotic Process and Industrial Automation (Naan Mudhalvan Course)" },
      "19NM020": { "credits": 2, "subname": "Block Chain Technology (Naan Mudhalvan Course)" },
      "19NM021": { "credits": 2, "subname": "Digital Marketing (Naan Mudhalvan Course)" },
      "19ECE12": { "credits": 3, "subname": "Mobile and Wireless Communication" },
      "19ECE14": { "credits": 3, "subname": "Speech Processing" },
      "19ECP04": { "credits": 3, "subname": "Automobile Electrical and Electronics Systems" },
      "19ECL71": { "credits": 1.5, "subname": "Microwave and Optical Engineering Laboratory" },
      "19ECL72": { "credits": 1, "subname": "Cyber Physical Systems Laboratory" },
      "19ECZ71": { "credits": 4, "subname": "Mini Project" },
      "19ECC51": { "credits": 4, "subname": "Digital Signal Processing" },
      "19ECC52": { "credits": 3, "subname": "Linear Integrated Circuits" },
      "19ECC53": { "credits": 3, "subname": "Communication Theory" },
      "19ECC54": { "credits": 3, "subname": "Antennas and Wave Propagation" },
      "19MEH41": { "credits": 3, "subname": "Total Quality Management" },
      "19ECE01": { "credits": 3, "subname": "Network Analysis & Synthesis" },
      "19ECE03": { "credits": 3, "subname": "Measurements and Instrumentation" },
      "19ECE04": { "credits": 3, "subname": "Image Processing" },
      "19ECE06": { "credits": 3, "subname": "Medical Electronics" },
      "19ECL51": { "credits": 1, "subname": "Digital Signal Processing Laboratory" },
      "19ECL52": { "credits": 1, "subname": "Linear Integrated Circuits Laboratory" },
      "19ECZ51": { "credits": 1, "subname": "Technical Seminar I" },
      "19NM040": { "credits": 2, "subname": "4G/5G Communication  Networks (Naan Mudhalvan Course)" },
      "19ECB32": { "credits": 4, "subname": "Time Harmonic Electromagnetic Fields" },
      "19CEZ51": { "credits": 0, "subname": "Soft Skills and Enhancement Training" },
      "19CSC52": { "credits": 3, "subname": "Computer Networks" },
      "19ECC61": { "credits": 3, "subname": "Digital Communication  System" },
      "19ECC62": { "credits": 3, "subname": "VLSI Design" },
      "19ECE05": { "credits": 3, "subname": "Consumer Electronics" },
      "19ECE09": { "credits": 3, "subname": "Optoelectronic Devices" },
      "19ECE11": { "credits": 3, "subname": "Radar and Navigational  Aids" },
      "19ECE13": { "credits": 3, "subname": "Satellite Communication" },
      "19ECE26": { "credits": 3, "subname": "Wireless Networks" },
      "19ECE27": { "credits": 3, "subname": "Network Security" },
      "19ECL61": { "credits": 1, "subname": "Analog and Digital Communication  Laboratory" },
      "19ECL62": { "credits": 1, "subname": "VLSI and Network Programming Laboratory" },
      "19ECM61": { "credits": 0, "subname": "Indian Constitution (Mandatory Course)" },
      "19ECZ61": { "credits": 1, "subname": "Technical Seminar  - II" },
      "NPTEL29": { "credits": 3, "subname": "Embedded Sensing, Actuation and Interfacing Systems" },
      "22HSE11": { "credits": 3, "subname": "Professional English-I" },
      "22PHB14": { "credits": 3, "subname": "Engineering Physics" },
      "22CYB15": { "credits": 3, "subname": "Engineering Chemistry" },
      "22CSG16": { "credits": 3, "subname": "Problem Solving and Python Programming" },
      "22HST17": { "credits": 1, "subname": "தமிழர் மரபு / Heritage of Tamils" },
      "22CSL18": { "credits": 1.5, "subname": "Problem Solving and Python Programming Laboratory" },
      "22PHL19": { "credits": 1.5, "subname": "Physics Laboratory" },
      "22CYL1A": { "credits": 1.5, "subname": "Chemistry Laboratory" },
      "22HSE21": { "credits": 2, "subname": "Professional English – II" },
      "22MAB22": { "credits": 4, "subname": "Statistics and Numerical Methods" },
      "22PHB2D": { "credits": 4, "subname": "Physics for Electronics Engineering" },
      "22EEG16": { "credits": 3, "subname": "Basic Electrical, Electronics and Instrumentation Engineering" },
      "22HST26": { "credits": 1, "subname": "Tamils and Technology" },
      "22NCC01": { "credits": 2, "subname": "NCC Credit Course Level 1" },
      "22EPL27": { "credits": 2, "subname": "Engineering Practices Laboratory" },
      "22ECL28": { "credits": 1.5, "subname": "Circuits Analysis Laboratory" },
      "22ECC41": { "credits": 3, "subname": "Transmission Lines and RF Systems" },
      "22ECC42": { "credits": 4, "subname": "Networks and Security" },
      "22ECC43": { "credits": 3, "subname": "Linear Integrated Circuits" },
      "22ECC44": { "credits": 4, "subname": "Digital Signal Processing" },
      "22ECC45": { "credits": 3, "subname": "Communication Systems" },
      "22CYB46": { "credits": 2, "subname": "Environmental Sciences and Sustainability" },
      "22NCC02": { "credits": 3, "subname": "NCC Credit Course Level 2" },
      "22ECL47": { "credits": 1.5, "subname": "Communication Systems Laboratory" },
      "22ECL48": { "credits": 1.5, "subname": "Linear Integrated Circuits Laboratory" },
      "22ECG51": { "credits": 4, "subname": "Control Systems" },
      "22ECC52": { "credits": 3, "subname": "VLSI and Chip Design" },
      "22ECC53": { "credits": 4, "subname": "Wireless Communication" },
      "22ECV41": { "credits": 3, "subname": "Wearable Devices" },
      "22ECV82": { "credits": 3, "subname": "Wireless Sensor Networks" },
      "22ECL51": { "credits": 1, "subname": "Control Systems Laboratory" },
      "22ECL52": { "credits": 1, "subname": "VLSI Laboratory" },
      "22ECZ71": { "credits": 2, "subname": "Technical Seminar II" },
      "22ECP10": { "credits": 1.5, "subname": "Internship" },
      "22ECZ61": { "credits": 3, "subname": "Project Work" },
      "22NM003": { "credits": 2, "subname": "Experience based project learning (EBPL)(Naan Mudhalvan Course)" },
      "22MAB13": { "credits": 4, "subname": "Matrices, Calculus and Laplace Transforms" },
    "22HSL1B": { "credits": 1.5, "subname": "English Laboratory" },
    "22MEG24": { "credits": 4, "subname": "Engineering Graphics" },
    "22ECC25":{ "credits": 4, "subname": "Circuit Analysis" },
    "22HSL29": { "credits": 1.5, "subname": "Communication Laboratory / Foreign Language" },
    "22NM042": { "credits": 2, "subname": "Cambridge English Upskill Course (Naan Mudhalvan Course)" },
    "22ECC31": { "credits": 4, "subname": "Signals and Systems" },
    "22ECC32": { "credits": 4, "subname": "Digital Systems Design" },
    "22MAB33": { "credits": 4, "subname": "Random Processes and Linear Algebra" },
    "22ECC34": { "credits": 3, "subname": "Electronic Devices and Circuits" },
    "22ECC35": { "credits": 3, "subname": "Electromagnetic Field Theory" },
    "22ECG36": { "credits": 3, "subname": "C Programming and Data Structures" },
    "22ECL37": { "credits": 1.5, "subname": "Electronic Devices and Circuits" },
    "22ECL38": { "credits": 1.5, "subname": "C Programming and Data StructuresLaboratory"},
    "22ECZ39": { "credits": 1, "subname": "Professional Development"},
    "NPTEL14": { "credits": 3, "subname": "Programming in JAVA"},
    
    
    },
  
  

  "MEC_subs":{
    "19MAB12": { "credits": 4, "subname": "Mathematics I" },
    "19PHB13": { "credits": 4, "subname": "Physics: Properties of Materials and Mechanics" },
    "19CHB21": { "credits": 4, "subname": "Chemistry" },
    "19CSG23": { "credits": 3, "subname": "Python Programming" },
    "19MAB22": { "credits": 4, "subname": "Mathematics II" },
    "19MEG24": { "credits": 3, "subname": "Engineering Graphics" },
    "19MAB32": { "credits": 4, "subname": "Partial Differential Equation & Numerical Methods" },
    "19MEB32": { "credits": 3, "subname": "Engineering Materials & Metallurgy" },
    "19MEC31": { "credits": 3, "subname": "Thermodynamics" },
    "19MEC32": { "credits": 3, "subname": "Fluid Mechanics" },
    "19MEC33": { "credits": 3, "subname": "Manufacturing Technology - I" },
    "19MEG31": { "credits": 3, "subname": "Applied Mechanics" },
    "19MEM31": { "credits": 0, "subname": "Yoga for Human Excellence (Mandatory Course)" },
    "19MEC41": { "credits": 3, "subname": "Mechanics of Solids" },
    "19MEC42": { "credits": 3, "subname": "Theory of Machines - I" },
    "19MEC43": { "credits": 3, "subname": "Thermal Engineering" },
    "19MEC44": { "credits": 3, "subname": "Manufacturing Technology - II" },
    "19MEE13": { "credits": 3, "subname": "Automobile Engineering" },
    "19MEE17": { "credits": 3, "subname": "Welding and Costing Process" },
    "19MEH41": { "credits": 3, "subname": "Total Quality Management" },
    "19EEP01": { "credits": 3, "subname": "Renewable Energy Resources" },
    "19MEC51": { "credits": 3, "subname": "Design of Machine Elements" },
    "19MEC52": { "credits": 3, "subname": "Theory of Machines-II" },
    "19MEC53": { "credits": 3, "subname": "Fluid Machinery" },
    "19MEE15": { "credits": 3, "subname": "Process Planning and Cost Estimation" },
    "19MEE26": { "credits": 3, "subname": "Industrial Engineering and Management" },
    "19MEH51": { "credits": 3, "subname": "Engineering Economics and Cost Analysis" },
    "19MEL51": { "credits": 1.5, "subname": "Theory of Machines Laboratory" },
    "19MEL52": { "credits": 1.5, "subname": "Fluid Mechanics and Machinery Laboratory" },
    "19CEZ51": { "credits": 0, "subname": "Soft Skills and Enhancement Training" },
    "19MEC61": { "credits": 3, "subname": "Design of Mechanical Drives" },
    "19MEC62": { "credits": 3, "subname": "Heat and Mass Transfer" },
    "19MEC63": { "credits": 3, "subname": "Mechatronics" },
    "19MEE08": { "credits": 3, "subname": "Design of Electric Vehicles" },
    "19MEE21": { "credits": 3, "subname": "Modern Machining Processes" },
    "19MEE24": { "credits": 3, "subname": "Non Destructive Testing and Evaluation" },
    "19MEH61": { "credits": 3, "subname": "Operation Research" },
    "19MEL61": { "credits": 1.5, "subname": "Heat and Mass Transfer Laboratory" },
    "19MEL62": { "credits": 1.5, "subname": "Automation Laboratory" },
    "19MEP03": { "credits": 3, "subname": "Professional Ethics for Engineers" },
    "19MEP04": { "credits": 3, "subname": "Principles of Management" },
    "19MEZ61": { "credits": 3, "subname": "Design and Fabrication Project" },
    "19NM039": { "credits": 2, "subname": "Artificial Intelligence and Machine Learning Fundamentals (Naan Mudhalvan Course)" },
    "22HSE11": { "credits": 3, "subname": "Professional English-I" },
    "22MAB12": { "credits": 4, "subname": "Matrices and Calculus" },
    "22PHB14": { "credits": 3, "subname": "Engineering Physics" },
    "22CYB15": { "credits": 3, "subname": "Engineering Chemistry" },
    "22EEG16": { "credits": 3, "subname": "Basic Electrical, Electronics and Instrumentation Engineering" },
    "22HST17": { "credits": 1, "subname": "தமிழர் மரபு / Heritage of Tamils" },
    "22EEL18": { "credits": 1.5, "subname": "Basic Electrical, Electronics and Instrumentation Engineering Laboratory" },
    "22PHL19": { "credits": 1.5, "subname": "Physics Laboratory" },
    "22CYL1A": { "credits": 1.5, "subname": "Chemistry Laboratory" },
    "22HSL1B": { "credits": 1.5, "subname": "English Laboratory" },
    "22HSE21": { "credits": 2, "subname": "Professional English-II" },
    "22MAB22": { "credits": 4, "subname": "Statistics and Numerical Methods" },
    "22PHB2B": { "credits": 4, "subname": "Material Science" },
    "22CSG16": { "credits": 3, "subname": "Problem Solving and Python Programming" },
    "22MEG24": { "credits": 4, "subname": "Engineering Graphics" },
    "22HST26": { "credits": 1, "subname": "தமிழரும் தொழில்நுட்பமும் / Tamils and Technology" },
    "22NCC01": { "credits": "2#", "subname": "NCC Credit Course Level 1 #" },
    "22EPL27": { "credits": 2, "subname": "Engineering Practices Laboratory" },
    "22CSL18": { "credits": 1.5, "subname": "Problem Solving and Python Programming Laboratory" },
    "22HSL29": { "credits": 1.5, "subname": "Communication Laboratory / Foreign Language" },
    "22NM042": { "credits": 0, "subname": "Cambridge English Upskill course (Nan Mudhalvan course)" },
    "22MAB31": { "credits": 4, "subname": "Transforms and Partial Differential Equations" },
    "22MEG32": { "credits": 3, "subname": "Engineering Mechanics" },
    "22MEC33": { "credits": 3, "subname": "Engineering Thermodynamics" },
    "22MEG34": { "credits": 4, "subname": "Fluid Mechanics and Machinery" },
    "22MEC35": { "credits": 3, "subname": "Engineering Materials and Metallurgy" },
    "22MEC36": { "credits": 3, "subname": "Manufacturing Processes" },
    "22MEL37": { "credits": 2, "subname": "Computer Aided Machine Drawing" },
    "22MEL38": { "credits": 2, "subname": "Manufacturing Technology Laboratory" },
    "22MEZ39": { "credits": 1, "subname": "Professional Development" },
    "22MEC41": { "credits": 4, "subname": "Theory of Machines" },
    "22MEC42": { "credits": 4, "subname": "Thermal Engineering" },
    "22MEC43": { "credits": 3, "subname": "Hydraulics and Pneumatics" },
    "22MEC44": { "credits": 3, "subname": "Manufacturing Technology" },
    "22MEC45": { "credits": 3, "subname": "Strength of Materials" },
    "22CYM46": { "credits": 3, "subname": "Environmental Science and Engineering" },
    "22MEL47": { "credits": 2, "subname": "Thermal Engineering Laboratory" },
    "22MEL48": { "credits": 2, "subname": "Fluid Mechanics and Machinery Laboratory" },
    "22MEZ49": { "credits": 1, "subname": "Soft Skills and Career Development" },
    "22NM002": { "credits": 2, "subname": "Employability Skills (Nan Mudhalvan Course)" },
    "22CYB46": { "credits": 2, "subname": "Environmental Sciences and Sustainability" },
    "22NCC02": { "credits": 3, "subname": "NCC Credit Course Level 2" }
  }
  ,
  
};

export const subject_current={
  "19ENH11": {
      "credits": 3,
      "subname": "Communicative English"
  },
  "19MAB12": {
      "credits": 4,
      "subname": "Mathematics I"
  },
  "22ENC14": {
      "credits": 3,
      "subname": "Air Pollution and Control"
  },
  "22PGM11": {
      "credits": 3,
      "subname": "Research Methodology and IPR"
  },
  "19EEG16": {
      "credits": 4,
      "subname": "Basic Electrical and Electronics Engineering"
  },
  "19PHB13": {
      "credits": 4,
      "subname": "Physics : Properties of Materials and Mechanics"
  },
  "19PHB14": {
      "credits": 4,
      "subname": "Physics : Optics and Semiconductors"
  },
  "22MAC11": {
      "credits": 4,
      "subname": "Applied Statistics and Probability in Environmental Engineering"
  },
  "22MAC12": {
      "credits": 4,
      "subname": "Analytical and Numerical Methods for Structural Engineering "
  },
  "22CDC12": {
      "credits": 3,
      "subname": "Computer Applications in Design"
  },
  "22MAC13": {
      "credits": 4,
      "subname": "Applied Mathematics for Electrical Engineers"
  },
  "22MAC14": {
      "credits": 4,
      "subname": "Applied Mathematics for Communication Engineers"
  },
  "22ENE01": {
      "credits": 3,
      "subname": "Remote sensing and GIS for Environmental Applications"
  },
  "22STC13": {
      "credits": 3,
      "subname": "Advanced Solid Mechanics"
  },
  "22CDC13": {
      "credits": 3,
      "subname": "Vibration Analysis and Control"
  },
  "22PEC12": {
      "credits": 3,
      "subname": "Advanced Power Semiconductor Devices"
  },
  "22MCC12": {
      "credits": 3,
      "subname": "Field Theory of Guided Waves"
  },
  "22ENE03": {
      "credits": 3,
      "subname": "Water and Sewage Conveyance"
  },
  "22STC14": {
      "credits": 3,
      "subname": "Advanced Structural Analysis"
  },
  "22CDC14": {
      "credits": 3,
      "subname": "Concepts of Engineering Design"
  },
  "22PEC14": {
      "credits": 3,
      "subname": "Analysis of Power Converters"
  },
  "22MCC13": {
      "credits": 3,
      "subname": "Laser and its Applications"
  },
  "22ENC12": {
      "credits": 3,
      "subname": "Environmental Chemistry"
  },
  "22STE02": {
      "credits": 3,
      "subname": "Advanced Concrete Technology"
  },
  "22CDE14": {
      "credits": 3,
      "subname": "Reverse Engineering"
  },
  "22PEC15": {
      "credits": 3,
      "subname": "Modeling and Analysis of Electrical Machines"
  },
  "22MCC15": {
      "credits": 3,
      "subname": "Non-Linear Fiber Optics"
  },
  "22ENC13": {
      "credits": 3,
      "subname": "Environmental Microbiology    "
  },
  "22STE03": {
      "credits": 3,
      "subname": "Theory of Structural Stability"
  },
  "22CDE21": {
      "credits": 3,
      "subname": "Design of Hydraulics and Pneumatic System"
  },
  "22PEE09": {
      "credits": 3,
      "subname": "Solar Photovoltaic Systems"
  },
  "22MCC16": {
      "credits": 3,
      "subname": "RF Circuits Design"
  },
  "22HSE11": {
      "credits": 3,
      "subname": "Professional English I "
  },
  "22MAB12": {
      "credits": 4,
      "subname": "Matrices and Calculus"
  },
  "22MAB13": {
      "credits": 4,
      "subname": "Matrices,Calculus and Laplace Transforms"
  },
  "22PHB14": {
      "credits": 3,
      "subname": "Engineering Physics"
  },
  "22CYB15": {
      "credits": 3,
      "subname": "Engineering Chemistry"
  },
  "22EEG16": {
      "credits": 3,
      "subname": "Basic Electrical,Electronics and Instrumentation Engineering"
  },
  "22HST17": {
      "credits": 1,
      "subname": "தமிழர் மரபு / Heritage of Tamils"
  },
  "22MNE22": {
      "credits": 3,
      "subname": "Addictive Manufacturing"
  },
  "22ENC21": {
      "credits": 3,
      "subname": "Solid and Hazardous Waste Management"
  },
  "22STC21": {
      "credits": 3,
      "subname": "Advanced Steel Design"
  },
  "22STE10": {
      "credits": 3,
      "subname": "Advanced Design of Foundation"
  },
  "22ENC22": {
      "credits": 4,
      "subname": "Physical and Chemical Treatment of water and wastewater"
  },
  "22STC22": {
      "credits": 3,
      "subname": "Structural Dynamics"
  },
  "22ENC23": {
      "credits": 3,
      "subname": "Biological Treatment of Waste water"
  },
  "22STC24": {
      "credits": 3,
      "subname": "Design of Advanced Concrete Structures"
  },
  "19MAB22": {
      "credits": 4,
      "subname": "Mathematics II"
  },
  "22HSE21": {
      "credits": 2,
      "subname": "Professional English-II"
  },
  "22ENE11": {
      "credits": 3,
      "subname": "Membrane Technologies for Water and Wastewater Treatment"
  },
  "22HST26": {
      "credits": 1,
      "subname": "தமிழரும் தொழில்நுட்பமும் / Tamils and Technology"
  },
  "22MEG24": {
      "credits": 4,
      "subname": "Engineering Graphics"
  },
  "22PHB2A": {
      "credits": 4,
      "subname": "Physics for Civil Engineering"
  },
  "22PHB2B": {
      "credits": 4,
      "subname": "Materials Science"
  },
  "22PHB2C": {
      "credits": 4,
      "subname": "Physics for Electrical Engineering "
  },
  "22PHB2D": {
      "credits": 4,
      "subname": "Physics for Electronics Engineering"
  },
  "22PHB2E": {
      "credits": 4,
      "subname": "Physics for Information Science"
  },
  "22MAB22": {
      "credits": 4,
      "subname": "Statistics and Numerical Methods"
  },
  "19CSG23": {
      "credits": 3,
      "subname": "Python Programming"
  },
  "22CMG23": {
      "credits": 3,
      "subname": "Basic Civil and Mechanical Engineering"
  },
  "22ECC25": {
      "credits": 4,
      "subname": "Circuit Analysis"
  },
  "22CSC25": {
      "credits": 3,
      "subname": "Programming in C"
  },
  "19MEG24": {
      "credits": 3,
      "subname": "Engineering Graphics"
  },
  "22EEC25": {
      "credits": 4,
      "subname": "Electric Circuit Analysis"
  },
  "22CSG16": {
      "credits": 3,
      "subname": "Problem Solving and Python Programming"
  },
  "19CSC31": {
      "credits": 3,
      "subname": "Programming and Data Structures"
  },
  "22ENE07": {
      "credits": 3,
      "subname": "Instrumental Monitoring of Environment"
  },
  "22STE14": {
      "credits": 3,
      "subname": "Corrosion Engineering"
  },
  "22MNC31": {
      "credits": 3,
      "subname": "Fluid Power Control and Automation"
  },
  "22MCC31": {
      "credits": 3,
      "subname": "Visible Light Communication"
  },
  "19MEC32": {
      "credits": 3,
      "subname": "Fluid Mechanics"
  },
  "19CSC32": {
      "credits": 3,
      "subname": "Database Management Systems"
  },
  "19CHM31": {
      "credits": 3,
      "subname": "Environmetal Science and Engineering "
  },
  "19ECC31": {
      "credits": 3,
      "subname": "Digital Electronics"
  },
  "22ENE12": {
      "credits": 3,
      "subname": "Environmental Impact Assessment"
  },
  "22STE20": {
      "credits": 3,
      "subname": "Maintenance and Rehabilitation of Structures"
  },
  "22MNE10": {
      "credits": 3,
      "subname": "Diagnostic Techniques"
  },
  "22PEE10": {
      "credits": 3,
      "subname": "Special Machines Controllers"
  },
  "22MCE34": {
      "credits": 3,
      "subname": "Electromagnetic Band Gap Structures"
  },
  "19CEH31": {
      "credits": 3,
      "subname": "Professional Practices, Ethics and Building By-laws"
  },
  "22CDP06": {
      "credits": 3,
      "subname": "Conversion of Waste to Energy"
  },
  "22ENP01": {
      "credits": 3,
      "subname": "Ecological Engineeering"
  },
  "22MCE3A": {
      "credits": 3,
      "subname": "mm Wave Communication"
  },
  "19CEG31": {
      "credits": 4,
      "subname": "Solid Mechanics I"
  },
  "19CEB33": {
      "credits": 3,
      "subname": "Science of Building Materials"
  },
  "22MNE29": {
      "credits": 3,
      "subname": "Processing of Polymer Materials"
  },
  "19CSH31": {
      "credits": 3,
      "subname": "Professional Ethics and Human Values"
  },
  "19ECB32": {
      "credits": 4,
      "subname": "Time Harmonic Electronmagnetic Fields"
  },
  "19MAB31": {
      "credits": 4,
      "subname": "Mathematics-III"
  },
  "19MAB32": {
      "credits": 4,
      "subname": "Partial Differential Equation & Numerical Methods"
  },
  "19MAB43": {
      "credits": 4,
      "subname": "Discrete Mathematics"
  },
  "19CEC31": {
      "credits": 3,
      "subname": "Surveying-I"
  },
  "22MAB31": {
      "credits": 4,
      "subname": "Transforms and Partial Differential Equations"
  },
  "22MAB32": {
      "credits": 4,
      "subname": "Probability,Complex functions and Fourier Series"
  },
  "22MAB33": {
      "credits": 4,
      "subname": "Random Processes and Linear Algebra"
  },
  "22MAB34": {
      "credits": 4,
      "subname": "Discrete Mathematics"
  },
  "19ECG31": {
      "credits": 4,
      "subname": "Signals and Systems"
  },
  "22CEG33": {
      "credits": 3,
      "subname": "Fluid Mechanics"
  },
  "19EEG32": {
      "credits": 3,
      "subname": "Measurements and Instrumentation"
  },
  "22ECC31": {
      "credits": 4,
      "subname": "Signals and Systems"
  },
  "22ECG31": {
      "credits": 4,
      "subname": "Signals and Systems"
  },
  "22CSG31": {
      "credits": 4,
      "subname": "Digital Principles  and Computer Organisation "
  },
  "19MEE30": {
      "credits": 3,
      "subname": "Power Plant Engineering"
  },
  "22CEC34": {
      "credits": 3,
      "subname": "Construction Materials and Technology"
  },
  "22MEC33": {
      "credits": 3,
      "subname": "Engineering Thermodynamics "
  },
  "22EEC33": {
      "credits": 4,
      "subname": "Electrical Machines - I"
  },
  "22ECC32": {
      "credits": 4,
      "subname": "Digital Systems Design"
  },
  "22CSC32": {
      "credits": 3,
      "subname": "Database Management Systems"
  },
  "22MEVA3": {
      "credits": 3,
      "subname": "Casting and Welding Processes"
  },
  "22CEC35": {
      "credits": 3,
      "subname": "Surveying - I"
  },
  "22MEG34": {
      "credits": 4,
      "subname": "Fluid Mechanics and Machinery"
  },
  "22EEC34": {
      "credits": 3,
      "subname": "Electronic Devices and circuits"
  },
  "22ECC35": {
      "credits": 3,
      "subname": "Electromagnetic Field Theory"
  },
  "22CSC33": {
      "credits": 3,
      "subname": "Datastructures Using C"
  },
  "22CEC36": {
      "credits": 3,
      "subname": "Water Supply Engineering"
  },
  "22MEC35": {
      "credits": 3,
      "subname": "Engineering Materials and Metallurgy"
  },
  "22EEG36": {
      "credits": 3,
      "subname": "C Programming & Data Structures"
  },
  "22ECG36": {
      "credits": 3,
      "subname": "C Programming & Data Structures"
  },
  "22CSC35": {
      "credits": 3,
      "subname": "Object Oriented Programming using Java"
  },
  "22CEG32": {
      "credits": 3,
      "subname": "Engineering Mechanics"
  },
  "22EEC35": {
      "credits": 3,
      "subname": "Electromagnetic Fields"
  },
  "22CSC36": {
      "credits": 4,
      "subname": "Algorithms"
  },
  "19MEE20": {
      "credits": 3,
      "subname": "Industrial Robotics and Automation "
  },
  "19CSC41": {
      "credits": 3,
      "subname": "Advanced Data Structures"
  },
  "19CSC42": {
      "credits": 3,
      "subname": "Operating Systems"
  },
  "19CSC43": {
      "credits": 3,
      "subname": "Computer Organization and Architecture"
  },
  "19MAB42": {
      "credits": 4,
      "subname": "Probability and Random Process"
  },
  "19CSC44": {
      "credits": 3,
      "subname": "Design and Analysis of Algorithms"
  },
  "19ECC42": {
      "credits": 3,
      "subname": "RF Transmission Lines"
  },
  "19CEG41": {
      "credits": 4,
      "subname": "Solid Mechanics II"
  },
  "19CSG41": {
      "credits": 3,
      "subname": "Analog and Digital Communication"
  },
  "19CEG42": {
      "credits": 4,
      "subname": "Fluid Mechanics"
  },
  "19CEC42": {
      "credits": 3,
      "subname": "Soil Mechanics"
  },
  "19MEC42": {
      "credits": 3,
      "subname": "Theory of Machines I"
  },
  "19ECC43": {
      "credits": 3,
      "subname": "Microprocessor and Microcontroller"
  },
  "19ECC41": {
      "credits": 3,
      "subname": "Electronic Circuits II"
  },
  "19EEC42": {
      "credits": 4,
      "subname": "Generation Transmission and Distribution"
  },
  "19MAB41": {
      "credits": 4,
      "subname": "Numerical Methods"
  },
  "19MEE17": {
      "credits": 3,
      "subname": "Welding and Casting Process"
  },
  "22CEB47": {
      "credits": 3,
      "subname": "Surveying II "
  },
  "22MEC41": {
      "credits": 3,
      "subname": "Theory of Machines"
  },
  "22CEC42": {
      "credits": 3,
      "subname": "Highway Engineering"
  },
  "22CSC41": {
      "credits": 3,
      "subname": "Introduction to Operating Systems"
  },
  "22CEC43": {
      "credits": 3,
      "subname": "Applied Hydraulics Engineering"
  },
  "22MEC43": {
      "credits": 3,
      "subname": "Hydraulics and Pneumatics"
  },
  "22EEC41": {
      "credits": 4,
      "subname": "Electrical Machines -II"
  },
  "22ECC42": {
      "credits": 4,
      "subname": "Networks and Security "
  },
  "22CSC42": {
      "credits": 3,
      "subname": "Computer Networks"
  },
  "22CEC44": {
      "credits": 3,
      "subname": "Waste Water Engineering"
  },
  "22ECC44": {
      "credits": 4,
      "subname": "Digital Signal processing"
  },
  "22CSC43": {
      "credits": 3,
      "subname": "Theory of Computation"
  },
  "22CEC45": {
      "credits": 3,
      "subname": "Concrete Technology"
  },
  "22EEC44": {
      "credits": 4,
      "subname": "Measurements and Instrumentation"
  },
  "22ECC45": {
      "credits": 3,
      "subname": "Communication systems"
  },
  "22CSC44": {
      "credits": 3,
      "subname": "Cryptography and Cyber Security"
  },
  "22CEG41": {
      "credits": 4,
      "subname": "Solid Mechanics"
  },
  "22CYB46": {
      "credits": 2,
      "subname": "Environmental Sciences and Sustainability"
  },
  "22ECC43": {
      "credits": 3,
      "subname": "Linear Integrated Circuits"
  },
  "22ECG43": {
      "credits": 3,
      "subname": "Linear Integrated Circuits"
  },
  "22MEC45": {
      "credits": 3,
      "subname": "Strength of Materials"
  },
  "22CSC45": {
      "credits": 3,
      "subname": "Artificial Intelligence"
  },
  "22ECC41": {
      "credits": 3,
      "subname": "Transmission Lines and RF systems"
  },
  "22MEC42": {
      "credits": 4,
      "subname": "Thermal Engineering"
  },
  "19MEE10": {
      "credits": 3,
      "subname": "Finite Element Analysis"
  },
  "19ECE03": {
      "credits": 3,
      "subname": "Measurements and Instrumentation"
  },
  "19CEC51": {
      "credits": 4,
      "subname": "Structural Analysis I"
  },
  "19ECC51": {
      "credits": 4,
      "subname": "Digital Signal Processing"
  },
  "19CEC54": {
      "credits": 3,
      "subname": "Environmental Engineering I"
  },
  "19CEC52": {
      "credits": 4,
      "subname": "Design of RC Elements"
  },
  "19CEC53": {
      "credits": 3,
      "subname": "Irrigation Engineering and Hydraulic Structures"
  },
  "19ECE06": {
      "credits": 3,
      "subname": "Medical Electronics"
  },
  "19CSC53": {
      "credits": 3,
      "subname": "Theory of Computation"
  },
  "19ECC53": {
      "credits": 3,
      "subname": "Communication Theory"
  },
  "19CSE53": {
      "credits": 3,
      "subname": "Internet of Things"
  },
  "19CEC55": {
      "credits": 3,
      "subname": "Foundation Engineering"
  },
  "19MEH51": {
      "credits": 3,
      "subname": "Engineering Economics and Cost Analysis"
  },
  "19EEE18": {
      "credits": 3,
      "subname": "Electrical Safety Management and Energy Audit"
  },
  "19ECC54": {
      "credits": 3,
      "subname": "Antennas and Wave Propagation"
  },
  "19MEE15": {
      "credits": 3,
      "subname": "Process Palnning and Cost Estimation "
  },
  "19ECC52": {
      "credits": 3,
      "subname": "Linear Integrated Circuits"
  },
  "19CSC54": {
      "credits": 3,
      "subname": "OOPS and Java Programming"
  },
  "19EEC52": {
      "credits": 3,
      "subname": "Analysis of Signals and Systems"
  },
  "19EEG51": {
      "credits": 4,
      "subname": "Control Systems"
  },
  "19MAB51": {
      "credits": 4,
      "subname": "Probability and Queuing Theory"
  },
  "22CEC51": {
      "credits": 4,
      "subname": "Structural Analysis-I"
  },
  "22MEC51": {
      "credits": 3,
      "subname": "Design of Machine Elements"
  },
  "22EEC51": {
      "credits": 4,
      "subname": "Linear Control Systems"
  },
  "22ECG51": {
      "credits": 4,
      "subname": "Control System"
  },
  "22CSC51": {
      "credits": 3,
      "subname": "Foundations of Data Science"
  },
  "19EEP01": {
      "credits": 3,
      "subname": "Renewable Energy Resources "
  },
  "22CEC52": {
      "credits": 3,
      "subname": "Design of RC Elements"
  },
  "22MEC52": {
      "credits": 3,
      "subname": "Metrology and Measurements"
  },
  "22EEC52": {
      "credits": 3,
      "subname": "Power Electronics"
  },
  "22ECC52": {
      "credits": 3,
      "subname": "VLSI and Chip Design"
  },
  "19CSC52": {
      "credits": 3,
      "subname": "Computer Networks"
  },
  "22CSC52": {
      "credits": 3,
      "subname": "Complier Design"
  },
  "22MEVA1": {
      "credits": 3,
      "subname": "Advanced Vehicle Engineering"
  },
  "22CEV52": {
      "credits": 3,
      "subname": "Traffic Engineering and Management"
  },
  "22MEV22": {
      "credits": 3,
      "subname": "Additive Manufacturing"
  },
  "22MEV87": {
      "credits": 3,
      "subname": "Refrigeration and Air Conditioning"
  },
  "22EEC53": {
      "credits": 3,
      "subname": "Generation,Transmission and Distribution"
  },
  "22ECC53": {
      "credits": 4,
      "subname": "Wireless Communication"
  },
  "22CSC53": {
      "credits": 3,
      "subname": "Introduction to Machine Learning "
  },
  "22MEV23": {
      "credits": 3,
      "subname": "CAD/CAM "
  },
  "22MEV86": {
      "credits": 3,
      "subname": "Power Plant Engineering"
  },
  "22MEV93": {
      "credits": 3,
      "subname": "Industrial Safety"
  },
  "22EEV11": {
      "credits": 3,
      "subname": "Utilization and Conservation of Electrical Energy "
  },
  "22ECV41": {
      "credits": 3,
      "subname": "Wearable Devices"
  },
  "22CSC54": {
      "credits": 3,
      "subname": "Distributed Computing"
  },
  "22CEV13": {
      "credits": 3,
      "subname": "Prefabricated Structures"
  },
  "22MEV24": {
      "credits": 3,
      "subname": "Design For X "
  },
  "22MEV83": {
      "credits": 3,
      "subname": "Design Concepts in Engineering"
  },
  "22MEVA5": {
      "credits": 3,
      "subname": "Surface Engineering "
  },
  "22EEV41": {
      "credits": 3,
      "subname": "Electric Vehicle Architecture "
  },
  "22ECV82": {
      "credits": 3,
      "subname": "Wireless Broad Band Networks"
  },
  "22CSV24": {
      "credits": 3,
      "subname": "UI and UX Design"
  },
  "22EEV65": {
      "credits": 3,
      "subname": "Internet of Things and its Applications"
  },
  "22ECV63": {
      "credits": 3,
      "subname": "Wireless Sensor Network Design"
  },
  "22ECV74": {
      "credits": 3,
      "subname": "Satellite Communication"
  },
  "22CSV31": {
      "credits": 3,
      "subname": "Cloud Computing"
  },
  "19CEE65": {
      "credits": 3,
      "subname": "Design of Steel Structures"
  },
  "19MEE08": {
      "credits": 3,
      "subname": "Design of Electric Vehicles "
  },
  "19ECE13": {
      "credits": 3,
      "subname": "Satellite Communication"
  },
  "19CSE18": {
      "credits": 3,
      "subname": "Information Security"
  },
  "19CEE64": {
      "credits": 3,
      "subname": "Municipal Solid Waste Management"
  },
  "19MEE31": {
      "credits": 3,
      "subname": "Regrigeration And Air Conditioning "
  },
  "19EEE03": {
      "credits": 3,
      "subname": "High Voltage Engineering"
  },
  "19EEC63": {
      "credits": 3,
      "subname": "Microcontrollers"
  },
  "19CSC64": {
      "credits": 3,
      "subname": "Object Oriented Analysis and Design"
  },
  "19ECE26": {
      "credits": 3,
      "subname": "Wireless Network"
  },
  "19CSC65": {
      "credits": 3,
      "subname": "Mobile and Pervasive Computing"
  },
  "19CEC61": {
      "credits": 4,
      "subname": "Structural Analysis-II"
  },
  "19CSE17": {
      "credits": 3,
      "subname": "User Interface Design"
  },
  "19EEE12": {
      "credits": 3,
      "subname": "Distributed Generation Systems"
  },
  "19CEC63": {
      "credits": 4,
      "subname": "Design of RC Structures and Masonry "
  },
  "19CSE29": {
      "credits": 3,
      "subname": "Cyber Forensics"
  },
  "19ECC61": {
      "credits": 3,
      "subname": "Digital Communication System"
  },
  "19ECC62": {
      "credits": 3,
      "subname": "VLSI Design"
  },
  "19CSC61": {
      "credits": 3,
      "subname": "Artificial Intelligence"
  },
  "19EEE16": {
      "credits": 3,
      "subname": "Embedded System Design"
  },
  "19MEH61": {
      "credits": 3,
      "subname": "Operation Research "
  },
  "19ECE05": {
      "credits": 3,
      "subname": "Consumer Electronics"
  },
  "19EEC61": {
      "credits": 4,
      "subname": "Power System Analysis"
  },
  "19EEE21": {
      "credits": 3,
      "subname": "Power System Operation and Control"
  },
  "19CSC63": {
      "credits": 3,
      "subname": "Web Application Programming"
  },
  "19CSC62": {
      "credits": 3,
      "subname": "Compiler Design"
  },
  "19MEC63": {
      "credits": 3,
      "subname": "Mechatronics"
  },
  "19MEP04": {
      "credits": 3,
      "subname": "Principles of Management "
  },
  "19CEH71": {
      "credits": 3,
      "subname": "Construction Management"
  },
  "19ECC71": {
      "credits": 3,
      "subname": "RF and Microwave Electronics"
  },
  "19CSC71": {
      "credits": 3,
      "subname": "Graphics and Multimedia"
  },
  "19EEE02": {
      "credits": 3,
      "subname": "Solid State Drives"
  },
  "19CEC71": {
      "credits": 3,
      "subname": "Estimation & Costing"
  },
  "19MEE01": {
      "credits": 3,
      "subname": "Computer Aided Design"
  },
  "19MEE16": {
      "credits": 3,
      "subname": "Smart Manufacturing "
  },
  "19ECC72": {
      "credits": 3,
      "subname": "Optical Communication and Networks"
  },
  "19CSC72": {
      "credits": 3,
      "subname": "C # and . NET Technologies"
  },
  "19MEE25": {
      "credits": 3,
      "subname": "Lean Manufacturing"
  },
  "19CEE75": {
      "credits": 3,
      "subname": "Air Pollution Monitoring and Control"
  },
  "19ECC73": {
      "credits": 3,
      "subname": "Cyber Physical Systems"
  },
  "19CSC73": {
      "credits": 3,
      "subname": "Cryptography and Network Security"
  },
  "19MEP01": {
      "credits": 3,
      "subname": "Industrial Safety"
  },
  "19EEE04": {
      "credits": 3,
      "subname": "Flexible AC Transmission Systems"
  },
  "19CEE72": {
      "credits": 3,
      "subname": "Railways, Airports, Docks and Harbours"
  },
  "19ECP04": {
      "credits": 3,
      "subname": "Automobile Electrical & Electronics System"
  },
  "19EEE19": {
      "credits": 3,
      "subname": "PLC and SCADA"
  },
  "19EEE22": {
      "credits": 3,
      "subname": "Progammable Logic Controllers"
  },
  "19CSE26": {
      "credits": 3,
      "subname": "Software Quality Management"
  },
  "19CSE32": {
      "credits": 3,
      "subname": "Grid and Cloud Computing"
  },
  "19CEE52": {
      "credits": 3,
      "subname": "Concrete Technology"
  },
  "19EEE24": {
      "credits": 3,
      "subname": "Solar and Wind Energy Conservation Systems "
  },
  "19EEE25": {
      "credits": 3,
      "subname": "Electric Vehicles"
  },
  "19CSE48": {
      "credits": 3,
      "subname": "Software Testing"
  },
  "19MEP02": {
      "credits": 3,
      "subname": "New and Renewable Source of Energy"
  },
  "19CSP01": {
      "credits": 3,
      "subname": "Fundamentals of Computing"
  },
  "19ECE17": {
      "credits": 3,
      "subname": "Audio Video Broadcasting System"
  },
  "19CEE83": {
      "credits": 3,
      "subname": "Construction Techniques and Equipments"
  },
  "19CHB21": {
      "credits": 4,
      "subname": "Chemistry"
  },
  "19EEC32": {
      "credits": 3,
      "subname": "Analog Electronics"
  },
  "19EEC31": {
      "credits": 4,
      "subname": "Electric Circuit Analysis"
  },
  "19EEG31": {
      "credits": 3,
      "subname": "Electromagnetic Theory"
  },
  "22PEC31": {
      "credits": 4,
      "subname": "Electric Vehicles and Power Management"
  },
  "19EEC41": {
      "credits": 4,
      "subname": "DC Machines and Transformers"
  },
  "19EEC43": {
      "credits": 3,
      "subname": "Digital Logic Circuits"
  },
  "19EEC44": {
      "credits": 3,
      "subname": "Analog Integrated Circuits and Applications"
  },
  "22EEC42": {
      "credits": 3,
      "subname": "Digital Logic Circuits"
  },
  "19MEC33": {
      "credits": 3,
      "subname": "Manufacturing Technology I"
  },
  "22ECC34": {
      "credits": 3,
      "subname": "Electron Devices and Circuits"
  },
  "19MEB32": {
      "credits": 3,
      "subname": "Engineering Materials and Metallurgy"
  },
  "19MEG31": {
      "credits": 3,
      "subname": "Applied Mechanics"
  },
  "22MEG32": {
      "credits": 3,
      "subname": "Engineering Mechanics"
  },
  "19MEC31": {
      "credits": 3,
      "subname": "Thermodynamics"
  },
  "22MEC36": {
      "credits": 3,
      "subname": "Manufacturing Processes"
  },
  "19CEC41": {
      "credits": 3,
      "subname": "Highway Engineering"
  },
  "19EEC51": {
      "credits": 4,
      "subname": "A.C.Machines"
  },
  "19CEC43": {
      "credits": 3,
      "subname": "Surveying II"
  },
  "19MEC44": {
      "credits": 3,
      "subname": "Manufacturing Technology II"
  },
  "22EEC45": {
      "credits": 3,
      "subname": "Microprocessor and Microcontroller"
  },
  "19MEC43": {
      "credits": 3,
      "subname": "Thermal Engineering"
  },
  "19MEH41": {
      "credits": 3,
      "subname": "Total Quality Management "
  },
  "19MEC41": {
      "credits": 3,
      "subname": "Mechanics of Solids "
  },
  "22MEC44": {
      "credits": 3,
      "subname": "Manufacturing Technology"
  },
  "19CEC62": {
      "credits": 3,
      "subname": "Environmental Engineering II"
  },
  "19EEC62": {
      "credits": 4,
      "subname": "Power Electronics"
  },
  "19MEC71": {
      "credits": 3,
      "subname": "Engineering Metrology and Measurement"
  },
  "19MEE13": {
      "credits": 3,
      "subname": "Automobile Engineering"
  },
  "19EEE06": {
      "credits": 3,
      "subname": "Power Quality"
  },
  "22CEC53": {
      "credits": 3,
      "subname": "Soil Mechanics"
  },
  "19MEC51": {
      "credits": 3,
      "subname": "Design of Machine Elements"
  },
  "19MEC53": {
      "credits": 3,
      "subname": "Fluid Machinery"
  },
  "19MEC52": {
      "credits": 3,
      "subname": "Theory of Machines - II"
  },
  "19EEE07": {
      "credits": 3,
      "subname": "Electrical Energy Utilization and Conservation"
  },
  "19ECE27": {
      "credits": 3,
      "subname": "Network Security"
  },
  "19EEE09": {
      "credits": 3,
      "subname": "Solar PV Systems"
  },
  "19EEC72": {
      "credits": 3,
      "subname": "Power System Protection and Switchgear"
  },
  "19MEE26": {
      "credits": 3,
      "subname": "Industrial Engineering and Management"
  },
  "19MEE21": {
      "credits": 3,
      "subname": "Modern Machining Processes"
  },
  "19MEC61": {
      "credits": 3,
      "subname": "Design of Mechanical Drives "
  },
  "19EEE08": {
      "credits": 3,
      "subname": "Biomedical Instrumentation"
  },
  "19MEE24": {
      "credits": 3,
      "subname": "Non-Destructive Testing and Evaluation"
  },
  "19ECE12": {
      "credits": 3,
      "subname": "Moblie and Wireless Communication "
  },
  "19CEC64": {
      "credits": 3,
      "subname": "Applied Hydraulics and Fluid Machinery"
  },
  "19ECE09": {
      "credits": 3,
      "subname": "Optoelectronic Devices"
  },
  "19MEP03": {
      "credits": 3,
      "subname": "Professional Ethics for Engineers"
  },
  "19MEC62": {
      "credits": 3,
      "subname": "Heat and Mass Transfer"
  },
  "22ENC24": {
      "credits": 3,
      "subname": "Industrial waste water Management"
  },
  "19ECC32": {
      "credits": 3,
      "subname": "Electronic Circuits - I"
  },
  "19CEL71": {
      "credits": 1,
      "subname": "Design and Drawing for RC and Steel Structures with Software Applications"
  },
  "19CEZ61": {
      "credits": 1,
      "subname": "Industrial Internship"
  },
  "19CEZ71": {
      "credits": 3,
      "subname": "Project Work I"
  },
  "19CEZ72": {
      "credits": 1,
      "subname": "Comprehensive Objective Type Examination"
  },
  "19NM043": {
      "credits": 2,
      "subname": "Structural Design, Modelling and Detailing (Naan Mudhalvan Course)"
  },
  "19NM048": {
      "credits": 2,
      "subname": "IGNITE: Entrepreneurship, Innovation and Venture Creation"
  },
  "NPTEL34": {
      "credits": 3,
      "subname": "Ground Improvement"
  },
  "19CEL41": {
      "credits": 1,
      "subname": "Computer Aided Civil Engineering Drawing"
  },
  "19CEL62": {
      "credits": 1,
      "subname": "Irrigation and Environmental Engineering Drawing"
  },
  "19CEZ73": {
      "credits": 6,
      "subname": "Project & Viva - Voce"
  },
  "22CYL1A": {
      "credits": 1,
      "subname": "Chemistry Laboratory"
  },
  "22EEL18": {
      "credits": 1,
      "subname": "Basic Electrical, Electronics and Instrumentation Engineering Laboratory"
  },
  "22HSL1B": {
      "credits": 1,
      "subname": "English Laboratory"
  },
  "22IPM01": {
      "credits": 0,
      "subname": "Induction Programme"
  },
  "22NM008": {
      "credits": 2,
      "subname": "Basic English for Employability (Naan Mudhalvan Course)"
  },
  "22PHL19": {
      "credits": 1,
      "subname": "Physics Laboratory"
  },
  "22CEL37": {
      "credits": 1,
      "subname": "Survey Laboratory I"
  },
  "22CEL38": {
      "credits": 1,
      "subname": "Computer Aided Building Drawing"
  },
  "22CEL39": {
      "credits": 1,
      "subname": "Environmental Engineering Laboratory"
  },
  "22CEZ3A": {
      "credits": 1,
      "subname": "Professional Development (Skill Based Course)"
  },
  "22NM007": {
      "credits": 2,
      "subname": "Microsoft Digital Skills (Naan Mudhalvan Course)"
  },
  "22CEL54": {
      "credits": 1,
      "subname": "Soil Mechanics Laboratory"
  },
  "22CEL55": {
      "credits": 1,
      "subname": "Concrete and Highway Laboratory"
  },
  "22NM004": {
      "credits": 2,
      "subname": "IBM (AI, IoT, Cloud, Data Analytics) (Naan Mudhalvan Course)"
  },
  "22UGM05": {
      "credits": 0,
      "subname": "Constitution of India (Mandatory Course)"
  },
  "NPTEL31": {
      "credits": 3,
      "subname": "Municipal Solid Waste Management"
  },
  "22UGM04": {
      "credits": 0,
      "subname": "Disaster Management (Mandatory Course)"
  },
  "19MEL51": {
      "credits": 1,
      "subname": "Theory of Machines Laboratory"
  },
  "19MEL52": {
      "credits": 1,
      "subname": "Fluid Mechanics and Machinery Laboratory"
  },
  "19MEL61": {
      "credits": 1,
      "subname": "Heat and Mass Transfer Laboratory"
  },
  "19MEL62": {
      "credits": 1,
      "subname": "Automation Laboratory"
  },
  "19MEZ61": {
      "credits": 3,
      "subname": "Design and Fabrication Project"
  },
  "19MEL71": {
      "credits": 1,
      "subname": "Computer Aided Simulation and Analysis laboratory"
  },
  "19MEL72": {
      "credits": 1,
      "subname": "Engineering Metrology and Measurements Laboratory"
  },
  "19NM044": {
      "credits": 2,
      "subname": "Artificial Intelligence and Machine Learning Fundamentals with Cloud Computing and Gen AI (Naan Mudhalvan Course)"
  },
  "22EPL27": {
      "credits": 2,
      "subname": "Engineering Practices Laboratory"
  },
  "22MEL37": {
      "credits": 2,
      "subname": "Computer Aided Machine Drawing"
  },
  "22MEL38": {
      "credits": 2,
      "subname": "Manufacturing Technology Laboratory"
  },
  "22MEZ39": {
      "credits": 1,
      "subname": "Professional Development (Skill Based Course)"
  },
  "22MEL47": {
      "credits": 2,
      "subname": "Strength of Materials and Fluid Machinery Laboratory"
  },
  "22MEL48": {
      "credits": 2,
      "subname": "Thermal Engineering Laboratory"
  },
  "22MEL54": {
      "credits": 2,
      "subname": "Theory of Machines Laboratory"
  },
  "22MEL55": {
      "credits": 2,
      "subname": "Metallulrgy and Metrology Laboratory"
  },
  "22MEZ53": {
      "credits": 1,
      "subname": "Summer Internship - I"
  },
  "19MEL53": {
      "credits": 1,
      "subname": "Simulation and Analysis Laboratory"
  },
  "19MEZ71": {
      "credits": 6,
      "subname": "Project & Vivo Voce"
  },
  "19EEL52": {
      "credits": 1,
      "subname": "A.C Machines Laboratory"
  },
  "19EEL61": {
      "credits": 1,
      "subname": "Power Electronics Laboratory"
  },
  "19EEL62": {
      "credits": 1,
      "subname": "Microcontroller Laboratory"
  },
  "19CEM61": {
      "credits": 0,
      "subname": "Constitution of India (Mandatory Course)"
  },
  "19EEL71": {
      "credits": 1,
      "subname": "Power Systems Laboratory"
  },
  "19EEL72": {
      "credits": 1,
      "subname": "Comprehensive Viva"
  },
  "19EEM61": {
      "credits": 0,
      "subname": "Gender Justice (Mandatory Courses)"
  },
  "19EEM71": {
      "credits": 0,
      "subname": "Stress Management By Yoga"
  },
  "19NM045": {
      "credits": 2,
      "subname": "Electric and Hybrid Vehicles (Naan Mudhalvan Course)"
  },
  "NPTEL33": {
      "credits": 3,
      "subname": "Sustainable Power Generation Systems"
  },
  "19EEL33": {
      "credits": 1,
      "subname": "Machines Laboratory"
  },
  "19EEL51": {
      "credits": 1,
      "subname": "Control and Instrumentation Laboratory"
  },
  "19EEZ71": {
      "credits": 6,
      "subname": "Project Work Viva Voce"
  },
  "22CSL18": {
      "credits": 1,
      "subname": "Problem Solving and Python Programming Laboratory"
  },
  "22EEL28": {
      "credits": 1,
      "subname": "Electric Circuit Laboratory"
  },
  "22EEL37": {
      "credits": 1,
      "subname": "Electronic Devices and Circuits Laboratory"
  },
  "22EEL38": {
      "credits": 1,
      "subname": "C Programming and Data Structures Laboratory"
  },
  "22EEL3A": {
      "credits": 1,
      "subname": "Electrical Machines Laboratory - I"
  },
  "22EEZ39": {
      "credits": 1,
      "subname": "Professional Development (Skill Based Course)"
  },
  "22EEL47": {
      "credits": 1,
      "subname": "Electrical Machines Laboratory - II"
  },
  "22EEL48": {
      "credits": 1,
      "subname": "Linear and Digital Integrated Circuits Laboratory"
  },
  "22EEL49": {
      "credits": 1,
      "subname": "Microprocessor and Microcontroller Laboratory"
  },
  "22EEL58": {
      "credits": 1,
      "subname": "Control and Instrumentation Laboratory"
  },
  "22EEL59": {
      "credits": 1,
      "subname": "Power Electronics Laboratory"
  },
  "19ECL71": {
      "credits": 1,
      "subname": "Microwave and Optical Engineering Laboratory"
  },
  "19ECL72": {
      "credits": 1,
      "subname": "Cyber Physical Systems Laboratory"
  },
  "19ECZ71": {
      "credits": 4,
      "subname": "Mini Project"
  },
  "19NM046": {
      "credits": 2,
      "subname": "SaaS (Naan Mudhalvan Course)"
  },
  "NPTEL14": {
      "credits": 3,
      "subname": "Programming in JAVA"
  },
  "NPTEL36": {
      "credits": 3,
      "subname": "Electronics Systems Design Hands-On Circuits and PCB Design with CAD Software"
  },
  "19ECL51": {
      "credits": 1,
      "subname": "Digital Signal Processing Laboratory"
  },
  "19ECZ72": {
      "credits": 6,
      "subname": "Project and Viva - Voce"
  },
  "22ECL37": {
      "credits": 1,
      "subname": "Elecronic Devices and Circuits Laboratory"
  },
  "22ECL38": {
      "credits": 1,
      "subname": "C Programming and Data Structures Laboratory"
  },
  "22ECZ39": {
      "credits": 1,
      "subname": "Professional Development (Skill Based Course)"
  },
  "22ECL47": {
      "credits": 1,
      "subname": "Communication Systems Laboratory"
  },
  "22ECL48": {
      "credits": 1,
      "subname": "Linear Integrated Circuits Laboratory"
  },
  "22ECL54": {
      "credits": 2,
      "subname": "VLSI Laboratory"
  },
  "22NM005": {
      "credits": 2,
      "subname": "Networking Essentials (Naan Mudhalvan Course)"
  },
  "NPTEL11": {
      "credits": 3,
      "subname": "Data Structures and Algorithms using Java"
  },
  "NPTEL35": {
      "credits": 3,
      "subname": "Introduction to Internet of Things"
  },
  "19ECL41": {
      "credits": 1,
      "subname": "Electronic Circuits and Simulation Laboratory"
  },
  "19ECL52": {
      "credits": 1,
      "subname": "Linear Integrated Circuits Laboratory"
  },
  "19ECL61": {
      "credits": 1,
      "subname": "Analog and Digital Communication Laboratory"
  },
  "19CSZ61": {
      "credits": 1,
      "subname": "Technical Seminar - II"
  },
  "19CSL71": {
      "credits": 1,
      "subname": "Graphics and Multimedia Laboratory"
  },
  "19CSL72": {
      "credits": 1,
      "subname": "C # and .NET Laboratory"
  },
  "19CSZ71": {
      "credits": 3,
      "subname": "Mini Project"
  },
  "19NM047": {
      "credits": 2,
      "subname": "XR (Naan Mudhalvan Course)"
  },
  "22CSL37": {
      "credits": 1,
      "subname": "Data Structures Laboratory"
  },
  "22CSL38": {
      "credits": 1,
      "subname": "Object Oriented Programming Laboratory"
  },
  "22CSL39": {
      "credits": 1,
      "subname": "Database Management Systems Laboratory"
  },
  "22CSZ3A": {
      "credits": 1,
      "subname": "Professional Development (Skill Based Course)"
  },
  "22CSL55": {
      "credits": 1,
      "subname": "Data Science Laboratory"
  },
  "22CSL56": {
      "credits": 1,
      "subname": "Compiler Design Laboratory"
  },
  "22CSL57": {
      "credits": 1,
      "subname": "Machine Learning Laboratory"
  },
  "22NM006": {
      "credits": 2,
      "subname": "Robotic Process Automation Development (Naan Mudhalvan Course)"
  },
  "22PGA04": {
      "credits": 0,
      "subname": "Constitution of India (Audit Course)"
  },
  "22STL14": {
      "credits": 2,
      "subname": "Advanced Concrete Laboratory"
  },
  "22STZ31": {
      "credits": 2,
      "subname": "In-plant Training"
  },
  "22STZ32": {
      "credits": 6,
      "subname": "Dissertation Phase I"
  },
  "22ENZ31": {
      "credits": 2,
      "subname": "Inplant Training"
  },
  "22ENZ32": {
      "credits": 6,
      "subname": "Dissertation Phase I"
  },
  "NPTEL32": {
      "credits": 3,
      "subname": "Waste Water Treatment and Recycling"
  },
  "22CDL15": {
      "credits": 2,
      "subname": "CAD Laboratory"
  },
  "22CDL16": {
      "credits": 2,
      "subname": "Robotics and Automation Laboratory"
  },
  "22MNZ32": {
      "credits": 6,
      "subname": "Dissertation Phase - I"
  },
  "22PEL16": {
      "credits": 2,
      "subname": "Power Converters Laboratory"
  },
  "22PEZ32": {
      "credits": 6,
      "subname": "Project Work Phase - I"
  },
  "22PGA01": {
      "credits": 0,
      "subname": "English for Research Paper Writing (Audit Course)"
  },
  "22MCL17": {
      "credits": 1,
      "subname": "Fiber Optic Communication Laboratory"
  },
  "22MCL18": {
      "credits": 1,
      "subname": "RF System Design Laboratory"
  },
  "22MCZ31": {
      "credits": 6,
      "subname": "Project Work - I"
  },
  "15MAB11": {
      "credits": 4,
      "subname": "Probability and Random Processes"
  },
  "11CS107": {
      "credits": 4,
      "subname": "Fundamentals of Computing and Programming"
  },
  "11ME205": {
      "credits": 5,
      "subname": "Engineering Graphics"
  },
  "11CE304": {
      "credits": 4,
      "subname": "Mechanics of Solids"
  },
  "11CE307": {
      "credits": 3,
      "subname": "Surveying"
  },
  "11MA301": {
      "credits": 4,
      "subname": "Mathematics III"
  },
  "11CE403": {
      "credits": 4,
      "subname": "Strength of Materials"
  },
  "11CE405": {
      "credits": 4,
      "subname": "Mechanics of Soils"
  },
  "11MA401": {
      "credits": 4,
      "subname": "Numerical Methods"
  },
  "11CE502": {
      "credits": 4,
      "subname": "Design of Reinforced Concrete Elements"
  },
  "11CE601": {
      "credits": 4,
      "subname": "Design of Reinforced Concrete and Masonry Structures"
  },
  "11CE602": {
      "credits": 4,
      "subname": "Structural Analysis - II"
  },
  "11CE603": {
      "credits": 3,
      "subname": "Environmental Engineering - II"
  },
  "11CE701": {
      "credits": 3,
      "subname": "Design of Steel Structures - II"
  },
  "11CE703": {
      "credits": 4,
      "subname": "Pre Stressed Concrete Structures"
  }
}

export const admin_menu = [
  {
    id: "1",
    path: "/admin-panel",
    link: "excel to database",
    arl: "l",
  },
  {
    id: "2",
    path: "/forget_password",
    link: "forget password",
    arl: "l",
  },

];

export const 
semesterSubjects={
  CSE :{
    1:{
      code:[
        "22HSE11",
        "22MAB13",
        "22PHB14",
        "22CYB15",
        "22CSG16",
        "22HST17",
        "22CSL18",
        "22PHL19",
        "22CYL1A",
        "22HSL1B"]
    },
    2: {
      code: [
        "22HSE21",
        "22MAB22",
        "22PHB2E",
        "22EEG16",
        "22MEG24",
        "22CSC25",
        "22NCC01",
        "22HST26",
        "22EPL27",
        "22CSL28",
        "22HSL29"
      ]
    },
    3:{
      code:[
        "22CSC32",
        "22CSC33",
        "22CSC35",
        "22CSC36",
        "22CSG31",
        "22CSL37",
        "22CSL38",
        "22CSL39",
        "22CSZ3A",
        "22MAB34",
      ]
    },
    
    4: {
      code: [
        "22CSC41",
        "22CSC42",
        "22CSC43",
        "22CSC44",
        "22CSC45",
        "22CYB46",
        // "22NCC02",
        "22CSL47",
        "22CSL48",
        "22CSL49",
        "22NM003"
      ]
    },
    5: {
      code: [
        "22CSC51",
        "22CSC52",
        "22CSC53",
        "22CSC54",
        "22CSV24",
        "22CSV31",
        "22UGM04",
        "22CSL55",
        "22CSL56",
        "22CSL57"],
  
  },
  },
  ECE:{
    1:{
      code:[
        "22HSE11",
        "22MAB13",
        "22PHB14",
        "22CYB15",
        "22CSG16",
        "22HST17",
        "22CSL18",
        "22PHL19",
        "22CYL1A",
        "22HSL1B",
        "22MAB13",
        "22PHB14",
        "22CYB15",
        "22CSG16",
        "22HST17",
        "22CSL18",
        "22PHL19",
        "22CYL1A",
        "22HSL1B",
      ]
    },
    2:{
      code:[
        "22HSE21",
        "22MAB22",
        "22PHB2D",
        "22EEG16",
        "22HST26",
        "22NCC01",
        "22EPL27",
        "22ECL28"
      ]
    },
    
    3:{
      code:[
        "22MAB33" ,
        "22ECC31",
        "22ECC32",
        "22ECC34",
        "22ECC35",
        "22ECG36",
        "22ECL37",
        "22ECL38",
        "22ECZ39",
      ]
    },
    4:{
      code:[
        "22ECC41",
        "22ECC42",
        "22ECC43",
        "22ECC44",
        "22ECC45",
        "22CYB46",
        "22NCC02",
        "22ECL47",
        "22ECL48"
      ]
    },
    5:{
      code:[
        "22ECG51",
        "22ECC52",
        "22ECC53",
        "22ECV41",
        "22ECV82",
        "22ECV74",
        "22UGM05",
        "22ECL54",
        "22ECG51",
        "22ECC52",
        "22ECC53",
        "22ECV41",
        "22ECV82",
        "22ECV74",
        "22UGM05",
        "22ECL54"
      ]
    },
    7:{
      code:[
        "19ECE12",
        "19ECE14",
        "19ECP04",
        "19ECL71",
        "19ECL72",
        "19ECZ71",
      ]
  },
},
  EEE:{
    1:{
      code:[
        "22HSE11",
        "22MAB13",
        "22PHB14",
        "22CYB15",
        "22CSG16",
        "22HST17",
        "22CSL18",
        "22PHL19",
        "22CYL1A",
        "22HSL1B",
      ]
    },
    2:{
      code:[
        "22MAB32",
        "22ECG31",
        "22EEC33",
        "22EEC34",
        "22EEC35",
        "22EEG36",
        "22EEL37",
        "22EEL38",
        "22EEL3A",
        "22EEZ39"
      ]
    },
 
    3:{
      code:[
        "22MAB32",
        "22ECG31",
        "22EEC33",
        "22EEC34",
        "22EEC35",
        "22EEG36",
        "22EEL37",
        "22EEL38",
        "22EEL3A",
        "22EEZ39",
        
      ]
    },
    
    4:{
      code:[
        "22EEC41",
        "22EEC42",
        "22ECG43",
        "22EEC44",
        "22EEC45",
        "22CYB46",
        "22NCC02",
        "22EEL47",
        "22EEL48",
        "22EEL49",
        "22EEC41",
        "22EEC42",
        "22ECG43",
        "22EEC44",
        "22EEC45",
        "22CYB46",
        "22NCC02",
        "22EEL47",
        "22EEL48",
        "22EEL49"
      ]
    },
    5:{
      code:[
        "22EEC51",
        "22EEC52",
        "22EEC53",
        "22EEV31",
        "22EEV32",
        "22EEV33",
        "22UGM05",
        "22EEL58",
        "22EEL59",
        "22EEC51",
        "22EEC52",
        "22EEC53",
        "22EEV31",
        "22EEV32",
        "22EEV33",
        "22UGM05",
        "22EEL58",
        "22EEL59"
      ]
    }
  },

  CIV:{
    1:{
      code:[
        "22HSE11" ,
        "22MAB12" ,
        "22PHB14",
        "22MAB12" ,
        "22PHB14",
        "22CYB15" ,
        "22EEG16",
        "22HST17",
        "22EEL18",
        "22PHL19" ,
        "22CYL1A" ,
        "22HSL1B",
        "22HST17",
        "22EEL18",
        "22PHL19" ,
        "22CYL1A" ,
        "22HSL1B"
      ]
    },
    
      2:{
        code:[
          "22HSE21",
          "22MAB22",
          "22PHB2A",
          "22CSG16",
          "22MEG24",
          "22HST26",
          "22NCC01",
          "22EPL27",
          "22CSL18",
          "22HSL29",
          "22HSE21",
          "22MAB22",
          "22PHB2A",
          "22CSG16",
          "22MEG24",
          "22HST26",
          "22NCC01",
          "22EPL27",
          "22CSL18",
          "22HSL29"
         
  ]
},
3:{
  code:[
    "22MAB31",
    "22CEG32",
    "22CEG33",
    "22CEC34",
    "22CEC35",
    "22CEC36",
    "22CEL37",
    "22CEL38",
    "22CEL39",
    "22CEZ3A",
    
  ]
},
4:{
  code:[
    "22CEG41",
    "22CEC42",
    "22CEC43",
    "22CEC44",
    "22CEC45",
    "22CYB46",
    "22CEB47",
    "22CEL48",
    "22CEL49",
    "22CEL4A",
    "22NCC02"
  ]
},
5:{
  code:[
    "22CEC51",
    "22CEC52",
    "22CEC53",
    "22CEV26",
    "22CEV13",
    "22CEV13",
    "22UGM05",
    "22CEL54",
    "22CEL55"
  ]
},


  },
  MEC:{
    1:{
      code:[
      "22HSE11",
      "2MAB13",
      "22HSE11",
      "2MAB13",
      "22PHB14",
      "22CYB15",
      "22CSG16",
      "22HST17",
      "22CSL18",
      "22PHL19",
      "22CYL1A",
      "22HSL1B",
      ]
    },
    2:{
      code:[
        "22HSE21",
        "22MAB22",
        "22PHB2B",
        "22CSG16",
        "22MEG24",
        "22HST26",
        "22NCC01",
        "22EPL27",
        "22CSL18",
        "22HSL29"
      ]
    },
    3:{
      code:[
        "22MAB31",
        "22MEG32",
        "22MEC33",
        "22MEG34",
        "22MEC35",
        "22MEC36",
        "22MEL37",
        "22MEL38",
        "22MEZ39"
      ]
    },
    4:{
      code:[
        "22MEC41",
        "22MEC42",
        "22MEC43",
        "22MEC44",
        "22MEC45",
        "22CYB46",
        "22NCC02",
        "22MEL47",
        "22MEL48"
      ]
    },
    5:{
      code:[
        "22MEC51",
        "22MEC52",
        "22MEV22",
        "22MEVA1",
        "22MEV93",
        "22MEVA3",
        "22MEV24",
        "22MEVA5",
        "22UGM04",
        "22MEZ53",
        "22MEL54",
        "22MEL55"
      ]
    }

  }
}

export const staffs={


    2:{
      code:[
        "22HSE21",
        "22MAB22",
        "22PHB2B",
        "22CSG16",
        "22MEG24",
        "22HST26",
        "22NCC01",
        "22EPL27",
        "22CSL18",
        "22HSL29"
      ]
    },
    3:{
      code:[
        "22MAB31",
        "22MEG32",
        "22MEC33",
        "22MEG34",
        "22MEC35",
        "22MEC36",
        "22MEL37",
        "22MEL38",
        "22MEZ39"
      ]
    },
    4:{
      code:[
        "22MEC41",
        "22MEC42",
        "22MEC43",
        "22MEC44",
        "22MEC45",
        "22CYB46",
        "22NCC02",
        "22MEL47",
        "22MEL48"
      ]
    },
    5:{
      code:[
        "22MEC51",
        "22MEC52",
        "22MEV22",
        "22MEVA1",
        "22MEV93",
        "22MEVA3",
        "22MEV24",
        "22MEVA5",
        "22UGM04",
        "22MEZ53",
        "22MEL54",
        "22MEL55"
      ]
    }

  }


// export const staffs={

// }