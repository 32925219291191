export const commpdf=[
    {
        'id':"17",
        "head":"Disipline and welfare Committee",
        "house":"left",
        "pdf":"https://drive.google.com/file/d/1PQQ65lcKXxcbCKUHH6Guk7PlmVxLgmHV/view?usp=sharing"
    },
    {
        'id':"16",
        "head":"Planning and monitoring  Committee",
        "house":"right",
        "pdf":"https://drive.google.com/file/d/17G68HQrVrJ5EIzQtzNbo2RhbfC0uviME/view?usp=sharing"
    },
    {
        'id':"15",
        "head":"Helpdesk Committee",
        "house":"left",
        "pdf":"https://drive.google.com/file/d/1d_g8nApTHXhAsqrkLpLP3vdbHfx048_O/view?usp=sharing"
    },
    {
        'id':"14",
        "head":"Web Committee",
        "house":"right",
        "pdf":"https://drive.google.com/file/d/1z6GKBU8LNQHQwNIoG6W-yO5Dx-lUgNZj/view?usp=sharing"
    },
    {
        'id':"7",
        "head":"Anti Drug",
        "house":"left",
        "pdf":"https://drive.google.com/file/d/1RvfLFMWHcqikkBe4aoI05jNsrMnsNXyN/view?usp=sharing"
    },
    
    {
        'id':"8",
        "head":"Differently Abled Persons Welfare Committee",
        "house":"right",
        "pdf":"https://drive.google.com/file/d/1L0rVtvQ75ahSaA8bRbV1CPY2EshehCeL/view?usp=drive_link"
    },
    {
        'id':"9",
        "head":"Library Committee",
        "house":"left",
        "pdf":"https://drive.google.com/file/d/1wrN0ec4XRzg4r-RAnc-_enszT9XmwGfO/view?usp=sharing"
    },
    {
        'id':"10",
        "head":"Examinations Reform Committee",
        "house":"right",
        "pdf":"https://drive.google.com/file/d/1ha7rE5vG8ovWGElGRANwvyqfYa5pase8/view?usp=sharing"
    },
    {
        'id':"11",
        "head":"Internal Quality Assurance Cell",
        "house":"left",
        "pdf":"https://drive.google.com/file/d/13dI8tCvnd9AfQrEq9hL5MN9PgpoWm-Ax/view?usp=sharing"
    },
    {
        'id':"12",
        "head":"Research Advisory Committee",
        "house":"right",
        "pdf":"https://drive.google.com/file/d/1atxqu1N1Eplz-ULE9h60twmWR16stv5l/view?usp=sharing"
    },
    {
        'id':"13",
        "head":"Faculty/Staff Grievance Redressal Committee",
        "house":"left",
        "pdf":"https://drive.google.com/file/d/1glZ3j_eFllIHOZJsElPlmEEqVy7mQmF0/view?usp=sharing"
    },
    {
        'id':"6",
        "head":"Hostel Committee",
        "house":"right",
        "pdf":"https://drive.google.com/file/d/1PuTixLKxuhM4P0zCBy0w6cPtIhNeCVac/view?usp=sharing"
    },
    {
        'id':"1",
        "head":"Anti Ragging Committee",
        "house":"left",
        "pdf":"https://drive.google.com/file/d/1N_B6hH0w0DTGQxIj9usnsRHVqjDdcbik/view?usp=sharing"
    },
    {
        'id':"2",
        "head":"Anti Ragging Squad Committee",
        "house":"right",
        "pdf":"https://drive.google.com/file/d/1aTveeAzK04NBuK54opJ1R54pwx_7640o/view?usp=sharing"
    },
    {
        'id':"3",
        "head":"Grievance Redressal Committee",
        "house":"left",
        "pdf":"https://drive.google.com/file/d/1pAMCiEzrECyysdbjdcATd-rtDpOexMiY/view?usp=sharing"/*https://drive.google.com/file/d/19acKL-CcJVVibbeRC4uAkl1_Mn6X1551/view?usp=sharing*/
    },
    {
        'id':"4",
        "head":"Internal Complaint Committee",
        "house":"right",
        "pdf":"https://drive.google.com/file/d/1sXlR6PnAk-1la83TKGMZqhxBtHqVjAuc/view?usp=sharing"
    },
    {
        'id':"5",
        "head":"Committee for SC/ST ",
        "house":"left",
        "pdf":"https://drive.google.com/file/d/149KXoP_nN6MixidHdNTnZTtmZ91snuGM/view?usp=sharing"
    },
    {
        'id':"15",
        "head":"Placement Advisory Committee",
        "house":"right",
        "pdf":"https://drive.google.com/file/d/1S8TsPsEsm3nhmJHSa28XCvIcZrTDD3d1/view?usp=sharing"
    },
]