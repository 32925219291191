import React, { useState } from 'react';
import './Std_statisf.css';
import { Underline, Backtotop, Alertmessage } from '../../widgets';
import axios from 'axios';

const surveyQuestions = [
    { name: 'knowledge', question: 'I have become more knowledgeable in my field of study' },
    { name: 'latest_developments', question: 'I know the latest developments in my field and I’m up-to-date' },
    { name: 'practical_skills', question: 'Nowadays, I’m more practical in solving issues related to my work/domain' },
    { name: 'creative_thinking', question: 'My thoughts are more creative after the completion of my course' },
    { name: 'analytical_skills', question: 'I’m more analytical in assessing situations nowadays' },
    { name: 'self_learning', question: 'I have become more of a self-learner recently' },
    { name: 'financial_management', question: 'I can relate the importance of financial management for my professional life' },
    { name: 'know_capabilities', question: 'I know my capabilities' },
    { name: 'modern_tools', question: 'I’m familiar with modern developments/tools in the field' },
    { name: 'teamwork', question: 'I mingle better with my peer group' },
    { name: 'professional_friends', question: 'I have more professional friends' },
    { name: 'leadership', question: 'I’m talented to lead a group' },
    { name: 'communication_skills', question: 'I have developed my ability to communicate effectively' },
    { name: 'express_ideas', question: 'I can express my ideas clearly to others' },
    { name: 'ethics', question: 'My actions are more ethical than before' },
    { name: 'social_contribution', question: 'I’m now more concerned about my contributions to society' },
   // { name: 'problem_solving', question: 'I am now better at problem-solving and critical thinking' }
];

const Std_statisf = () => {
    const [graduateName, setGraduateName] = useState('');
    const [programme, setProgramme] = useState('');
    const [branch, setBranch] = useState('');
    const [yearOfPassing, setYearOfPassing] = useState('');
    const [ratings, setRatings] = useState({});
    const [alertMessage, setAlertMessage] = useState('');
    const [yearError, setYearError] = useState('');

    const handleRatingChange = (name, value) => {
        setRatings({ ...ratings, [name]: value });
    };

    const validateYear = (year) => {
        const currentYear = new Date().getFullYear();
        if (!/^\d{4}$/.test(year) || year < 1900 || year > currentYear) {
            setYearError('Enter a valid Year of Passing');
            return false;
        }
        setYearError('');
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateYear(yearOfPassing)) {
            return;
        }

        const unansweredQuestions = surveyQuestions.filter(({ name }) => !ratings[name]);
        
        if (unansweredQuestions.length > 0) {
            setAlertMessage('Please rate all questions before submitting.');
            return;
        }

        try {
            await axios.post('/backend/student_satisfaction_submit', {
                graduateName,
                programme,
                branch,
                yearOfPassing,
                ratings
            });
            setAlertMessage('Survey submitted successfully');
            setGraduateName('');
            setProgramme('');
            setBranch('');
            setYearOfPassing('');
            setRatings({});
        } catch (error) {
            setAlertMessage('An error occurred while submitting the survey');
        }
    };

    return (
        <div className="std_satisf_container">
            <Underline heading="Student Satisfaction Survey" />
            <form className="std_satisf_form" onSubmit={handleSubmit}>
                <input type="text" className="std_satisf_input" placeholder="Graduate Name*" required value={graduateName} onChange={(e) => setGraduateName(e.target.value)} />
                <select className="std_satisf_select" required value={programme} onChange={(e) => setProgramme(e.target.value)}>
                    <option value="" disabled>Select Programme*</option>
                    <option value="UG">UG</option>
                    <option value="PG">PG</option>
                </select>
                <select className="std_satisf_input" placeholder="Branch*" required value={branch} onChange={(e) => setBranch(e.target.value)} >
                    <option value="" disabled selected>Branch*</option>
                    <option value="CIV">CIVIL</option>
                    <option value="MEC">MECH</option>
                    <option value="EEE">EEE</option>
                    <option value="ECE">ECE</option>
                    <option value="CSE">CSE</option>
                </select>
                <input 
                    type="text" 
                    className="std_satisf_input" 
                    placeholder="Year of Passing*" 
                    required 
                    value={yearOfPassing} 
                    onChange={(e) => setYearOfPassing(e.target.value)} 
                    onBlur={() => validateYear(yearOfPassing)}
                />
                {yearError && <p className="error-message">{yearError}</p>}

                <h2 className="std_statisf_fb_subtitle">Please give your valuable feedback on a scale</h2>
                <p className='std_statisf_fb_instruction'>5 - Strongly agree  ,  4 - Agree   ,   3 - Neutral ,   2 - Disagree  ,   1 - Strongly Disagree</p>
                {surveyQuestions.map(({ name, question }) => (
                    <div key={name} className="survey_question">
                        <p>{question}</p>
                        <div className="std_teach_fb_data">
                            {[1, 2, 3, 4, 5].map((num) => (
                                <label key={num}>
                                    <input type="radio" name={name} value={num} onChange={() => handleRatingChange(name, num)} checked={ratings[name] === num} />
                                    <span className="custom-radio">{num}</span>
                                </label>
                            ))}
                        </div>
                    </div>
                ))}

                <div className="std_teaching_fb">
                    <button type="submit" className="std_teaching_fb_submit">Submit</button>
                </div>
            </form>

            <Backtotop />
            <Alertmessage message={alertMessage} onClose={() => setAlertMessage('')} />
        </div>
    );
};

export default Std_statisf;
