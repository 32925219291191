export const Coe_mou_data=[
    {
        'id':'1',
        'program':'BUCKSBOX Software ',
        'pdf':'https://drive.google.com/file/d/1Tsny8xIp8uIEROA3BzoyuMO_23PrHpf8/view?usp=sharing'
      },
      {
        'id':'2',
        'program':'Edunet Foudation -Skills Build ',
        'pdf':'https://drive.google.com/file/d/1Lhi12FJniYhU71qlA3YDh55POXKYJTs7/view?usp=sharing'
      },
      {
        'id':'3',
        'program':'Infosys Pvt Ltd',
        'pdf':'https://drive.google.com/file/d/1uq1LP-aoWgdnHqCvjrsJASMq_Vqa4GNt/view?usp=sharing'
      },
      {
        'id':'4',
        'program':'Edunet Foudation - Tech Saksham',
        'pdf':'https://drive.google.com/file/d/1hg92dXr6YgLfQ7rcX0I7dZGiwtHavXED/view?usp=sharing'
      },
      {
        'id':'5',
        'program':'Chakraa Universal Tech Labs Pvt Ltd ',
        'pdf':'https://drive.google.com/file/d/1-WMv19DHHXUH9Diatm9PNC2Z4HHyhhEo/view?usp=sharing'
      },
      {
        'id':'6',
        'program':'IITM Incubation Cell ',
        'pdf':'https://drive.google.com/file/d/12sOAGYzMGqZ8XefGgeZPOSMOipFF-0D-/view?usp=sharing'
      },
      {
        'id':'7',
        'program':'MAGA Tech Gramam Pvt Ltd ',
        'pdf':'https://drive.google.com/file/d/1a9t7LdUYGomBsQkNDk0JxFSBXwxY-mGi/view?usp=sharing'
      },
      {
        'id':'8',
        'program':'Qmax Test Technologies Pvt Ltd ',
        'pdf':'https://drive.google.com/file/d/10rh-fsfIi8yrlDqm5ckhhEKOu4T46ebO/view?usp=sharing'
      },
      {
        'id':'9',
        'program':'Siemens CoE ',
        'pdf':'https://drive.google.com/file/d/1nljisUPM1BvOXD9-D1KhXqYHrNnzevfX/view?usp=sharing'
      },
      {
        'id':'10',
        'program':'Nandy Foundation',
        'pdf':'https://drive.google.com/file/d/1xDGFsZJ-tWBf_VwfftbKkB8s2NvfObjr/view?usp=sharing'
      },
    
      {

        'id':'12',
        'program':'Surveysparrow pvt Ltd',
        'pdf':'https://drive.google.com/file/d/1CNN-V7hh0SKJcgnrsTaozctZkkMbHcx9/view?usp=sharing'

      },
      {
        'id':'13',
        'program':'Garuda Aerospace Pvt Ltd',
        'pdf':'https://drive.google.com/file/d/11LFsCN6pOHrvZbtWlwkq12rl8sz8G_Ce/view?usp=sharing'
      },
      {
        'id':'14',
        'program':'Tarcin Robotic LLP',
        'pdf':'https://drive.google.com/file/d/1aYah80F6D942XSe_jhtJNBbgULft3iKD/view?usp=sharing'
      },
    
]

