export const bulletin_info=[
    // {
    //     id:'17',
    //     link:"https://drive.google.com/file/d/1_aHOLV5dNjT2yAjPmTETJ6xh83GbjILm/view?usp=sharing",
    //     content:(
    //         <span>
    //             <span className="newContent">New</span> <span className="insideNewContent">Temporary Assistant Professor recruitment process update</span>
    //         </span>
    //     ),
    // },
    // {
    //     id:'18',
    //     link:"https://drive.google.com/file/d/17RX_8RPOO-iiaYEauYChQ4IWnT9BYude/view?usp=drive_link",
    //     content:(
    //         <span>
    //             <span className="newContent">New</span> <span className="insideNewContent">Written & Interview - Notification of Temporary Assistant Professors</span>
    //         </span>
    //     ),
    // },
//     {
//         id:'18',
//         link:"https://drive.google.com/file/d/1c2uJ7w2EEFF2eGogbvEeb7or1inLqAmp/view?usp=sharing",
//         content:(
//             <span>
//                 <span className="newContent">New</span> <span className="insideNewContent">Advertisement for Recruitment of Junior Research Fellow under DST-SERB-SURE Research Project
// </span>
//             </span>
//         ),
//     },
    {
        id:'42',
        link:"https://drive.google.com/file/d/1gvba4tKiGOVo60K9zUMUk1VBzsUf-YSR/view?usp=drive_link",
        content:(
            <span>
                <span className="newContent">New</span> <span className="insideNewContent">APR MAY 2025 AUTONOMOUS EXAM - B.E. REGULAR</span>
            </span>
        ),
    },    {
        id:'41',
        link:"https://drive.google.com/file/d/1gsJCOCxeWsDBHuBd1hjLKtIQOFm44ys6/view?usp=drive_link",
        content:(
            <span>
                <span className="newContent">New</span> <span className="insideNewContent">APR MAY 2025 AUTONOMOUS EXAM - P.T.B.E. REGULAR TIMETABLE</span>
            </span>
        ),
    },    {
        id:'40',
        link:"https://drive.google.com/file/d/1gqd6xzVAU8M6f_nXxU4YBHGkMIT19q63/view?usp=drive_link",
        content:(
            <span>
                <span className="newContent">New</span> <span className="insideNewContent">APR MAY 2025 AUTONOMOUS EXAM - M.E. REGULAR TIMETABLE</span>
            </span>
        ),
    },
    {
        id:'43',
        link:"https://drive.google.com/file/d/1gpRQWkZMVfor2Fih3pxF_u-dozq3A6rE/view?usp=drive_link",
        content:(
            <span>
                <span className="newContent">New</span> <span className="insideNewContent">APR MAY 2025 AUTONOMOUS EXAM - M.E. REGULAR TIMETABLE</span>
            </span>
        ),
    },
    {
        id:'44',
        link:"https://drive.google.com/file/d/1gltlXg1sHeaJn71aJm6UFdQKpYgWlA1i/view?usp=drive_link",
        content:(
            <span>
                <span className="newContent">New</span> <span className="insideNewContent">APR MAY 2025 AUTONOMOUS EXAM - B.E. & P.T.B.E. R2022 ARREAR TIMETABLE</span>
            </span>
        ),
    },
    {
        id:'45',
        link:"https://drive.google.com/file/d/1gk9cqAVTNIHZtZOylo40WRbyO9nONw89/view?usp=drive_link",
        content:(
            <span>
                <span className="newContent">New</span> <span className="insideNewContent">APR MAY 2025 AUTONOMOUS EXAM - B.E. & P.T.B.E. R2019 & 2019A ARREAR TIMETABLE</span>
            </span>
        ),
    },
    {
        id:'46',
        link:"https://drive.google.com/file/d/1gixqJFRFr_o_FcaQgaUKeDWgpEcpSHzh/view?usp=drive_link",
        content:(
            <span>
                <span className="newContent">New</span> <span className="insideNewContent">APR MAY 2025 AUTONOMOUS EXAM - M.E. ARREAR TIMETABLE</span>
            </span>
        ),
    },
    {
        id:'47',
        link:"https://drive.google.com/file/d/1gY5mCmrivY26KOjGqR3Cb35v7ai-Xxbf/view?usp=drive_link",
        content:(
            <span>
                <span className="newContent">New</span> <span className="insideNewContent">APR MAY 2025 AUTONOMOUS EXAM - B.E. SPECIAL ARREAR TIMETABLE</span>
            </span>
        ),
    },
        {
            id:'39',
            link:"https://drive.google.com/file/d/1qPiH9ENVvH9vGG0ktx42Qk3e_VtHh8F0/view?usp=sharing",
            content:(
                <span>
                    <span className="newContent">New</span> <span className="insideNewContent">Graduation day Invitation</span>
                </span>
            ),
        },
// {
//     id:'38',
//     link:"https://drive.google.com/file/d/1nyf7TuHNab1IO5mOoukhaCW0N5SC-WZe/view?usp=sharing",
//     content:(
//         <span>
//             <span className="newContent">New</span> <span className="insideNewContent"> ACGCET- HOSTELS- DAILY WAGES- NOTIFICATION 2025
// </span>
//         </span>
//     ),
// },
{
    id:'37',
    link:"https://drive.google.com/file/d/1GD-7vdI-WunalCAbigWifaJ0DRQ1FSb4/view?usp=drive_link",
    content:(
        <span>
            <span className="newContent">New</span> <span className="insideNewContent">Academic Calendar for the AY 2024-25 (EVEN SEM)
</span>
        </span>
    ),
},

// {
//     id:'36',
//     link:"https://drive.google.com/file/d/1bHSMio32D7zvniGh4KpJ1q3QdaCXAFL_/view?usp=drive_link",
//     content:(
//         <span>
//             <span className="newContent">New</span> <span className="insideNewContent">Re-addmission for the  AY 2024-25- Circular
// </span>
//         </span>
//     ),
// },
// {
//     id:'35',
//     link:"https://drive.google.com/file/d/1JcUpA5aUeEwsC9DLbAH223ra40NPtPVm/view?usp=sharing",
//     content:(
//         <span>
//             <span className="newContent">New</span> <span className="insideNewContent">BE Part-time - College Fee for AY 2024-25 - Circular
// </span>
//         </span>
//     ),
// },
{
    id:'34',
    link:"https://drive.google.com/file/d/1zDeQTaq_IT8QF8Qu7K_zkldoLWaspyDB/view?usp=sharing",
    content:(
        <span>
            <span className="newContent"></span> <span className="insideNewContent">Establishment of StartupTN Pre-incubation Centre
</span>
        </span>
    ),
},
// {
//     id:'33',
//     link:"https://drive.google.com/file/d/1zj4KEV6zqFCNSs6snUN8kzTShKPzNSR8/view?usp=sharing",
//     content:(
//         <span>
//             <span className="newContent"></span> <span className="insideNewContent"> NOV / DEC 2024 - ME Arrear Timetable
// </span>
//         </span>
//     ),
// },
// {
//     id:'33',
//     link:"https://drive.google.com/file/d/1ZAWHMgm7Lqb1ksKIkngbkj6uoT1gUvub/view?usp=sharing",
//     content:(
//         <span>
//             <span className="newContent"></span> <span className="insideNewContent"> NOV / DEC 2024 - BE Regular Timetable
// </span>
//         </span>
//     ),
// },
// {
//     id:'38',
//     link:"https://drive.google.com/file/d/1ITrnmvrScPu4kNS0OOkGpisrdIHd224F/view?usp=sharing",
//     content:(
//         <span>
//             <span className="newContent"></span> <span className="insideNewContent"> NOV / DEC 2024 - R2019 & 2019A BE & PTBE Arrear Timetable
// </span>
//         </span>
//     ),
// },
// {
//     id:'37',
//     link:"https://drive.google.com/file/d/1HyQAJldqjG3eqM8tE6kKtevt87O4OCsD/view?usp=sharing",
//     content:(
//         <span>
//             <span className="newContent"></span> <span className="insideNewContent">NOV / DEC 2024 - ME Regular Timetable
// </span>
//         </span>
//     ),
// },
// {
//     id:'36',
//     link:"https://drive.google.com/file/d/1Ygh2LbapryXz0wKYqecypXb6g2j1lpTd/view?usp=sharing",
//     content:(
//         <span>
//             <span className="newContent"></span> <span className="insideNewContent"> NOV / DEC 2024 - Ph.D CourseWork Timetable
// </span>
//         </span>
//     ),
// },
// {
//     id:'35',
//     link:"https://drive.google.com/file/d/1_L1s0nbRfAEescqVMNo29wHDm3JE1iuh/view?usp=sharing",
//     content:(
//         <span>
//             <span className="newContent"></span> <span className="insideNewContent"> NOV / DEC 2024 - PTBE Regular Timetable
// </span>
//         </span>
//     ),
// },


// {
//     id:'32',
//     link:"https://drive.google.com/file/d/1wXyEODPnCv1wr5U1NSefTqYELbt651E9/view?usp=sharing",
//     content:(
//         <span>
//             <span className="newContent"></span> <span className="insideNewContent"> Pragati/Saksham/Swanath/CSS Scholarship - Fresh/Renewal for AY 2024-25- Circular
// </span>
//         </span>
//     ),
// },

// {
//     id:'32',
//     link:"https://drive.google.com/file/d/1-AqGrns8S3EaGDqh75Fx9RudDbhmYqEp/view?usp=sharing",
//     content:(
//         <span>
//             <span className="newContent"></span> <span className="insideNewContent">Two weeks student induction programme  Schedule for first year B.E  AY2024-2025
//             </span>
//         </span>
//     ),
// },

// {
//     id:'32',
//     link:"https://drive.google.com/file/d/1-AqGrns8S3EaGDqh75Fx9RudDbhmYqEp/view?usp=sharing",
//     content:(
//         <span>
//             <span className="newContent"></span> <span className="insideNewContent">Two weeks student induction programme  Schedule for first year B.E  AY2024-2025
//             </span>
//         </span>
//     ),
// },

// {
//     id:'31',
//     link:"https://drive.google.com/file/d/1Mawyms2Su2cREg_mLksdL8Rm3yKcNEFg/view?usp=sharing",
//     content:(
//         <span>
//             <span className="newContent"></span> <span className="insideNewContent"> TWO WEEKS STUDENT INDUCTION PROGRAMME FOR FIRST YEAR B.E INAUGURAL INVITATION
// </span>
//         </span>
//     ),
// },
// {
//     id:'30',
//     link:"https://drive.google.com/file/d/1G2ZgAi0WkaT3hLk894hwSi84lHhZmw-2/view?usp=sharing",
//     content:(
//         <span>
//             <span className="newContent"></span> <span className="insideNewContent">Pragati/Saksham/Swanath/CSS Scholarship - OTR & Face Authentication instructions
// </span>
//         </span>
//     ),
// },
// {
//     id:'29',
//     link:"https://drive.google.com/file/d/18hfxDTJ03Cki8Ltt1FMqG0OkZYgwgPdE/view?usp=drive_link",
//     content:(
//         <span>
//             <span className="newContent"></span> <span className="insideNewContent">1st Year PT -BE  Classes Starts from 01-08-2024
// </span>
//         </span>
//     ),
// },
// {
//     id:'28',
//     link:"https://drive.google.com/file/d/1sElm-y3OZL7gGE8i1-Q_0OYpEBCzDoYH/view?usp=sharing",
//     content:(
//         <span>
//             <span className="newContent"></span> <span className="insideNewContent">1st year M.E - 2024-25 - Classes commences from 01.08.2024
//             </span>
//         </span>
//     ),
// },
// {
//     id:'27',
//     link:"https://drive.google.com/file/d/1yx-H2gLoZYDWvWt2ee9SO74Z6YWTsdM8/view?usp=drive_link",
//     content:(
//         <span>
//             <span className="newContent"></span> <span className="insideNewContent">1st year B.E - 2024-25 -Fees-Details 
// </span>
//         </span>
//     ),
// },
// {
//     id:'26',
//     link:"https://drive.google.com/file/d/1vDwppsbAttDvXD0S21EG-B3B5x2AR3rp/view?usp=sharing",
//     content:(
//         <span>
//             <span className="newContent"></span> <span className="insideNewContent">Regular B.E, M.E 2024-25 Semester Fees details
// </span>
//         </span>
//     ),
// },
// {
//     id:'25',
//     link:"https://drive.google.com/file/d/1K4t4r2_WrIdx9Ak0blZNJcVagkT0w_4w/view?usp=sharing",
//     content:(
//         <span>
//             <span className="newContent"></span> <span className="insideNewContent">1st year PTBE 2024-25 Fees details
// </span>
//         </span>
//     ),
// },
// {
//     id:'24',
//     link:"https://drive.google.com/file/d/1rseEJqbptYRbNE083s6xZzygBs2a6cCI/view?usp=sharing",
//     content:(
//         <span>
//             <span className="newContent"></span> <span className="insideNewContent">1st year M.E 2024-25 Fees details
// </span>
//         </span>
//     ),
// },
// {
//     id:'23',
//     link:"https://drive.google.com/file/d/1onZo4UGT_esdqisCoetDZEJC0NW_XdcD/view?usp=sharing",
//     content:(
//         <span>
//             <span className="newContent">New</span> <span className="insideNewContent">APR / MAY - 2024 B.E. II Semester Exams Timetable
// </span>
//         </span>
//     ),
// },
// {
//     id:'21',
//     link:"https://drive.google.com/file/d/1Im1hjIhLK3NnpntIL0Or-wRdlwi2p5fl/view?usp=sharing",
//     content:(
//         <span>
//             <span className="newContent">New</span> <span className="insideNewContent">APR / MAY - 2024 B.E. IV Semester Exams Timetable
// </span>
//         </span>
//     ),
// },
// {
//     id:'20',
//     link:"https://drive.google.com/file/d/1__knJrkEpH0-VKmHze7h38j8PWnL4Pav/view?usp=sharing",
//     content:(
//         <span>
//             <span className="newContent">New</span> <span className="insideNewContent">APR / MAY - 2024 Part-time B.E. II Semester Exams Timetable
// </span>
//         </span>
//     ),
// },
// {
//     id:'19',
//     link:"https://drive.google.com/file/d/1JdLQoOLT1HuNEzNdUE38xwuwK8qeV1FQ/view?usp=sharing",
//     content:(
//         <span>
//             <span className="newContent">New</span> <span className="insideNewContent">APR / MAY - 2024 Arrear Exams Timetable
// </span>
//         </span>
//     ),
// },
// {
//     id:'18',
//     link:"https://drive.google.com/file/d/1vxzqp9-1uaB2rCaPIl0653RlTjeym6Fj/view?usp=drive_link",
//     content:(
//         <span>
//             <span className="newContent">New</span> <span className="insideNewContent">III Assessment Tests Schedule for B.E. & PTBE
// </span>
//         </span>
//     ),
// },
// {
//     id:'17',
//     link:"https://drive.google.com/file/d/1ci_AC7Hc9CoZrz6eke2J16AkPnTLXu4p/view?usp=sharing",
//     content:(
//         <span>
//             <span className="newContent">New</span> <span className="insideNewContent">Advertisement for the Part time B.E. Counseling for the academic year 2024-2025
// </span>
//         </span>
//     ),
// },
    // {
    //     id:'16',
    //     link:"https://drive.google.com/file/d/1V9itpzM-S1GPo2UQy5DWkrazGMzFzOKg/view",
    //     content:"BC/MBC/DNC/BCM Fresh Scholarship-Circular AY 2023-24",
    // },
    // {
    //     id:'15',
    //     link:"https://drive.google.com/file/d/1iNyLJvzBgqfnxND3sJFHtFhRkPuArqqc/view",
    //     content:"BC/MBC/DNC/BCM Fresh Scholarship-Guidelines AY 2023-24",
       
    // },
    // {
    //     id:'14',
    //     link:"https://drive.google.com/file/d/1-9pVnTJ3g1P_EJ1Zx1vIGEzKaHV0ag3o/view",
    //     content:"SC ST Scholarship - Circular AY 2023-24",
 


    // },
    {
        id:'13',
        link:"https://drive.google.com/file/d/1Yqep4TBUBHSDrIxzLoqGV-Pp5UdWMTHI/view",
        content:" Guidelines for SC ST Scholarship application",
           

    },
    // {
    //     id:'12',
    //     link:"https://drive.google.com/file/d/1jcVfOZsJMr8uW1d1ukcUTCPpQ9FRePwa/view?usp=drive_link",
    //     content:(
    //         <span>
    //             <span className="newContent">New</span> <span className="insideNewContent">Notification-Application for the post of temporary assistant professor on consolidated pay.</span>
    //         </span>
    //     ),
    // },
    // {
    //     id:'1',
    //     link:"https://drive.google.com/file/d/1gC3L0wIAykwASWiL63-N_E_dJNj1xhO0/view?usp=drive_link",
    //     content:"Pudhumaipenn Thittam",
    // },
    // {
    //     id:'2',
    //     link:"https://drive.google.com/file/d/1JVfMtFByLxm9TLiNJmsZlSa_XtvLJ6GC/view?usp=drive_link",
    //     content:"First year B.E Full time & Part time classes commences on 14.09.2023",
    // },
    // {
    //     id:'3',
    //     link:"https://drive.google.com/file/d/1yy5Gr8WhlTIwK7zNA6Y9uS9r1HbeOovq/view?usp=sharing",
    //     content:"First year M.E classes commences on 21.08.2023",
    // },
    // {
    //     id:'4',
    //     link:"https://drive.google.com/file/d/1N9yOpdr5ciHkcQ7-J0MHVHPb2LdARswY/view?usp=sharing",
    //     content:"Scholarship renewal",
    // },
    // {
    //     id:'5',
    //     link:"https://drive.google.com/file/d/1IaIH8XLfSfecnk9FJOUKVw6z1zBAJmVV/view?usp=drive_link",
    //     content:"B.E (Regular), B.E(Part Time), M.E(Regular) Fees Structure – AY 2023-2024",
    // },
    // {
    //     id:'6',
    //     link:"https://drive.google.com/file/d/18TxoSvEM-KyL3PZ4TTDen7maumF_JdB_/view?usp=sharing",
    //     content:"A Two week Student Induction Program for First year B.E (2023-2024) commences from 14-9-2023",
    // },
    // {
    //     id:'7',
    //     link:"https://drive.google.com/file/d/14hQRj_dDiKsFnk3_405vecsLErdo4zSZ/view?usp=sharing",
    //     content:"Update on E-kYC – 2023-2024 BE/ME – BC/MBC/DNC Post Matric Scholarship",
    // },
    // {
    //     id:'8',
    //     link:"https://drive.google.com/file/d/1BoOObzaYAIYVYUi2bfBUDwgmkVXobxCJ/view?usp=sharing",
    //     content:"Scholarship – Pragathi/Saksham/Swanatn",
    // },



    // {
    //     id:'9',
    //     link:"https://drive.google.com/file/d/1lRk4E_xkYQiOX9FcWipUAZQ2umIwEsnk/view?usp=sharing",
    //     content:"Scholarship – BC/ MBC/DNC",
    // },
    // {
    //     id:'10',
    //     link:"https://drive.google.com/file/d/1hdteq7W3suqFu9LpRSgPamUR2CFG_C1f/view?usp=sharing",
    //     content:"BE Part-time- College Fee – AY 2022-23",
    // },
    // {
    //     id:'11',
    //     link:"https://drive.google.com/file/d/1Sg9z3VAq8OIs8MkPIvepqYEv51SNbJd9/view?usp=sharing",
    //     content:"Anti Ragging Committee",
    // },

]