import React,{useEffect} from 'react'

import './Sports_team.css'

import { Pic_gallery } from '../../components'

// import typhonz from "../../assets/pictures/sportsmeet/Typhonz.png"
import { maverickz_list} from '../../constants/sports'
import { Underline } from '../../widgets'

const maverickz="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/cocurricular/Sports/Sportsmeet/Maverickz/Maverickz.webp"

const Maverickz = () => {
    useEffect(() => {
        document.title = "ACCET-Maverickz";
      }, []);
  return (
    <div className='sport_team_page_cont'>
        <div className='sport_team_header_sec'>
            <div className='sport_team_header_img_holder'>
                <img className='sport_team_header_img' src={maverickz} alt="Maverickz Logo" />
            </div>
            <div className='sport_team_header_title_holder'>
                <div className='sport_team_header_title maverickz_title'>
                Maverickz
                </div>
                <div className='sports_team_header_points'>
                    59 Pts
                </div>
            </div>
        </div>

        <div className='sport_team_gallery_holder'>
            <Underline heading="Winners"/>
            <Pic_gallery img_list={maverickz_list.winners}/>
        </div>

        <div className='sport_team_gallery_holder'>
            <Underline heading="Runners"/>
            <Pic_gallery img_list={maverickz_list.runners}/>
        </div>

    </div>
  )
}

export default Maverickz