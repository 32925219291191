// import dpm from "../assets/pictures/boy.webp";
// import dpf from "../assets/pictures/girl.webp"
// import cover from "../assets/pictures/eee-cover.webp";
// import swayam from '../assets/pictures/swayam.webp'
// import robotics from '../assets/pictures/robotics.webp'
// import placement from '../assets/pictures/placement.webp'
// import baskaran from '../assets/pictures/eee_dept/eee_faculties/1-Baskaran-_3_.webp'
// import arunya from '../assets/pictures/eee_dept/eee_faculties/2-Arunya-Revathi.webp'
// import Nageswari from '../assets/pictures/eee_dept/eee_faculties/3-Nageswari.webp'
// import Vennila from '../assets/pictures/eee_dept/eee_faculties/4-Vennila.webp'
// import Madasamy from '../assets/pictures/eee_dept/eee_faculties/5-Madasamy.webp'
// import Ramadas from '../assets/pictures/eee_dept/eee_faculties/6-Ramadas.webp'
// import arun from '../assets/pictures/eee_dept/eee_faculties/7 Arun.webp'
// import amuthan from '../assets/pictures/eee_dept/eee_faculties/8 Amuthan.webp'
// import pradeep from '../assets/pictures/eee_dept/eee_faculties/11 PRADEEP.webp'
// import Padmanaban from '../assets/pictures/eee_dept/eee_faculties/12 Padmanaban (1).webp'
// import ida from '../assets/pictures/eee_dept/eee_faculties/13 Ida Evangeline.webp'
// import asaimani from '../assets/pictures/eee_dept/eee_faculties/14 Asaimani.webp'
// import dhivya from '../assets/pictures/eee_dept/eee_faculties/15 Dhivya.webp'
// import saranya from '../assets/pictures/eee_dept/eee_faculties/17 Saranya.webp'
// import preethi from '../assets/pictures/eee_dept/eee_faculties/18 Preethi.webp'
// import abarna from '../assets/pictures/eee_dept/eee_faculties/19 Abarna.webp'
// import mansoor from '../assets/pictures/eee_dept/eee_faculties/21-Mansoore.webp'
// import abinesh from '../assets/pictures/eee_dept/eee_faculties/22-Abinesh.webp'
//  import akbar from '../assets/pictures/eee_dept/eee_faculties/Akbar-_2_.webp'
//  import ananthi from '../assets/pictures/eee_dept/eee_faculties/Ananthii-_1_.webp'
//  import arulmani from '../assets/pictures/eee_dept/eee_faculties/Arulmani-_1_.webp'
//  import chockalingam from '../assets/pictures/eee_dept/eee_faculties/Chokalingam-_1_.webp'
//  import natchiappan from '../assets/pictures/eee_dept/eee_faculties/Natchiappan-_1_.webp'
//  import peter from '../assets/pictures/eee_dept/eee_faculties/Peter-_1_.webp'
//  import raja from '../assets/pictures/eee_dept/eee_faculties/Raja-_1_.webp'
 //import suresh from '../assets/pictures/eee_dept/eee_faculties/Suresh-kumar-_1_.webp'
//  import mani from '../assets/pictures/eee_dept/eee_faculties/manimaran-_1_.webp'
//  import sakthi from '../assets/pictures/eee_dept/eee_faculties/Sakthivel-_1_.webp'
//  import priyanka from '../assets/pictures/eee_dept/eee_faculties/priyanka.webp'



// import AC from '../assets/pictures/eee_dept/eee_labs/AC.webp'
// import DC from '../assets/pictures/eee_dept/eee_labs/DC.webp'
// import comp from '../assets/pictures/eee_dept/eee_labs/computer.webp'
// import drives from '../assets/pictures/eee_dept/eee_labs/drives.webp'
// import electro from '../assets/pictures/eee_dept/eee_labs/electronics.webp'
// import ped from '../assets/pictures/eee_dept/eee_labs/PED.webp'
// import ecl from '../assets/pictures/eee_dept/eee_labs/ECL.webp'
// import vlsi from '../assets/pictures/eee_dept/eee_labs/VLSI.webp'
// import pe from '../assets/pictures/eee_dept/eee_labs/pe.webp'
// import smart from '../assets/pictures/eee_dept/eee_labs/smart.webp'

// import s9 from '../assets/pictures/eee_dept/feedback/s9.webp'
// import s8 from '../assets/pictures/eee_dept/feedback/s8.webp'
// import s7 from '../assets/pictures/eee_dept/feedback/s7.webp'
// import s5 from '../assets/pictures/eee_dept/feedback/s6.webp'
// import s6 from '../assets/pictures/eee_dept/feedback/s5.webp'
// import s4 from '../assets/pictures/eee_dept/feedback/s4.webp'
// import s3 from '../assets/pictures/eee_dept/feedback/s3.webp'
// import s2 from '../assets/pictures/eee_dept/feedback/s2.webp'
// import s1 from '../assets/pictures/eee_dept/feedback/s1.webp'
const swayam="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/swayam.webp"
const robotics="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/robotics.webp"
const placement="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/placement.webp"
const cover="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Eee/eee-cover.webp"
const dpm="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Eee/boy.webp"
const dpf="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Eee/girl.webp"

const baskaran="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Eee/faculties/teachingfac/1-Baskaran-_3_.webp"

const arunya="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Eee/faculties/teachingfac/2-Arunya-Revathi.webp"
const Nageswari="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Eee/faculties/teachingfac/3-Nageswari.webp"
const Vennila="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Eee/faculties/teachingfac/4-Vennila.webp"
const venkatesan ="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Eee/faculties/teachingfac/venkat-TNPSC-exam.webp"
const Madasamy="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Eee/faculties/teachingfac/5-Madasamy.webp"
const Ramadas="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Eee/faculties/teachingfac/6-Ramadas.webp"
const arun="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Eee/faculties/teachingfac/7+Arun.webp"
const amuthan="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Eee/faculties/teachingfac/8+Amuthan.webp"
const pradeep="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Eee/faculties/teachingfac/11+PRADEEP.webp"
const Padmanaban="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Eee/faculties/teachingfac/12+Padmanaban+(1).webp"
const ida="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Eee/faculties/teachingfac/13+Ida+Evangeline.webp"
const asaimani="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Eee/faculties/teachingfac/14+Asaimani.webp"
const dhivya="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Eee/faculties/teachingfac/15+Dhivya.webp"
const saranya="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Eee/faculties/teachingfac/17+Saranya.webp"
const preethi="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Eee/faculties/teachingfac/18+Preethi.webp"
const abarna="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Eee/faculties/teachingfac/19+Abarna.webp"
const  mansoor="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Eee/faculties/teachingfac/21-Mansoore.webp"
const abinesh="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Eee/faculties/teachingfac/22-Abinesh.webp"
const priyanka="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Eee/faculties/teachingfac/20-Priyanka.webp"


const  akbar="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Eee/faculties/supportingfac/Akbar-_2_.webp"
const ananthi="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Eee/faculties/supportingfac/Ananthii-_1_.webp"
const arulmani="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Eee/faculties/supportingfac/Arulmani-_1_.webp"
const chockalingam="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Eee/faculties/supportingfac/Chokalingam-_1_.webp"
const natchiappan="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Eee/faculties/supportingfac/Natchiappan-_1_.webp"
const peter="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Eee/faculties/supportingfac/Peter-_1_.webp"
const raja="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Eee/faculties/supportingfac/Natchiappan-_1_.webp"
const mani="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Eee/faculties/supportingfac/manimaran-_1_.webp"
const sakthi="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Eee/faculties/supportingfac/Sakthivel-_1_.webp"
const nkr ="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Eee/faculties/teachingfac/NKR_0442.webp"
const AC="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Eee/labfacilities/AC.webp"

const DC="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Eee/labfacilities/DC.webp"
const  comp="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Eee/labfacilities/computer.webp"
const drives="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Eee/labfacilities/drives.webp"
const  electro="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Eee/labfacilities/electronics.webp"
const ped="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Eee/labfacilities/PED.webp"
const ecl="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Eee/labfacilities/ECL.webp"

const vlsi="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Eee/labfacilities/VLSI.webp"
const  pe="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Eee/labfacilities/pe.webp"
const  smart="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Eee/labfacilities/smart.webp"

const s1="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Eee/modernelectric/s1.webp"
const s2="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Eee/modernelectric/s2.webp"
const s3="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Eee/modernelectric/s3.webp"
const s4="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Eee/modernelectric/s4.webp"
const s5="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Eee/modernelectric/s5.webp"
const s6="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Eee/modernelectric/s6.webp"
const s7="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Eee/modernelectric/s7.webp"
const s8="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Eee/modernelectric/s8.webp"
const s9="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Eee/modernelectric/s9.webp"





const eee_dept_menu = [
  {
    id: "1",
    path: "/eeedept",
    link: "About the Department",
    arl:'l'
  },
  {
    id: "3",
    path: "/eeedept/eee-peo",
    link: "Programme Objectives & Outcomes",
    arl:'l'
  },
  {
    id: "5",
    path: "/eeedept/eee-faculties",
    link: "Faculty",
    arl:'l'
  },
  {
    id: "9",
    path: "/eeedept/eee-research",
    link: "Research and Publications",
    arl:'l'
  },
  
  {
    id: "7",
    path: "/eeedept/eee-lab",
    link: "Laboratory Facilities",
    arl:'l'
  },
  // {
  //   id: "8",
  //   path: "#",
  //   link: "Student Placements",
  //   arl:'l'
  // },
  
  
  {
    id: "12",
    path: "/eeedept/eee-nextgen",
    link: "Center of Excellence",
    arl:'l'
  },
  {
    id: "13",
    path: "/eeedept/eee-modern",
    link: "Modern Electric Drives System",
    arl:'l'
  },
  {
    id: "15",
    path: "/eeedept/eee-dept-lib",
    link: "Department Library",
    arl:'l'
  },
  
  {
    id: "16",
    path: "/eeedept/eee-events",
    link: "Department Activities",
    arl:'l'
  },
  // {
  //   id: "17",
  //   path: "#",
  //   link: "Video Tour",
  //   arl:'l'
  // },
  
  {
    id: "14",
    path: "/eeedept/eee-studcorner",
    link: "Students Corner",
    arl:'l'
  },
];

const eee_hod = {
  name: "Dr.K.Baskaran",
  designation: "Head of the Department, EEE",
  pic: baskaran,
  content:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do" +
    "eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut" +
    "enim ad minim veniam, quis nostrud exercitation ullamco laboris" +
    "nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor" +
    "in reprehenderit in voluptate velit esse cillum dolore eu fugiat" +
    "nulla pariatur. Excepteur sint occaecat cupidatat non proident," +
    "sunt in culpa qui officia deserunt mollit anim id est laborum." +
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do" +
    "eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut" +
    "enim ad minim veniam, quis nostrud exercitation ullamco laboris" +
    "nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor" +
    "in reprehenderit in voluptate velit esse cillum dolore eu fugiat" +
    "nulla pariatur. Excepteur sint occaecat cupidatat non proident," +
    "sunt in culpa qui officia deserunt mollit anim id est laborum.",
};

const eee_vision = {
  id: "1",
  visiondata:
    "To educate the students in the recent developments of emerging fields in Electrical and Electronics Engineering, encourage research activities and innovative techniques, develop employability skills so as to equip them to excel globally.",
};
const eee_mission = [
  {
    id: "1",
    mission:
      "To bring out the students as committed and employable technocrats in the field of Electrical and Electronics Engineering.",
  },
  {
    id: "2",
    mission:
      "To prepare the students for addressing societal challenges through competitive and innovative research.",
  },
  {
    id: "3",
    mission:
      "To ensure the graduates acquire leadership qualities and commitment towards lifelong learning.",
  },
];

const eee_research_info=[
  {
    'name':"Dr. K.Baskaran",
    'area':"Smart Grid, Power System , Computer Networks and Security",
  },
  {
    'name':"Prof. P.Madasamy",
    'area':"Power Quality management",
  },
  {
    'name':"Prof. K.Ramadas",
    'area':"Microcontroller and microprocessor, signal and systems.",
  },
  {
    'name':"Dr. A.Arunya Revathi",
    'area':"Power System , Power electronics ,Digital System",
  },
  {
    'name':"Dr. M.Balasubramonian",
    'area':"	Power converters ,Power system",
  },
  {
    'name':"Dr. M.Arun",
    'area':"FACT Devices",
  },
  {
    'name':"Dr. S.Nageswari",
    'area':"Power Converters",
  },
  {
    'name':"Prof. G.Amuthan",
    'area':"Solar Photovoltaic system",
  },
  {
    'name':"Prof. C.Vennila",
    'area':"Solar Photovoltaic system, MPPT Algorithm",
  },
  {
    'name':"Prof. M.L.Ramamoorthy",
    'area':"Multi Sensor Data fusion",
  },
  {
    'name':"Dr. S.Venkatesan",
    'area':"Investigation of power converters for solar pv system",
  },
  {
    'name':"Prof. S.Balamurugan",
    'area':"Analysis of modular multilevel inverter",
  },
  {
    'name':"Prof. V.Pradeep",
    'area':"Wide area protection in Smart Grid, Insulation Co-ordination, Distance Relaying",
  },
  {
    'name':"Prof. K.Padmanaban",
    'area':"Power Converters",
  },
  {
    'name':"Prof S.Ida Evangeline",
    'area':"Power System",
  },
  {
    'name':"Prof. S.Aasaimani",
    'area':"Active Distribution Network, Microgrid, Smart Grid",
  },
  {
    'name':"Prof K.Thirumal",
    'area':"	Control System and Power Systems",
  },
];

const scholar_info1=[
  {
    'sno':"1",
    'guide':"Mr.Hans John Dcruz",
    'area':"Development of Model ware for improving reliability of smart grid",
  },
  {
    'sno':"2",
    'guide':"Mr.M.Subha Renuka",
    'area':"Intelligent controllers for Electronics Devices",
  },
  {
    'sno':"3",
    'guide':"Mr.T.Sivaranjani",
    'area':"Power converters for Renewable Energy",
  },
  {
    'sno':"4",
    'guide':"Mr.J.Shanmugapriya",
    'area':"Cyber security in smart Grid",
  },
  {
    'sno':"5",
    'guide':"Mr.K.Ramadas",
    'area':"Electric Vehicle",
  },
  {
    'sno':"6",
    'guide':"Mr.G.Amuthan",
    'area':"Solar Photo Voltaic Systems",
  },
  {
    'sno':"7",
    'guide':"Mr.V.Pradeep",
    'area':"Wide area Protection of Power System",
  },
  {
    'sno':"8",
    'guide':"Mr.S.Aasaimani",
    'area':"Voltage control in active distribution network",
  },
  {
    'sno':"9",
    'guide':"Mr.S.Ramalingam",
    'area':"Energy Efficient Routing Protocol in Wireless Sensor Network",
  },
  {
    'sno':"10",
    'guide':"Mr.S. SelvaKumaran",
    'area':"Smart Grid Based Farming Land water Pump system using induction motor drive with reduced switches",
  },
  {
    'sno':"11",
    'guide':"Mr.K.Manikandan",
    'area':"Protection Coordination of AC DC distribution system",
  },
];
const scholar_info2=[
  {
    'sno':"1",
    'guide':"Ms.N.Hemadevi",
    'area':"Invisibility cloaking using plasmonic meta materials at optical frequency",
  },
  {
    'sno':"2",
    'guide':"Ms.D.Rajeswari",
    'area':"Design and analysis of photonic crystal fiber for various applications",
  },
];
const scholar_info3=[
  {
    'sno':"1",
    'guide':"Mr.A. Pandiyarajan",
    'area':"An investigation and analysis of a conical antenna with meta material for low earth orbit satellite applicationd",
  },
  {
    'sno':"2",
    'guide':"Mr.J.Nagarajan",
    'area':"Investigation of self adaptive speed controller for DC DC converter for control applications",
  },
  {
    'sno':"3",
    'guide':"Mr.S.M. Ayyappan",
    'area':"Some investigation on analysis and design of low power high speed bimode cmos logic circuits for automatic applications",
  },
  {
    'sno':"4",
    'guide':"Mrs.R.Dharani",
    'area':"Multi-Criteria decision analysis for Renewable Energy Integration",
  },
];
const scholar_info4=[
  {
    'sno':"1",
    'guide':"Mr.S.Annadurai",
    'area':"Control implementation of Power converters with Green Energy sources",
  },
  {
    'sno':"2",
    'guide':"Ms.R Durga Devi",
    'area':"Investigation of Performance Enhancement Techniques for standalone solar photovoltaic system",
  },
  {
    'sno':"3",
    'guide':"Mr S.Balamurugan",
    'area':"Multi level Inverters",
  },
  {
    'sno':"4",
    'guide':"Ms.A. Maheshwari",
    'area':"Optimal Energy Management System of Electrical Energy System",
  },
];
const scholar_info5=[
  {
    'sno':"1",
    'guide':"Mr.K Rajamohana",
    'area':"Investigation on performance of  a Photovoltaic power Generation System integrated converter topologies",
  },
  {
    'sno':"2",
    'guide':"Ms.R.Nirmala",
    'area':"Performance of Solar PV system Standalone Grid connected system",
  },
  {
    'sno':"3",
    'guide':"Ms S.Indumathi",
    'area':"Investigation of Different Doping effects on ZnO based piezo electric nano generator",
  },
  {
    'sno':"4",
    'guide':"Ms K.Rajesh",
    'area':"Advanced Power converters for Renewable Energy Sources",
  },
];

const pub1=[
  {
    'id':"1",
    'point':"R Dharani and M Balasubramonian, Load Shifting and Peak Clipping forReducing Energy Consumption inan Indian University Campus MDPI-Energies January 2021"
  },
  {
    'id':"2",
    'point':"R Dharani and M Balasubramonian, “Investigation of Optimum Tilt Angle for Solar PV Installation in Southern India Solid State Technology”, February 2021"
  },
  {
    'id':"3",
    'point':"Vennila C., and Vijayaraj., M, “Dynamical Performance Investigation of DynamicalModel Based Maximum Power Point Tracking Controller in Solar Photovoltaic System”, Tierärztliche Praxis Multidisciplinary Journal, ISSN: 0303-6286,Vol 41, 2021, PP No:540-555."
  },
  {
    'id':"4",
    'point':"S Venkatesan, M Saravanan, S Venkatanarayanan “Signal-flow graph analysis and implementation of novel power tracking algorithm using fuzzy logic controller”, 2021, International Journal of Business Intelligence and Data Mining, Volume 18, Issue 1,  Pages 88-108, Inderscience Publisher (IEL)."
  },
];
const pub2=[
  {
    'id':"1",
    'point':"Ramalingam, S. and Baskaran, K. ‘An Efficient Data Prediction Model Using Hybrid Harris Hawk Optimization with Random Forest Algorithm in Wireless Sensor Network’. Journal of Intelligent & Fuzzy Systems, vol. 40, no. 3, pp. 5171-5195, 2021. DOI: 10.3233/JIFS-201921."
  },
  {
    'id':"2",
    'point':"Ramalingam, S. and Baskaran, K. ‘An energy‐efficient clustering and cross‐layer‐based opportunistic routing protocol (CORP) for wireless sensor network’, International Journal of Communication systems, 09 February 2021 https://doi.org/10.1002/dac.4752.",
  },
  {
    'id':"3",
    'point':"MadasamyPerisnayagam, Skumar, “A Modified High Voltage Gain Quasi impedance source Coupled Inductor Multilevel Inverterfor Photovoltaic Applications”, Energies 13(4),874,2020."
  },
  {
    'id':"4",
    'point':"Veerapandiyan Veerasamy, , Balasubramonian Madasamy and others, A Novel RK4-Hopfield Neuralnetwork for power flow analysis ofpower system,Applied soft computing Journal, April 2020."
  },
  {
    'id':"5",
    'point':"Arunya Revathi& D. Rajeswari Surface Plasmon resonance biosensor-based dual-core photonic crystal fiber:design and analysis”, Journal of Optics, ISSN: 0972-8821, Volume 49, Number 2, and PP163-167, 2020.",
  },
  {
    'id':"6",
    'point':"Hemalatha, N &Nageswari, „A New Approach of Position Sensorless Control for Brushless DC Motor‟, Real time processing in Control, Computer Vision and Power Electronics, Current Signal Transduction Therapy, Bentham Science Publishers, ISSN: 1574-3624, vol. 15, Issue 1, (2020),pp. 65-76."
  },
  {
    'id':"7",
    'point':"Durga Devi &S. Nageswari“Distributed SM MPPT Controller for Solar PV System Under Non-Uniform Atmospheric Conditions”, IETE Journal of Research (2020) , DOI: 10.1080/03772063.2020.1844069"
  },
  {
    'id':"8",
    'point':"Annadurai, S.Nageswari“Performance Enhancement of Differential Power Processing converter with Switched Inductor”, Solid State Technology (2020), vol. 63, Issue 5, pp. 8521-8532."
  },
  {
    'id':"9",
    'point':"P Anbarasan, V Krishnakumar, S Ramkuma, “A new three-phase multilevel DC-link invertertopology with reduced switch count for photovoltaic applications”, Circuit World (2020),Volume 47 Issue 2, Emerald Publishing Limited."
  },
  {
    'id':"10",
    'point':"Balamurugan, S.Nageswari, “ Performance Analysis of multi carrier PWM (m-PWM) based Single Phase Modular Multilevel Converter using dSPACE”, Solid State Technology (2020), vol. 63, Issue 5, pp. 8639-8651."
  },
];

const pub3=[
  {
    'id':"1",
    'point':"Hans John Dcruz, Dr K.Baskaran, “Analysis of Cyber-Physical Security in Electric SmartGrid – Survey and challenges”, IEEE Explore, ISSN: 2380-7393, ISBN: 978-1- 7281-1182-7, 02 May 2019, DOI Number: 10.1109/IRSEC.2018.8702957.",
  },
  {
    'id':"2",
    'point':"V Santhosh, K Baskaran, K Santha, S Sethupathy, “Enhanced P16INK4A Expression In Cervical Cancer Among HPV Infected Women”, International Journal of Chemical and Lifesciences, 2019 Vol 3, issue 3 pp 1301-1305.",
  },
  {
    'id':"3",
    'point':"V Radhika, K Baskaran, “High resolution DPWM clustered architecture for digitally controlled DC–DC converter using FPGA”, International Journal on Cluster Computing, Springer US, 2019 Volume22, Issue2,pp 4421-4430."
  },
  {
    'id':"4",
    'point':"K Naveen Durai, K Baskaran, “Decision tree classification-N tier solution for preventing SQL injection attack on websites”, International Journal of Enterprise Network Management,2019, Volume10, Issue3-4, pp 253-271."
  },
  {
    'id':"5",
    'point':"P Madasamy, V Suresh Kumar, “A Three phase transformer less T type NPC-MLI for grid Connected PV Systems withcommon mode leakage Current mitigation”, Energies, 12(12),2434,2019.",
  },
  {
    'id':"6",
    'point':"Vaishnavadevi .A & Rmadas.K “Power Generation using DC Driven Self-Excited InductionGenerator Supplying DC Load”, Journal of Applied Science and Computations, Vol VI, Issue IV,April/2019, ISSN :1076-5131, Impact Factor: 5.8."
  },
  {
    'id':"7",
    'point':"A & Ramadas.K , “Performance Analysis of Self Excited Induction GeneratorSupplying DC load”, Vol. 5, Issue 4, April -2019, ISSN: 2395-1052",
  },
  {
    'id':"8",
    'point':"A Arunya Revathi,“Implementation of Heric Transformer-Less Inverter with Multiple Sources”,International Journal of Engineering and Advanced Technology (IJEAT), ISSN:2249-8958,Volume-9,Issue-I,October2019."
  },
  {
    'id':"9",
    'point':"A Arunya Revathi, “Performance Analysis of HERIC Inverter using Multiple Inputs”, Journal ofApplied Science and Computations, ISSN: 1076-5131, Vol. VI, Issue VI, 652-657, June2019, 41238."
  },
  {
    'id':"10",
    'point':"A Arunya Revathi “Power Quality Improvement using Fuzzy Based Dynamic Voltage Restorer”,International Journal of Scientific Research and Review, ISSN: 2279-543X, Vol.8, Issue7,249-254,July2019."
  },
  {
    'id':"11",
    'point':"Durga Devi and S.Nageswari, “Mathematical modelling and analysis of voltage super-lift power dc-dc converter for enhanced dynamic characteristics in CCM” Compel: International Journal for computation and mathematics in electrical and electronic engineering, ISSN: 0332-1649, Vol. 38, No.2 (2019), pp.759-776."
  },
  {
    'id':"12",
    'point':"Vennila, C., and Vijay raj., “Design and Implementation of Fuzzy Logic and Sliding ModeMPPT Controller for Solar PV System.” Journal of Electrical Engineering, University ofPolytechnica Timisoara Romania Faculty of Electrical and Power Engineering, ISSN 1582-4594,Vol. 19,Issue 19/2019, PP No: 441-448.",
  },
  {
    'id':"13",
    'point':"BanupriyaDr.S. Venkatesan, “Performance Analysis of PWM Controlled Single Phase Quasi Cascaded Z source Multilevel Inverter”, International Research Journal of Engineering Sciences (2019), Volume 5, Issue1, pages 52-62, International Research Journal of Engineering Sciences,ISSN :2394-983X."
  },
  {
    'id':"14",
    'point':"Indumathi S, Banupriya M , Venkatesan S, “Influence of PSSPWM Control analysis on Different Level of Quasi Z Source Cascaded H Bridge Multilevel Inverter”, SSRG International Journal of Electrical and Electronics Engineering (SSRG-IJEEE) – Special Issue ICRTETM Mar 2019, Special Issue ICRTETM Mar 2019, Pages:10-16, Seventh Sense Research Group."
  },
];
const pub4=[
  {
    'id':"1",
    'point':"Edwin Prem Kumar Gilbert, Baskaran Kaliaperumal, Elijah Blessing Rajsingh, M.Lydia, “Trust based Data Prediction, Aggregation and Reconstruction using Compressed Sensing for Clustered Wireless Sensor Networks, ” Computers and Electrical Engineering (Elsevier), February 2018, ISSN 0045-7906. (Article in Press)DOI: 10.1016/j.compeleceng.2018.01.013 (Impact Factor: 1.570"
  },
  {
    'id':"2",
    'point':"S SindhujaBanu, K Baskaran, “Hybrid FGWO based FLCs modeling for performance enhancement in wireless body area networks”, Wireless Personal Communications, Springer US 2018, Volume 100, Issue 3, pp 1163-1199",
  },
  {
    'id':"3",
    'point':"KC Sriharipriya, K Baskaran, “Optimal number of cooperators in the cooperative spectrum sensing schemes”,Circuits, Systems, and Signal Processing, Springer US, 2018, Volume37, Issue5, pp 1988-2000."
  },
  {
    'id':"4",
    'point':"K Baskaran, PK Kumar, K Santha, I Sivakamasundari, “Association of estrogen and progesterone with cancer of the uterine cervix in women infected with high-risk human papillomavirus”, Journal of Research in Medical Sciences, 2018, Vol 6, issue 3 , pp 74-79."
  },
  {
    'id':"5",
    'point':"K Baskaran, R Malathi, P Thirusakthimurugan, “Feature Fusion for FDG-PET and MRI for Automated Extra Skeletal Bone Sarcoma Classification”, Materials Today: Proceedings, Elsevier, 2018, Vol 5, Issue 1, pp 1879-1889.",
  },
  {
    'id':"6",
    'point':"V Radhika, K Baskaran, “SCADA Based Intelligent Control and Monitoring of Bottle Filling System”, TAGA JOURNAL,2018, VOL. 14, ISSN: 1748-0345 (Online),pp 1622-1625."
  },
  {
    'id':"7",
    'point':"V Radhika, K Baskaran, “Block-Random Access Memory-Based Digital Pulse Modulator Architecture for DC–DC Converters”, Intelligent and Efficient Electrical Systems, Springer, 2018."
  },
  {
    'id':"8",
    'point':"Tamil Selvi, P & Baskaran, K 2018, ‘Enhanced Energy Saving Tree Based Clustering with Multiple Input and Multiple Output’, in International Journal of Computational and Theoretical Nano science, vol. 15, pp. 773-780."
  },
  {
    'id':"9",
    'point':"Tamil Selvi, P & Baskaran, K 2018, ‘Improved Communication Confining Clustering Using Unceasing Possession Node Allocation Algorithm’, International journal of Control Theory and Applications, vol. 11, no. 1, ISSN:0974-5572."
  },
  {
    'id':"10",
    'point':"M Balasubramoniam,An Investigation of Square Microstrip Antenna with the Developments of its Charecteristicsin WI-MAX Application, International Journal of Advance Research, Ideas and Innovations in Technology, May 2018."
  },
  {
    'id':"11",
    'point':"M Balasubramoniam, Design and Comparative Analysis ofBi-mode CMOS logic circuits forArithmetic Applications, International journal Imageprocessing and networktechniques,May 2018.",
  },
  {
    'id':"12",
    'point':"M Balasubramoniam Power Factor Correction Converterfor AC Drives Application,International journal of powersystems and powerelectronics,May 2018.",
  },
  {
    'id':"13",
    'point':"M Balasubramoniam , Load Frequency Control of aDynamic Power System usingGeneralised Hopfield Neural Network based Self-Adaptive PIDController,IET Journal,August 2018."
  },
  {
    'id':"14",
    'point':"A Arunya Revathi “Development of Multiport Converter for Hybrid Renewable Energy System”,Journal of Computational and Theoretical Nanoscience, ISSN: 1546-1955, 15(5):1634- 1638,May 2018."
  },
  {
    'id':"15",
    'point':"A Arunya Revathi , “Performance Improvement in Birefringence of Photonic Crystal Fiber UsingFull-Vectorial Finite Element Method”, Journal of Computational and TheoreticalNanoscience, ISSN: 1546-1955, 15(5):1594-1597 · May 2018.",
  },
  {
    'id':"16",
    'point':"A Arunya Revathi “Dispersion and Modal Characteristics Analysis of 2D Rectangular PlasmonicWaveguide Structure”, Journal of Computational and Theoretical Nanoscience,ISSN:1546-1955, 15(5):1577-1581 · May 2018."
  },
  {
    'id':"17",
    'point':"Durga Devi and S.Nageswari, “Recursive Identification of MPP Using Adaptive MPPT Controller For Solar PV System”, Journal of Electrical Engineering, Volume 18, Number 1 (2018), pp.442-449.",
  },
  {
    'id':"18",
    'point':"Gomathi, R. Durga Devi and Nageswari“Modeling and Analysis of Sliding Mode MPPT Controller for Solar Photovoltaic System”, International Journal of Advanced Research in Electrical, Electronics and Instrumentation Engineering, Volume 7 Number 2 (2018), pp. 726-734."
  },
  {
    'id':"19",
    'point':"Vennila, C., and M. Vijayaraj. “A Novel MPPT Scheme for Unequally Irradiated Solar Photovoltaic Panels Feeding a Common Load Using Sliding Mode Controller.” Current SignalTransduction Therapy, Vol 13,Issue 1/2018, PP No:11–18."
  },
  {
    'id':"20",
    'point':"SundharajanVenketasen, ManimaranSaravanan, Journal of Electrical Engineering (International), Volume 18, Issue: 2018/1, pages: 94-120, Journal of Electrical Engineering,Romania, ISSN:1582-4594."
  },
  {
    'id':"21",
    'point':"Sundharajan Venkatesan, ManimaranSaravanan, “Implementation of a Single sensor Based MPPT for Solar PV System using LUO converter”, Journal of Electrical Engineering, (2018),Volume 18, Issue1, Pages 27-27."
  },
];

const pub5=[
  {
    'id':"1",
    'point':"N Kaleeswari and, K Baskaran, “An Optimized Secure Data Gathering Scheme for Wireless Sensor Network”, Journal of Computational and Theoretical Nanoscience, Vol 14 issue 3 pp 1653-1660."
  },
  {
    'id':"2",
    'point':"J Sujitha, K Baskaran, “Blind Channel Estimation Based Linear Precoding using MIMO-OFCDM”, Wireless Personal Communications, Springer US, 2017, Vol 97, Issue 4, pp 5969-5981",
  },
  {
    'id':"3",
    'point':"S SindhujaBanu, K Baskaran, “Time stationed traffic prioritization protocol for wireless body area network (WBAN)-modeling and energy consumption analysis”, Advances in Natural and Applied Sciences, 2017, Vol 11, No 9, pp 10-17."
  },
  {
    'id':"4",
    'point':"K & Anusiya.K “Fuzzy Based Maximum Power Point Tracking in Transformer lessGrid connected PV System” International Journal of Innovative Research in Science, Engineeringand Technology, Vol. 6, Issue 8, August 2017 ISSN 2319-8753"
  },
  {
    'id':"5",
    'point':"K & Raga Brindha.S “A Comparison Between Perturb and Observe and ModifiedPerturb and Observe MPPT Techniques”, International Journal of Advance Engineering andResearch Development, Vol. 4, Issue 11, November- 2017 ISSN : 2348-4470 Impact Factor:4.72.",
  },
  {
    'id':"6",
    'point':"K & Madasamy.P “Monitoring the Transformer Oil Temperature and Load sharing UsingGSM”, International Journal for Research in Applied Science & Engineering Technology, Vol 5,Issue XI, November 2017, ISSN : 2321-9653, Impact Factor: 6.887"
  },
  {
    'id':"7",
    'point':"K & Nagananthini.R, ” Cascaded Multilevel Inverter Based Unified Power Flow Controller”, International Journal of Advance Engineering and Research Development, Vol. 4,Issue 11, November- 2017 ISSN : 2348-4470 Impact Factor: 4.72."
  },
  {
    'id':"8",
    'point':"K, Raga Brindha.S, “ A Single stage Switched Capacitor Inverter withMaximumPower Point Tracking”, International Journal for Research in Applied Science & EngineeringTechnology, Vol 5, Issue XII, December- 2017, ISSN : 2321-9653, Impact Factor: 6.887"
  },
  {
    'id':"9",
    'point':"M Balasubramoniam, Load flow analysis in generalisedHOP-field neural network, IET Journal, December 2017."
  },
  {
    'id':"10",
    'point':"A Arunya Revathi, “Working Principle of Semi dual Dynamic Bridge Converter with Dual Source”,Elysium Journal of Engineering Research and Management, ISSN: 2347-4734, Volume – 4,Issue:1, page No. 1-5, February 2017."
  },
  {
    'id':"11",
    'point':"Amuthameena Subramanian, Amuthan G, Ganesan L, “Comparative Analysis of Unity Power factor Grid Connected PV system with PI and Fuzzy Based Controllers”,International Journal of Power Electronics. J. Power Electronics, Vol. 8, No. 2, 2017,  Pp159-177.",
  },
  {
    'id':"12",
    'point':"Durga Devi and S.Nageswari, “Analysis and Implementation of MPPT Technique and Battery Voltage Regulation System for a Standalone Solar PV System”, Middle-East Journal of Scientific Research, Volume 25, Number 2 (2017), pp.408-416.",
  },
];


const eee_progs = [
  {
    id: "1",
    year:"1952",
    // period:"4 Years",
    top_head:"B.E.",
    program: "Electrical and Electronics Engineering",
    intake: "120",
  },
  {
    id: "2",
    year:"2002",
    // period:"4 Years",
    top_head:"M.E.",
    program: "Power Electronics and Drives",
    intake: "18",
  },
  {
    id: "3",
    year:"1971",
    // period:"4 Years",
    top_head:"Part time B.E.",
    program: "Electrical and Electronics Engineering",
    intake: "60",
  },
];

const studcorner=[
  {
    id:'1',
    top_head:'B.E (2015-2019)',
    program:"Arunasri K",
    view:"https://drive.google.com/file/d/1qxIoCwBZk8A5mVuY-64k437mGIgjlS9G/view"
  },
  {
    id:'2',
    top_head:'B.E (2016-2020)',
    program:"Aarthe B",
    view:"https://drive.google.com/file/d/1PgAZeUhwelcgEuzA7r4enEyT5AT6zBQV/view"
  },
  {
    id:'3',
    top_head:'B.E (final yr)',
    program:"Arnika P",
    view:"https://drive.google.com/file/d/1zGXRf2fTZipHJJZcdf-uZWMfB2rCcAoh/view"
  },
  {
    id:'4',
    top_head:'B.E (final yr)',
    program:"Indumathi V",
    view:"https://drive.google.com/file/d/1dYWVNbKBlcTQCTNql0n7WjoS3WCcYwBL/view"
  },
  {
    id:'4',
    // top_head:'B.E (final yr)',
    program:"Nidhish MM, Sunjith Kumaran CM, \n Madhavan K, VinothKumar S",
    view:"https://drive.google.com/file/d/1OOI-yiXQz2QU-6pu-gvlwkg6vsTa2dla/view?usp=drive_link "
  },
]
const eee_gen=[
  {
    id: "1",
    head: "18.01.2021 to 28.02.2021",
    house: "left",
    content:
      "NPIU, with IIT Bombay, trains faculty in IoT. They, in turn, train students. Batch I: 30 students. Batch II: 47 students, 10 NASSCOM-certified.",
  },
  {
    id: "2",
    head: "23.08.2020 to 01.11.2020",
    house: "right",
    content:
      "The Digital Transformation Learning Series facilitated peer learning among 100 students via online platforms during the lockdown, with students sharing insights from 23.08.2020 to 01.11.2020.",
  },
  {
    id: "3",
    head: "17.02.2020 to 21.02.2020",
    house: "left",
    content:
      "Students were trained in robot building with ROS, including simulation and prototyping, from 17.02.2020 to 21.02.2020 at the NextGen Lab, EEE Department, benefiting 56 students.",
  },
  {
    id: "4",
    head: "30.10..2019 to 03.11.2019",
    house: "right",
    content:
      "In a week-long training on AI, experts from Smart Bridge Services instructed 50 students at the NextGen Lab, EEE Department, covering IBM Watson AI services, including chatbots and image recognition from 30.10.2019 to 03.11.2019.",
  },
  {
    id: "5",
    head: "09.09.2019 to 14.09.2019",
    house: "left",
    content:
      "During a one-week training on IoT from 09.09.2019 to 14.09.2019 at the NextGen Lab, EEE Department, 52 students acquired skills in building IoT systems, sensor selection, IBM cloud services, and MIT App Inventor app development.",
  },
]

const eee_peo = [
  {
    id: "1",
    head: "PEO 1",
    house: "left",
    content:
      "To impart a stout foundation in Mathematics, Science and Electrical Engineering fundamentals as well as comprehend, scrutinize, design, innovate and develop products for real life applications and to meet trending industrial needs.",
  },
  {
    id: "2",
    head: "PEO 2",
    house: "right",
    content:
      "To inculcate ethical attitude, effective communication skills, leadership and management qualities, self-motivation with team spirit for making the most out of collaboration and showing solicitude towards society.",
  },
  {
    id: "3",
    head: "PEO 3",
    house: "left",
    content:
      "To provide a holistic academic environment to foster excellence, entrepreneurship and multidisciplinary approach to develop an attitude for research and lifelong learning",
  },
];

const eee_outcomes = [
  {
    id: "1",
    point:
      "Engineering knowledge: Apply the knowledge of mathematics, science, engineering fundamentals, and an engineering specialization to the solution of complex engineering problems.",
  },
  {
    id: "2",
    point:
      "Problem analysis: Identify, formulate, review research literature, and analyze complex engineering problems reaching substantiated conclusions using first principles of mathematics, natural sciences, and engineering sciences.",
  },
  {
    id: "3",
    point:
      "Design/development of solutions: Design solutions for complex engineering problems and design system components or processes that meet the specified needs with appropriate consideration for the public health and safety, and the cultural, societal, and environmental considerations.",
  },
  {
    id: "4",
    point:
      "Conduct investigations of complex problems: Use research-based knowledge and research methods including design of experiments, analysis and interpretation of data, and synthesis of the information to provide valid conclusions.",
  },
  {
    id: "5",
    point:
      "Modern tool usage: Create, select, and apply appropriate techniques, resources, and modern engineering and IT tools including prediction and modeling to complex engineering activities with an understanding of the limitations.",
  },
  {
    id: "6",
    point:
      "The engineer and society: Apply reasoning informed by the contextual knowledge to assess societal, health, safety, legal and cultural issues and the consequent responsibilities relevant to the professional engineering practice.",
  },
  {
    id: "7",
    point:
      "Environment and sustainability: Understand the impact of the professional engineering solutions in societal and environmental contexts, and demonstrate the knowledge of, and need for sustainable development.",
  },
  {
    id: "8",
    point:
      "Ethics: Apply ethical principles and commit to professional ethics and responsibilities and norms of the engineering practice.",
  },
  {
    id: "9",
    point:
      "Individual and team work: Function effectively as an individual, and as a member or leader in diverse teams, and in multidisciplinary settings.",
  },
  {
    id: "10",
    point:
      "Communication: Communicate effectively on complex engineering activities with the engineering community and with society at large, such as, being able to comprehend and write effective reports and design documentation, make effective presentations, and give and receive clear instructions.",
  },
  {
    id: "11",
    point: "Project management and finance: Demonstrate knowledge and understanding of the engineering and management principles and apply these to one’s own work, as a member and leader in a team, to manage projects and in multidisciplinary environments.",
  },
  {
    id: "12",
    point:
      "Life-long learning: Recognize the need for, and have the preparation and ability to engage in independent and life-long learning in the broadest context of technological change.",
  },
];
const eee_spec_outcomes = [
  {
    id: "1",
    point:
      "Introduce the concepts of Electrical and Electronics Engineering and apply them to electrical power apparatus, analog and digital electronic systems.",
  },
  {
    id: "2",
    point:
      "Analyze, design and provide an engineering solution to issues in power electronics, drives and renewable energy systems.",
  },
];

const eee_fac_profiles = [
  {
    id: "1",
    cover: cover,
    dp: baskaran,
    name: "Dr.K.Baskaran",
    Designation: "Professor and HOD",
    mailid: "drbaskaran@accetedu.in",
    pdf: "https://drive.google.com/file/d/1-CyaqHhAV-kbm73tNpQWzePwJpnZb5mh/view?usp=drive_link",
  },
  {
    id: "2",
    cover: cover,
    dp: arunya,
    name: "Dr.A.ArunyaRevathi",
    Designation: "Professor (CAS)",
    mailid: "arunyarevathi_eee@yahoo.co.in",
    pdf: "https://drive.google.com/file/d/1KGuTXZdM25MKdLXwN7wmjnCoVpyUGXLF/view?usp=drive_link",
  },
  {
    id: "3",
    cover: cover,
    dp: Nageswari,
    name: "Dr.S.Nageswari",
    Designation: "Associate Professor",
    mailid: "mahabashyam@gmail.com",
    pdf: "https://drive.google.com/file/d/1j0WWZP729GsSQTUxizR_vtWOGOLBJolb/view?usp=drive_link",
  },
  {
    id: "4",
    cover: cover,
    dp: Vennila,
    name: "Dr.C.Vennila",
    Designation: "Associate Professor",
    mailid: "vennila@accetedu.in",
    pdf: "https://drive.google.com/file/d/1YRcDbOlr58hbclvZTbunPAAnPrnnXfoV/view?usp=sharing",
  },
  
  {
    id: "5",
    cover: cover,
    dp: venkatesan,
    name: "Dr.S.Venkatesan",
    Designation: "Associate Professor",
    mailid: "venkateee10@gmail.com",
    pdf: "https://drive.google.com/file/d/1eZSVL3SFjGKy7l_Wk68By4njyYb6nCkq/view?usp=sharing",
  },
  {
    id: "6",
    cover: cover,
    dp: Madasamy,
    name: "Prof.P.Madasamy",
    Designation: "Associate Professor(CAS)",
    mailid: "mjasmitha0612@gmail.com",
    pdf: "https://drive.google.com/file/d/1zk2vQddK_B9d3XtbwoLXDIfIWhlEKMWL/view?usp=drive_link",
  },
  {
    id: "7",
    cover: cover,
    dp: Ramadas,
    name: "Prof.K.Ramadas",
    Designation: "Associate Professor(CAS)",
    mailid: "proframadas@gmail.com",
    pdf: "https://drive.google.com/file/d/1a60IrGfnpHU23Uj0a54mEuo4Iopi2ffE/view?usp=drive_link",
  },
  {
    id: "8",
    cover: cover,
    dp: arun,
    name: "Dr.M.Arun",
    Designation: "Associate Professor(CAS)",
    mailid: "arunmano80@gmail.com",
    pdf: "#",
  },
  {
    id: "9",
    cover: cover,
    dp: nkr,
    name: "Prof.M.L.Ramamoorthy",
    Designation: "Assistant Professor",
    mailid: "ramamoorthyml@gmail.com",
    pdf: "https://drive.google.com/file/d/1TDYW0Jlk76GKtapo-fZVtlgbn91eUaV1/view?usp=drive_link",
  },
  {
    id: "10",
    cover: cover,
    dp: pradeep,
    name: "Prof.V.Pradeep",
    Designation: "Assistant Professor",
    mailid: "pradeep.vee@gmail.com",
    pdf: "https://drive.google.com/file/d/1nsd2W5GQO7jlECkVSSag6Qqf52QO7QRB/view?usp=drive_link",
  },
  {
    id: "11",
    cover: cover,
    dp: Padmanaban,
    name: "Prof.K.Padmanaban",
    Designation: "Assistant Professor",
    mailid: "padupoi@gmail.com",
    pdf: "https://drive.google.com/file/d/1kFmRvOEvAjsPpF-jidxQHrVU7hpMnGHq/view?usp=sharing",
  },
  {
    id: "12",
    cover: cover,
    dp: asaimani,
    name: "Prof.S.Aasaimani",
    Designation: "Assistant Professor",
    mailid: "aasaimani.be.eee@gmail.com",
    pdf: "https://drive.google.com/file/d/1I_TzxbSMu6Kja_8XgtEV-S36HcqaWkbt/view?usp=drive_link",
  },
  {
    id: "13",
    cover: cover,
    dp: dpf,
    name: "Ms.B.Subashini",
    Designation: "Assistant Professor (Contract Basis)",
    mailid: "subasubramani11@gmail.com",
    pdf: "https://drive.google.com/file/d/1LeLwtT80IVMAeNXk_b-lc2KGmEDpisyW/view?usp=drive_link",
  },
  {
    id: "14",
    cover: cover,
    dp: saranya,
    name: "Ms.M.Sharanya",
    Designation: "Assistant Professor (Contract Basis)",
    mailid: "sharanyam105@gmail.com",
    pdf: "https://drive.google.com/file/d/1gLf-Plr7M_wHqoTKvj2ZrU3VdPKoVmRR/view?usp=drive_link",
  },
  {
    id: "15",
    cover: cover,
    dp: abarna,
    name: "Ms.C.Abarna",
    Designation: "Assistant Professor (Contract Basis)",
    mailid: "cabarna97@gmail.com",
    pdf: "https://drive.google.com/file/d/1IXsTDmJhYnarMU1H-LTsbNp4YDRBiNj8/view?usp=drive_link",
  },
  {
    id: "16",
    cover: cover,
    dp: abinesh,
    name: "Mr.G.Abinesh",
    Designation: "Assistant Professor (Contract Basis)",
    mailid: "abineshkkdi@gmail.com",
    pdf: "https://drive.google.com/file/d/1R5qwoGs3-tS22Cdcbssh1ltGjpR33mm3/view?usp=drive_link",
  },
  {
    id: "17",
    cover: cover,
    dp: dpm,
    name: "Mr.B.Annaselvaraj",
    Designation: "Assistant Professor (Contract Basis)",
    mailid: "annaselvarajb@gmail.com",
    pdf: "https://drive.google.com/file/d/1YGPI_vFa-xcnHGgspuspx_7LM3yUqp92/view?usp=sharing",
  },
  {
    id: "18",
    cover: cover,
    dp: dpm,
    name: "Mr.S.Janakiraman",
    Designation: "Assistant Professor (Contract Basis)",
    mailid: "janakiramanttl@gmail.com",
    pdf: "https://drive.google.com/file/d/1XDzghaIeiSjXoTisYHMBdBYGLnIyImY5/view?usp=sharing",
  },
  {
    id: "19",
    cover: cover,
    dp: dpm,
    name: "Mr.N.Suresh",
    Designation: "Assistant Professor (Contract Basis)",
    mailid: "suresh067@gmail.com",
    pdf: "https://drive.google.com/file/d/1lMJLivOI8x7Yxgb2q9BuDeqzodCkUU7A/view?usp=sharing",
  },
  {
    id: "20",
    cover: cover,
    dp: dpf,
    name: "Ms.S.SelvaPrabha",
    Designation: "Assistant Professor (Contract Basis)",
    mailid: "selvaprabha3797@gmail.com",
    pdf: "https://drive.google.com/file/d/1abDoTChieZGBq1hkHmZtGo9eOxHsVwtW/view?usp=sharing",
  },
 
  {
    id: "21",
    cover: cover,
    dp: dpm,
    name: "Mr.T.Meganathan",
    Designation: "Assistant Professor (Contract Basis)",
    mailid: "megapkyeee@gmail.com",
    pdf: "https://drive.google.com/file/d/1vNDTaA270FXjXE7ziQn4QA1eJ8SCbybR/view?usp=sharing",
  },
  
];
const eee_sup_profiles = [
  {
    id: "1",
    cover: cover,
    dp: ananthi,
    name: "I.Ananthi",
    Designation: "Lab Assistant (Sl.Gr)",
    mailid: "ananthiselvaraj71@gmail.com",
  },
  {
    id: "2",
    cover: cover,
    dp: mani,
    name: "K.Manimaran",
    Designation: "Electrician – II (Sl.Gr)",
    mailid: "maranmani132@gmail.com",
  },
  {
    id: "3",
    cover: cover,
    dp: sakthi,
    name: "M.Sakthivel",
    Designation: "Electrician – II",
    mailid: "sudhasakthisri@gmail.com",
  },
  {
    id: "4",
    cover: cover,
    dp: peter,
    name: "G.Peter Ramesh",
    Designation: "Mechanic – II",
    mailid: "accetpeter@gmail.com",
  },
  {
    id: "5",
    cover: cover,
    dp: akbar,
    name: "J.Akbar Ali",
    Designation: "Electrician - II",
    mailid: "akku1485@gmail.com",
  },
  {
    id: "6",
    cover: cover,
    dp: dpf,
    name: "B.Suriya",
    Designation: "Instrument Repairer",
  },
  {
    id: "7",
    cover: cover,
    dp: chockalingam,
    name: "G.Chokalingam",
    Designation: "Electrician – II",
    mailid: "chockugs@gmail.com",
  },
  {
    id: "8",
    cover: cover,
    dp: dpm,
    name: "S.Senthil Kumar",
    Designation: "Lab Assistant",
  },
  {
    id: "9",
    cover: cover,
    dp: dpm,
    name: "P.Saravanan",
    Designation: "Mechanic – II",
  },
  {
    id: "10",
    cover: cover,
    dp: arulmani,
    name: "R.Arulmani",
    Designation: "Cleaner",
  },
];

const eee_labs=[
  {
      'id':'1',
      'heading':"DC Machines Lab",
      'pic':DC,
      'content_1':'The Machines Lab holds the distinction of being one of the earliest established facilities within our department. It stands as a testament to our commitment to practical engineering education. This lab is adorned with a treasure trove of imported machinery from industry giants like ASEA and Brown Boveri, which adds a historical dimension to our learning environment.',
      'content_2':'The lab is well-equipped with a diverse array of meters, each calibrated for different measurement ranges, enabling students to engage in a wide range of experiments. While its primary purpose is to support the Undergraduate Machines Lab course, it serves a dual role as a hub for project development. Here, ambitious endeavors like the creation of linear induction motors come to life.'+"Covering a substantial area of 199.2 square meters, the Machines Lab offers ample space for students to explore, learn, and innovate. Among its major assets are DC Shunt Generators, DC Series Generators, DC Compound Generators, DC Shunt Motors, DC Series Motors, and DC Compound Motors, all of which contribute significantly to the hands-on learning experience of our engineering students."
  },
  {
      'id':'2',
      'heading':'AC Machines Lab',
      'pic':AC,
      'content_1':'The AC Machines Lab, occupying 199.2 square meters, serves as a pivotal hub for hands-on learning in our department. Equipped with essential apparatus, including Single Phase Transformers, 3 Phase Alternator Sets, Transformers, Salient Pole Alternators, and Pole Changing setups, it facilitates comprehensive exploration of AC machinery.',
      'content_2':"This lab also features Induction Motors (both single-phase and three-phase), including Squirrel Cage and Slip-ring variants, along with Induction Generators, Synchronous Motors, and Pole-changing Induction Motors. This array of equipment allows students to gain practical insights into the behavior and operation of AC machines, bridging the gap between theory and real-world applications in electrical engineering education."
  },
  {
      'id':'3',
      'heading':'Power Electronics and Drives Lab (PG)',
      'pic':ped,
      'content_1':'The Power Electronics and Drives Lab for postgraduate studies spans an area of 58.9 square meters and serves multiple key functions within our department. Primarily, it is dedicated to PG-level Power Electronics and Drives lab courses, offering students an in-depth understanding of these critical subjects. Additionally, the lab serves as a hub for research endeavors, fostering innovation in power electronics and drives. Moreover, it plays a pivotal role in consultancy work such as energy audits.',
      'content_2':"This multifaceted lab is equipped with a diverse range of major equipment including Power Analyzers, IGBT-based Three-Phase PWM Inverters for SR Motors, Three-Phase Half and Fully Controlled Rectifiers, Cyclo Converters, Inverters (both Series and Parallel), IPM-Based Electrical Drives, Speed Control Setups, Oscilloscopes, MOSFET Power Modules, AC Voltage Regulators, Resonant Converters, Multilevel Inverters, Harmonic Analyzers, and Power Meters. It serves as a dynamic space for learning, research, and practical applications in the realm of power electronics and drives."
  },
  {
      'id':'4',
      'heading':'Computer Lab',
      'pic':comp,
      'content_1':'With an area of 186.5 square meters, this lab is a technological hub. Equipped with advanced computing machines running industrial-grade software, it primarily serves for teaching simulation experiments in power systems, control systems, and power electronics.',
      'content_2':"Additionally, it facilitates online quizzes and other technology-enhanced learning activities. The lab boasts 40 Pentium-i7-based computers, a Pentium-i7 N-Computing System with 5 nodes, and a range of software, including Mi-Power, MATLAB/Simulink, PSCADEMTDC, ETAP, LabVIEW, dSpace with data acquisition modules. It also features essential infrastructure like a high-speed laser printer, internet connectivity, and robust UPS systems."
  },
  {
    'id':'5',
    'heading':"Drives Lab",
    'pic':drives,
    'content_1':'Spanning an area of 156.2 square meters, the Drives Lab is equipped with a state-of-the-art AD DC drive system. This setup serves as a robust test bench for research activities related to drives.',
    'content_2':"The lab boasts a machine assembly capable of real-time data acquisition and control, as well as hardware in loop capabilities for Power Electronics & Drives (cRIO-9039). Additionally, it houses essential equipment like a 3 Phase Slip-ring Induction Motor, Compound Motor Coupled set, DC Shunt Motor, and DC Series Generator Coupled Set.",
  },
{
    'id':'6',
    'heading':'Power Electronics Lab',
    'pic':pe,
    'content_1':'Spanning an area of 240 square meters, this lab houses an array of major equipment for research and experimentation. It includes Single Phase Inverter Modules, Single Phase Semi Converters, Single Phase Fully Controlled Converters, 3 Phase Fully Controlled Converters, 3 Phase IGBT based Inverters, SCR-based 1 Phase AC Voltage Controllers, DC-DC Buck, Boost, and Buck/Boost Converters.',
    'content_2':" Additionally, the lab features an FPGA-based Speed Controller for Rectifier-fed DC Motors, as well as Analog and Dual Trace Oscilloscopes for in-depth analysis, enabling advanced research in power electronics and control systems."
},
{
    'id':'7',
    'heading':'Electronics Lab',
    'pic':electro,
    'content_1':'The Electronics Lab, spanning 187 square meters, is a vital educational space within our department. It plays a pivotal role in teaching Electronics devices, analog and digital integrated lab courses, forming the foundation of our students understanding of electronics. At the heart of this lab are crucial resources, including Digital IC Trainers, which enable students to explore the intricate world of digital integrated circuits.',
    'content_2':"Dual Tracking Power Supplies ensure a stable power source for experiments, while Function Generators generate precise waveforms for testing and analysis. Dual Trace CROs provide visual insights into electronic signals, aiding in troubleshooting and comprehension. Moreover, the lab houses Digital Measuring Instruments, essential for accurate measurements, and an Electronic Work Bench, which serves as a versatile workspace for students to assemble, test, and refine their electronic projects."
},
{
    'id':'8',
    'heading':'Embeded Controllers Lab',
    'pic':ecl,
    'content_1':'With an area spanning 105 square meters, the lab is well-equipped to facilitate hands-on learning in the realm of embedded systems and microcontrollers. The lab is furnished with essential equipment, including six computers and a variety of microcontroller-based modules, such as ARM 2148 and PIC microcontroller-based modules.',
    'content_2':"Additionally, it features universal add-on modules for Microcontroller 8051 and 8085 kits, along with ADSP 2148-based embedded modules. These resources provide students with a practical understanding of embedded systems and microcontroller applications, preparing them for the ever-evolving field of electronics and computer engineering."
},
{
'id':'9',
'heading':"VLSI and DSP Lab",
'pic':vlsi,
'content_1':'Spanning 80.1 square meters, our VLSI and DSP Lab is a hub for advanced digital system design. Its well-equipped with 19 computers and an array of specialized equipment, including VLSI Trainer kits and Spartan Trainer kits.',
'content_2':"These tools empower students to delve into VLSI design. The lab also features Spartan 3E Trainer kits for hands-on FPGA development. Additionally, it includes DSP kits like TMS3202407, TMS320C5416, and TMS320V6713, enabling students to explore the intricacies of digital signal processing, ensuring a comprehensive understanding of these vital technologies.",
},
// {
// 'id':'10',
// 'heading':'Measurements Control and Instrumentation Lab',
// 'pic':placement,
// 'content_1':'Spanning an area of 160.2 square meters, our Measurements Control and Instrumentation Lab is a pivotal space for practical learning.',
// 'content_2':"Equipped with a range of major instruments, including LVDTs, Synchronous AC and DC Bridges, Hall Effect Transducer Modules, Pressure and Temperature Transducers, Load cell Transducers, Dual Tracking Power Supplies, Instrumentation Amplifiers, A/D & D/A Converters, Current Transformers, and AC & DC Servomotors, this lab provides students with hands-on experience in precision measurement, control, and instrumentation techniques, preparing them for diverse applications in engineering and technology."
// },
{
'id':'11',
'heading':'Smart Grid Lab',
'pic':smart,
'content_1':'Our Smart Grid Test Bench is a crucial addition to our facilities, serving both undergraduate students and facilitating advanced research for postgraduates and research associates. The Electrical Power Generating station features 2KVA Alternator sets with three-phase protection relays, ensuring robust overcurrent, Earth fault, overvoltage, and under-voltage protection.',
'content_2':"The Smart Grid Test Bench features a 3 KW solar inverter with protective measures, a 3.73 KW Induction Generator for fault simulation, a robust Electrical Power Transmission System, an Electrical Grid station with Autotransformer and Isolation transformer, and a Distribution station with transformers and fault protection. It's IoT-enabled with SCADA integration for real-time smart grid research and learning."
},
{
'id':'12',
'heading':'NextGen Lab',
'pic':swayam,
'content_1':'The Next Gen Lab, established in collaboration with 1994 batch alumni, is a hub for exploring cutting-edge technologies. This facility fosters knowledge in Internet of Things (IoT), Robotics, and Artificial Intelligence (AI).',
'content_2':" It houses a range of advanced equipment including Arduino Uno, Bluetooth 5 Development Kits, Intel Movidius computing stick, Dragino Lora Shield, Raspberry Pi module, Arduino processors, Xbee and Zigbee communication modules, 3D prototype development with Prusa i3 3D printer, NVIDIA Jetson Nano GPU-based Processor, Intel RealSense Robotic Development Kit, and Turtle Bot Burger, offering students a dynamic space to innovate and experiment."
},
]

const eee_dept_events=[
  {
      'id':'1',
      'heading':"One Week Training on Robotics",
      'sub-head':'Some representative placeholder content for the second slide of the carousel.',
      'pic':robotics,
      'content_1':'Our Commitment as a Centre of Engineering Education is to impart Technical Knowledge par excellence, motivate the learners in Research, evolve result-oriented, innovative techniques in Engineering, provide necessary career guidance, and train our learners'+
                  'in leadership qualities so as to achieve better productivity and prosperity for our country.',
      'content_2':"ACGCET, a leader in higher education in engineering and technology, is committed to excellence through the delivery of outstanding undergraduate and graduate education that remains current and responsive to the diverse needs of those it serves. The institution’s"+
      "faculty and staff promote the intellectual, professional, social, and personal development of its students through innovations in learning, scholarship, and creative endeavours. Students, faculty, and staff partner to create strong"+
      "engagement with the local, national, and global communities. The institution dedicates itself to academic vitality, integrity, and diversity."
  },
  {
      'id':'2',
      'heading':'Placement Activities',
      'sub-head':'Some representative placeholder content for the second slide of the carousel.',
      'pic':placement,
      'content_1':'Our Commitment as a Centre of Engineering Education is to impart Technical Knowledge par excellence, motivate the learners in Research, evolve result-oriented, innovative techniques in Engineering, provide necessary career guidance, and train our learners'+
                  'in leadership qualities so as to achieve better productivity and prosperity for our country.',
      'content_2':"ACGCET, a leader in higher education in engineering and technology, is committed to excellence through the delivery of outstanding undergraduate and graduate education that remains current and responsive to the diverse needs of those it serves. The institution’s"+
      "faculty and staff promote the intellectual, professional, social, and personal development of its students through innovations in learning, scholarship, and creative endeavours. Students, faculty, and staff partner to create strong"+
      "engagement with the local, national, and global communities. The institution dedicates itself to academic vitality, integrity, and diversity."
  },
]
const features=[
  {
    id:"1",
    point:"Universal Machines Bed assembly where AC Motor, DC Motor, AC Generator and DC Generator aree coupled in one line shaft as a High Precisions Assembly"
  },
  {
    id:"2",
    point:"Programmable machine selection using an EM Clutch facilitates conducting all the required experiments of UG curriculum"
  },
  {
    id:"3",
    point:"Custom made table completely embedded with all required controls, Instrumentaion and Machine required"
  },
  {
    id:"4",
    point:"PC with Data Acquisition System Based Machines and Drives Lab"
  },
  {
    id:"5",
    point:"Drive System with thee provision for communication adapter for Drive networking"
  },
  {
    id:"6",
    point:"System with Centralized Automation Controller which facilitates high speed networking of all the drives in the tables"
  },
  {
    id:"7",
    point:"Automation Controller have Man-Machine Interface to view various operation screens and networking of drives"
  },
  {
    id:"8",
    point:"Since all required components are embedded over the table itself, saves resources in terms of space, staff and time"
  },
]

const feedback=[
  {
    'id':'1',
    'heading':'Mohamed Nawas',
    'sub_text':'(2014 passed out BE EEE student)',
    'pic':s1,
    'content_1':'Greetings, As a UG student I am very much excited to have such an advanced Drives Laboratory in my college. I feel very pleased to see drives which we studied in Text books and I got an opportunity to study, research about those drives. I got an opportunity to do my final year project "MULTI VARIABLE SELF TUNING CONTROL OF ALTERNATOR" at the Modern Drives Laboratory. ',
    'content_2':"It gave a good opportunity to learn advanced devices such as Transducers, HMI, DAQ card, programming these devices with LabVIEW which will be a future advancement in the field of Virtual instrumentation. I recommend that students who are eager to work in the field of industry can use this opportunity to learn about drives. So those who are really interested in our department should give 24x7 Laboratory support to them and more detailed training on Virtual instrumentation is needed for those who really deserve it. I thank my college for giving me this opportunity and for supporting me."
    },
  {
      'id':'2',
      'heading':'V. Arun',
      'sub_text':'2016 passed out ME PED student',
      'pic':s2,
      'content_1':'During my PG study time, modern electric drive systems setup is helpful in learning soft starting, different types of braking, and various modes of operation and control of drives from remote places with graphical preview as in the practical aspects. To know the real time software LabVIEW, PLC which are incorporated with the setup.',
      'content_2':"This drives the system with LabVIEW software, is more useful to do research work, and it is more user friendly to interface with the hardware. Now I‟m working as an Application Engineer in an Electrical Automation Company, here I worked with Phoenix Contact PLC Controllers & Compton Greaves (CG) Emotron VFX, FD Drives and Emotron soft starters, shaft power monitors. Modern Electric drive provides a platform for my career and getting a job."
      },
      {
        'id':'3',
        'heading':'D. Arunkumar',
        'sub_text':'2015 passed out BE EEE student',
        'pic':s3,
        'content_1':'It‟s very helpful for my learning because in the early days we understood the concept of electrical machines based on our imagination only but now that all imagination concepts come true under modern electrical drives lab. I have done my final year project using a modern electric drives lab.',
        'content_2':"Here I see the virtual representation (or) real time representation of each and every performance of motor, generator an transformer, Especially B-H curve analysis is one of our successful experiments in that drives lab. It's really useful for our ACCET students if they will use it in the future. It‟s very full for me while questions are raised about machines and their related parameters in job interviews. If we implement some of the electrical machine experiments in modern electric drives lab students get more knowledge because this LabVIEW is mainly real time signal processing software so based on that we will gain more knowledge."
        },
        {
          'id':'4',
          'heading':'B. Aaraamuthan',
          'sub_text':'2016 passed out BE EEE student',
          'pic':s4,
          'content_1':'Myself, B. Aaraamuthan, 2013-2016 batch student of Electrical and Electronics engineering department, ACCET, Karaikudi. I did my final year project titled “Real-time monitoring and control of Electric drives”. I made use of the “Modern Electric drive system” setup in our department. I immensely thank my guide, the department head for  providing me such a wonderful opportunity to complete my final year project in such a prestigious electric drive.',
          'content_2':"From my present point of view, the electric drive which I worked on was very well equipped and was very user friendly. This project could have been quite complicated in conventional methods but in this modern drive setup, it was very easy to obtain the dynamic characteristics of the system which was considered. In addition to that advantage mentioned, the electric drive can adapt to the LabVIEW-real time application software. On the whole the entire project is very useful for me and my batch mates. At present I am pursuing my master degree in Power System Engineering from College of Engineering-Guindy (Anna university-Chennai). I also found many applications for my drives project which I feel are much easier to understand. I once again thank all faculties for giving me permission to work on electric drives."
          },
          {
            'id':'5',
            'heading':'D. Ankith Mishra',
            'sub_text':'2017 passed out BE EEE student',
            'pic':s5,
            'content_1':'I, along with project mates, did a project titled “Performance analysis of Induction motor with LabVIEW realized Space Vector Modulation”. Doing the project work was a really good experience but at the same time',
            'content_2':"we faced a lot of difficulties such as designing the program for Space Vector modulation, interfacing of LabVIEW software with the NI 9067 module etcetera. We thank our department, for giving us the opportunity to work in the Drives Laboratory which has been incorporated with latest hardware and software along with other Drives related machines."
            },
            {
              'id':'6',
              'heading':'K.Vijaya Raghavan',
              'sub_text':'2017 passed out BE EEE student',
              'pic':s6,
              'content_1':'I, along with my project mates, did a project on “Performance analysis of 3 phase induction motor using LabVIEW realized Space vector pulse width modulation”. In our project, we worked in the Electrical Drives Laboratory in our EEE department. In the Electrical Drives Laboratory, we used the 3 phase induction motor and monitored the parameters like voltage, current, speed using the drives setup.',
              'content_2':"We generated pulses for the 3 phase induction motor using LabVIEW and interfaced with the inverter using CompactRIO. In our project, we faced a lot of problems which include generation of pulses for the inverter (which is a high frequency pulse). We also faced challenges in interfacing with CRIO. Our faculty members made it easy for us to work in the Drives laboratory. Everyone gave us freedom to experiment our technical thoughts."
              },
              {
                'id':'7',
                'heading':'Vishal Balaji P M',
                'sub_text':'2017 passed out BE EEE student',
                'pic':s7,
                'content_1':'Greetings, During my Undergraduate final year, my team of four did our project on “Analysis of four Quadrant Operation of DC motor-based hoist drive using LabVIEW”. Four Quadrants of operation is a unique property in DC motor and it was yet to be implemented practically until then.',
                'content_2':"Our team made use of LabVIEW - graphical programming language to provide input to the DC motor drive which was available in our department to demonstrate our project. Additionally, we implemented voice mode controlled operation of the project, aiding the visually impaired in operating the drive. Today I feel grateful to the faculty members of EEE for giving me an amazing opportunity to work on the DC motor drive using LabVIEW. The project played a pivotal role in helping me receive an internship opportunity in Danfoss Drives Ltd., after my graduation. Thanks,"
                },
                {
                  'id':'8',
                  'heading':'Deepak T',
                  'sub_text':'B.E.,(EEE) - 2020 batch student',
                  'pic':s8,
                  'content_1':'Greetings, During my UG programme, utilizing the lab facilities in our department gave me practical exposure. The demonstration classes using LabVIEW software, real time data acquisition hardware and modern drive system for',
                  'content_2':"virtual instrumentation and solid state drives‟ courses made better understanding for theory courses. I thank all my department faculty members and technical staff for giving me this opportunity to use the facilities."
                  },
                  {
                    'id':'9',
                    'heading':'SELVA KARUPPASAMY. M',
                    'sub_text':'2022 Batch B.E. EEE student',
                    'pic':s9,
                    'content_1':'Me and my project mates did the mini project - "Real time Speed Control of DC motor using LabVIEW ". During this project, we faced lots of technical issues in acquiring real time signals initially but gain more practical knowledge.',
                    'content_2':"We also gain a hands-on experience by handling the industrial setup hardware. At last, we are exposed to real time signal acquisition using LabVIEW and DAQ. We thank our department for the wonderful opportunity. This project gave us more confident and better understanding."
                    },
]
const events_info=[
  {
    id:"1",
    point:"Alconesy – Inter College Technical Symposium"
  },
  {
    id:"2",
    point:"Think Quest – Intra College Technical Symposium"
  }
]
const eee_staff=[
  {
    id:"1",
    // coverpic:Ramadas,
    name:"Prof K Ramadas",
    batch:"Dept of EEE",
    desc:"ACGCET, Karaikudi 03",
    date:"Associate Professor(CAS)",
  }
]
const eee_event=[
  {
    'sno':"1",
    'title':"Think Quest 2K19",
    'chiefguest':"Internal event",
    'date':"13.02.2019"
  },
  {
    'sno':"2",
    'title':"ALCONESY’18",
    'chiefguest':"Dr.M.L.Valarmathi (Principal, Government College of Engineering, Dharmapuri), G. Senthi Kumar (Deputy General Manager, Electrical, BGR Energy Systems Ltd, Chennai)",
    'date':"12.09.2018"
  },
  {
    'sno':"3",
    'title':"Think Quest 2K18",
    'chiefguest':"Internal event",
    'date':"16.02.2018"
  },
  {
    'sno':"4",
    'title':"ALCONESY’17",
    'chiefguest':"Er.Venkatesh Narasimhan (Director of KRIPYA Engineering Pte. Ltd. Chennai)",
    'date':"12.10.2017"
  },
  {
    'sno':"5",
    'title':"Think Quest 2K16",
    'chiefguest':"Internal event",
    'date':"25.02.2016"
  },
  {
    'sno':"6",
    'title':"ALCONESY’15",
    'chiefguest':"Mr.Thirugnana Sambandam Gurunathan (Chairman,iScottsbergPte. Ltd. Singapore)",
    'date':"22.09.2015"
  },
]
const eee_events_info1=[
  {
    id:"1",
    point:"Paper Presentation"
  },
  {
    id:"2",
    point:"C Programming Event"
  },
  {
    id:"3",
    point:"Robo Soccer"
  },
  {
    id:"4",
    point:"Quiz"
  },
  {
    id:"5",
    point:"Faraday’s Friend"
  },
]
const eee_events_info2=[
  {
    id:"1",
    point:"Project Presentation"
  },
  {
    id:"2",
    point: "Do or Die"
  },
  {
    id:"3",
    point:"Technical Quiz"
  },
  {
    id:"4",
    point:"See Through C"
  },
  {
    id:"5",
    point:"Paper presentation"
  },
  {
    id:"6",
    point:"Robo soccer"
  },
  {
    id:"7",
    point:" Jerk it If You can"
  },
  {
    id:"8",
    point:"Unlock not with your Keys"
  },
  {
    id:"9",
    point:"Touch me not Challenge (Electric Fence)"
  },
  {
    id:"10",
    point:"Race but not on Wheels"
  },
  {
    id:"11",
    point:"Breath and blow to glow"
  },
  {
    id:"12",
    point:"Lighten the life"
  },
  {
    id:"13",
    point:"Blow with your breath"
  },
]
const eee_events_info3=[
  {
    id:"1",
    point:"Paper Presentation"
  },
  {
    id:"2",
    point:"C Programming Event"
  },
  {
    id:"3",
    point:"Robo Soccer"
  },
  {
    id:"4",
    point:"Quiz"
  },
  {
    id:"5",
    point:"Faraday’s Friend"
  },
  {
    id:"6",
    point:"C Chef"
  },
]
const eee_events_info4=[
  {
    id:"1",
    point:"Project Presentation"
  },
  {
    id:"2",
    point: "Paper presentation"
  },
  {
    id:"3",
    point:"See Through “c”"
  },
  {
    id:"4",
    point:"Technical Quiz"
  },
  {
    id:"5",
    point:"Faraday’s Friend"
  },
  {
    id:"6",
    point:"Robo soccer"
  },
  {
    id:"7",
    point:"Maze runner"
  },
  {
    id:"8",
    point:"Formation of pyramids"
  },
  {
    id:"9",
    point:" Workshop on IoT-LoRa"
  },
]
const eee_events_info5=[
  {
    id:"1",
    point:"Paper Presentation"
  },
  {
    id:"2",
    point:"Solar Car Race"
  },
  {
    id:"3",
    point:"C Chef",
  },
  {
    id:"4",
    point:"Quiz"
  },
  {
    id:"5",
    point:"Circuit Design"
  },
]
const eee_events_info6=[
  {
    id:"1",
    point:"Project Presentation"
  },
  {
    id:"2",
    point: "Paper presentation"
  },
  {
    id:"3",
    point:"See Through “c”"
  },
  {
    id:"4",
    point:"Technical Quiz"
  },
  {
    id:"5",
    point:"Circuitrix"
  },
  {
    id:"6",
    point:"Robo soccer"
  },
  {
    id:"7",
    point:"Maze runner"
  },
  {
    id:"8",
    point:"Formation of pyramids"
  },
  {
    id:"9",
    point:" Workshop on IoT-LoRa"
  },
]


export {
  eee_dept_menu,
  eee_fac_profiles,
  eee_sup_profiles,
  eee_mission,
  eee_vision,
  eee_progs,
  eee_outcomes,
  eee_peo,
  eee_spec_outcomes,
  eee_dept_events,
  eee_labs,eee_hod,
  eee_research_info,
  scholar_info1,
  scholar_info2,
  scholar_info3,
  scholar_info4,
  scholar_info5,
  pub1,
  pub2,
  pub3,
  pub4,
  pub5,
  eee_gen,
  features,
  feedback,
  studcorner,
  events_info,
  eee_staff,
  eee_event,
  eee_events_info1,
  eee_events_info2,
  eee_events_info3,
  eee_events_info4,
  eee_events_info5,
  eee_events_info6
};
