import sdg from '../assets/pictures/sdgcover.png';
const Syed="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Mech/Faculties/Teaching_fac/17.Mr.A.Syed+Abdulla+AP(Contract).webp"
const venkat="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Civil/Faculties/Teaching_fac/Civil+Venkatesh+Baskaran-1.webp"
  const Geetha="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Mech/Faculties/Teaching_fac/3.Dr.S.GEETHA.webp"
  const s1="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/cocurricular/ncc/ncc/loki.webp"
//   const s1cover="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/cocurricular/ncc/ncc/ncccover.webp"

  export const sdg_coordinator =[
    {
        'id':"1",
        'cover': sdg,
        'dp':Geetha,
        'name':"Dr. S. Geetha",
        'Designation':"Assosiate Proffesor/ Mechanical Engg.",
        'mailid':"gitskumaresh@gmail.com"
    },
    {
        'id':"2",
        'cover': sdg,
        'dp':s1,
        'name':"prof.K.T.Loganathan",
        'Designation':"Assistant proffesor /Chemistry department ",
        'mailid':"logchem80@gmail.com"
    },
    {
        'id':"3",
        'cover': sdg,
        'dp':venkat,
        'name':"prof.B.Venkatesh",
        'Designation':"Assistant proffesor /Civil Engg ",
        'mailid':"venkatesh@gcetly.ac.in"
    },
    {
        'id':"2",
        'cover': sdg,
        'dp':Syed,
        'name':"Mr.A.Syed Abdullah",
        'Designation':"Assistant Professor/Mechanical Engg ",
        'mailid':"abdullahh50@gmail.com"
    },
    


];
export const plannedactivities=[
    {
        id:1,
        point:'Awareness Campaigns: Conduct workshops, seminars, and social media campaigns to educate students about SDGs and their importance.'
      },
    {
        id:1,
        point:'Educational Initiatives: Organize SDG-focused courses to enhance knowledge and critical discussions.'
      },
    {
        id:1,
        point:'Community Engagement: Engage students in volunteer work and other social initiatives to support local communities.'
      },
    {
        id:1,
        point:'Research and Innovation: Encourage SDG-focused research, hackathons, and case competitions to develop innovative, real-world solutions.'
      },
    {
        id:1,
        point:'Sustainability Initiatives on Campus: Promote green practices, recycling, sustainable fashion, and awareness through art and campus-wide initiatives.'
      },
    {
        id:1,
        point:'Policy Engagement: Organize panel discussions with policymakers to discuss the implementation of the SDGs at local and national levels.'
      },
    {
        id:1,
        point:'Global Engagement: Participate in international conferences, cultural exchanges, and partnerships to expand global SDG efforts.'
      },
    {
        id:1,
        point:'Monitoring and Reporting: Track SDG progress, impact assessments, and publish reports to measure and enhance club contributions.'
      },
    {
        id:1,
        point:'Celebration and Recognition: Host awards and annual events to recognize SDG contributions and celebrate achievements within the university.'
      },
    
]