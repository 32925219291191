import React, { useState } from 'react';
import './Employer_fb.css';
import { Backtotop, Underline,Alertmessage } from "../../widgets";
import { CollegeFeedbackQuestions } from "../../constants/feedbackQuestions";
import axios from 'axios';

const Std_fac = () => {
    const [alertMessage, setAlertMessage] = useState('');
    const [programme, setProgramme] = useState('');
    const [department, setDepartment] = useState('');
    const [semester, setSemester] = useState('');
    const [suggestion, setSuggestion] = useState('');
    const [ratings, setRatings] = useState({});
    const [ratingErrors, setRatingErrors] = useState({});
    const validateRatings = () => {
        const errors = {};
        CollegeFeedbackQuestions .forEach(({ name }) => {
            if (!ratings[name]) {
                errors[name] = 'Please select a rating (தயவுசெய்து மதிப்பீட்டை தேர்வு செய்யவும்)';
            }
        });
        setRatingErrors(errors);
        return Object.keys(errors).length === 0;
    };


    const handleRatingChange = (e, name) => {
        setRatings(prevRatings => ({
            ...prevRatings,
            [name]: parseInt(e.target.value, 10)
        }));
        setRatingErrors(prevErrors => ({
            ...prevErrors,
            [name]: ''
        }));
    };


    const SatisyFeedbackSubmit = async (event) => {
        event.preventDefault();
        console.log(ratings)

        const areRatingsValid = validateRatings();

        if ( !areRatingsValid) {
            return;
        }

        try {
            const response = await axios.post('/backend/studentStatisfaction', {
                programme: programme,
                department: department,
                semester: semester,
                rating: ratings
            });

            console.log(response.data);
            setAlertMessage("Feedback submitted successfully");
            setProgramme('');
            setSemester('');
            setDepartment('');
          
            setSuggestion('');
            setRatings({});
            setRatingErrors({}); 
        } catch (error) {
            console.error('Error:', error);
           setAlertMessage("An error occurred while submitting your ratings");
        }
    };
    const handleCloseAlert = () => setAlertMessage('');
    return (
        <div className='employer_fb_container'>
            <Underline heading="Student Satisfaction on Facilities" />
            <form className='employer_fb_form' onSubmit={SatisyFeedbackSubmit}>
                <div className='employer_fb_row'>
               
                    </div>
                   
                    <select 
                       className='employer_fb_input' 
                       value={programme} 
                       onChange={(e) => setProgramme(e.target.value)} 
                       required
                    >
                        <option value="" disabled>Select Programme*</option>
                        <option value="BE">B.E</option>
                        <option value="ME">M.E</option>
                        <option value="PhD">PhD</option>
                    </select>

               
                <div className='employer_fb_row'>

                <select 
                       className='employer_fb_input' 
                       value={department} 
                       onChange={(e) => setDepartment(e.target.value)} 
                       required
                    >
                         <option value="" disabled selected>Department*</option>
                    <option value="CIV">CIVIL</option>
                    <option value="MEC">MECH</option>
                    <option value="EEE">EEE</option>
                    <option value="ECE">ECE</option>
                    <option value="CSE">CSE</option>
                    </select>   

                      <select 
                       className='employer_fb_input' 
                       value={semester} 
                       onChange={(e) => setSemester(e.target.value)} 
                       required
                    >
                        <option value="" disabled>Select Semester</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                       
                    </select>
                </div>           
                <h2 className="employer_fb_subtitle">Please give your valuable feedback on a scale</h2>
                <div>
                <p className="employer_fb_instruction">5 - Excellent,   4 - Good,   3 - Satisfied,   2 - Not Satisfied,   1 - Poor</p>
                <br />   
                {CollegeFeedbackQuestions .map(({ question, translation, name }) => (
                        <div className="employer_fb_question" key={name}>
                            <p>{question}</p>
                            <p>({translation})</p>
                            <div className="employer_fb_ratings">
                                {[1, 2, 3, 4, 5].map(num => (
                                    <label key={num}>
                                        <input 
                                            type="radio" 
                                            name={name} 
                                            value={num} 
                                            onChange={(e) => handleRatingChange(e, name)} 
                                            checked={ratings[name] === num} 
                                        />
                                        <span className="custom-radio">{num}</span>
                                    </label>
                                ))}
                            </div>
                            {ratingErrors[name] && <p className='employer_fb_error'>{ratingErrors[name]}</p>}
                        </div>
                    ))}
                    </div>
                    {/* <div className='employer_fb_row'>
                    <textarea 
                        className='employer_fb_textarea' 
                        placeholder='Any Suggestions' 
                        value={suggestion}
                        onChange={(e) => setSuggestion(e.target.value)}
                    />
                </div> */}
                <div className="employer_fb_btn">
                    <button type="submit" className="employer_fb_submit">Submit</button>
                </div>
            </form>
            <Backtotop />
            <Alertmessage message={alertMessage} onClose={handleCloseAlert} /> 
        </div>
    );
};

export default Std_fac;