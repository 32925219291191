import React, { useState } from 'react';
import './Employer_fb.css';
import { Backtotop, Underline,Alertmessage } from "../../widgets";
import { employerFeedbackQuestions } from "../../constants/feedbackQuestions";
import axios from 'axios';
import { TextField,Autocomplete } from '@mui/material';

const EmployeeFeedback = () => {
    const [hrName, setHrName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [companyAddress, setCompanyAddress] = useState('');
     const [alertMessage, setAlertMessage] = useState('');
    const [studentName, setStudentName] = useState('');
    const [programme, setProgramme] = useState('');

    const [yearOfJoining, setYearOfJoining] = useState();
    const [entryLevelPosition, setEntryLevelPosition] = useState('');
    const [currentPosition, setCurrentPosition] = useState('');
    const [responsibilities, setResponsibilities] = useState('');
    const [achievements, setAchievements] = useState('');
    const [suggestion, setSuggestion] = useState('');
    const [ratings, setRatings] = useState({});
    const [errors, setErrors] = useState({});
    const [nameError, setNameError] = useState({ hrName: '', studentName: '' });
    const [ratingErrors, setRatingErrors] = useState({});

    const validateName = (name, type) => {
        const nameRegex = /^[A-Za-z\s]+$/;
        if (!nameRegex.test(name)) {
            setNameError(prevErrors => ({
                ...prevErrors,
                [type]: 'Name should only contain letters and spaces.'
            }));
            return false;
        } else {
            setNameError(prevErrors => ({
                ...prevErrors,
                [type]: ''
            }));
            return true;
        }
    };

    const validateRatings = () => {
        const errors = {};
        employerFeedbackQuestions.forEach(({ name }) => {
            if (!ratings[name]) {
                errors[name] = 'Please select a rating (தயவுசெய்து மதிப்பீட்டை தேர்வு செய்யவும்)';
            }
        });
        setRatingErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleHrNameChange = (e) => {
        const name = e.target.value;
        setHrName(name);
        validateName(name, 'hrName');
    };

    const handleStudentNameChange = (e) => {
        const name = e.target.value;
        setStudentName(name);
        
    };

    const handleRatingChange = (e, name) => {
        setRatings(prevRatings => ({
            ...prevRatings,
            [name]: parseInt(e.target.value, 10)
        }));
        setRatingErrors(prevErrors => ({
            ...prevErrors,
            [name]: ''
        }));
    };

    const handleYearOfJoiningChange = (e) => {
        const value = e.target.value.trim();
        setYearOfJoining(value);
    
        if (value === '') {
           
            setErrors(prevErrors => ({ ...prevErrors, yearOfJoining: '' }));
            return;
        }
        
    
        const currentYear = new Date().getFullYear();
        if (value >= 1952 && value <= currentYear) {
            setErrors(prevErrors => ({ ...prevErrors, yearOfJoining: '' }));
        } else {
            setErrors(prevErrors => ({ ...prevErrors, yearOfJoining: 'Please enter a valid year between 1952 and ' + currentYear }));
        }
    };

    const EmployeeFeedbackSubmit = async (event) => {
        event.preventDefault();

        const isHrNameValid = validateName(hrName, 'hrName');
        // const isStudentNameValid = validateName(studentName, 'studentName');
        const areRatingsValid = validateRatings();

        if (!isHrNameValid || !areRatingsValid) {
            return;
        }

        try {
            const response = await axios.post('/backend/employeefeedbacksubmit', {
                hr_name: hrName,
                company_name: companyName,
                company_address: companyAddress,
                student_name: studentName,
                programme: programme,
              
                year_of_joining: yearOfJoining,
                entry_level_position: entryLevelPosition,
                current_position: currentPosition,
                responsibilities: responsibilities,
                achievements_awards: achievements,
                suggestion:suggestion,
                ratings: ratings 
            });

            console.log(response.data);
            setAlertMessage("Feedback submitted successfully");
            setHrName('');
            setCompanyName('');
            setCompanyAddress('');
            setStudentName('');
            setProgramme('');
          
            setYearOfJoining('');
            setEntryLevelPosition('');
            setCurrentPosition('');
            setResponsibilities('');
            setAchievements('');
            setSuggestion('');
            setRatings({});
            setRatingErrors({});
            setNameError({ hrName: '', studentName: '' }); 
        } catch (error) {
            console.error('Error:', error);
           setAlertMessage("An error occurred while submitting your ratings");
        }
    };
    const handleCloseAlert = () => setAlertMessage('');
    const programmes = ["B.E","M.E","PhD"];
    return (
        <div className='employer_fb_container'>
            <Underline heading="Employer Feedback" />
            <form className='employer_fb_form' onSubmit={EmployeeFeedbackSubmit}>
                {/* <div className='employer_fb_row'> */}
                    <div className='employer_fb_row'>
                    <TextField  
                        className='employer_fb_input' 
                        label='Name of the HR*' 
                        value={hrName}
                        onChange={handleHrNameChange} 
                        required 
                    />
                    </div>
                    {nameError.hrName && <p className='employer_fb_error'>{nameError.hrName}</p>}
                    <div className='employer_fb_row'>
                    <TextField  
                        className='employer_fb_input' 
                        label='Name of the company*' 
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        required 
                    />
                    </div>
                {/* </div> */}
               
                <div className='employer_fb_row'>
                    <TextField  
                        className='employer_fb_input_full' 
                        label='Address of the company' 
                        value={companyAddress}
                        onChange={(e) => setCompanyAddress(e.target.value)}
                    />
                </div>
                {/* <div className='employer_fb_row'> */}
                <div className='employer_fb_row'>
                    <TextField  
                        className='employer_fb_input' 
                        label='Name of the student (optional)' 
                        value={studentName}
                        onChange={handleStudentNameChange} 
                       
                    />
                    </div>
                   
                    {/* <select 
                       className='employer_fb_input' 
                       value={programme} 
                       onChange={(e) => setProgramme(e.target.value)} 
                       required
                    >
                        <option value="" disabled>Select Programme*</option>
                        <option value="BE">B.E</option>
                        <option value="ME">M.E</option>
                        <option value="PhD">PhD</option>
                    </select> */}
                    <Autocomplete
                    disablePortal
                    options={programmes}
                    sx={{ width: "100%",marginBottom:"15px" }}
                    onChange={(event, newValue) => setProgramme(newValue || "")}
                    renderInput={(params) => <TextField {...params} label="Select Programme" required />}
                    />
                {/* </div> */}
               
                {/* <div className='employer_fb_row'>
                



                    <input 
                        type="text" 
                        className='employer_fb_input' 
                        placeholder='Year of joining the company(optional)' 
                        value={yearOfJoining}
                        onChange={handleYearOfJoiningChange} maxLength={4}
                       
                    />
                   
                </div> */}
                
                {errors.yearOfJoining && <p className='employer_year_of_joining_container'>{errors.yearOfJoining}</p>}
                <div className='employer_fb_row'>
                    <TextField 
                        className='employer_fb_input' 
                        label='Position at the entry level' 
                        value={entryLevelPosition}
                        onChange={(e) => setEntryLevelPosition(e.target.value)}
                    />
                    <TextField  
                        className='employer_fb_input' 
                        label='Current position' 
                        value={currentPosition}
                        onChange={(e) => setCurrentPosition(e.target.value)}
                    />
                </div>

                <div className='employer_fb_row'>
                    <TextField 
                        className='employer_fb_textarea' 
                        label='Responsibilities held' 
                        value={responsibilities}
                        onChange={(e) => setResponsibilities(e.target.value)}
                    />
                </div>
                <div className='employer_fb_row'>
                    <TextField 
                        className='employer_fb_textarea' 
                        label='Achievements/Awards' 
                        value={achievements}
                        onChange={(e) => setAchievements(e.target.value)}
                    />
                </div>
                <h2 className="employer_fb_subtitle">Please give your valuable feedback on a scale</h2>
                <div>
                <p className="employer_fb_instruction">5 - Excellent,   4 - Good,   3 - Satisfied,   2 - Not Satisfied,   1 - Poor</p>
                <br />   
                {employerFeedbackQuestions.map(({ question, translation, name }) => (
                        <div className="employer_fb_question" key={name}>
                            <p>{question}</p>
                            <p>({translation})</p>
                            <div className="employer_fb_ratings">
                                {[1, 2, 3, 4, 5].map(num => (
                                    <label key={num}>
                                        <input 
                                            type="radio" 
                                            name={name} 
                                            value={num} 
                                            onChange={(e) => handleRatingChange(e, name)} 
                                            checked={ratings[name] === num} 
                                        />
                                        <span className="custom-radio">{num}</span>
                                    </label>
                                ))}
                            </div>
                            {ratingErrors[name] && <p className='employer_fb_error'>{ratingErrors[name]}</p>}
                        </div>
                    ))}
                    </div>
                    <div className='employer_fb_row'>
                    <TextField 
                        className='employer_fb_textarea' 
                        label='Any Suggestions' 
                        value={suggestion}
                        onChange={(e) => setSuggestion(e.target.value)}
                    />
                </div>
                <div className="employer_fb_btn">
                    <button type="submit" className="employer_fb_submit">Submit</button>
                </div>
            </form>
            <Backtotop />
            <Alertmessage message={alertMessage} onClose={handleCloseAlert} /> 
        </div>
    );
};

export default EmployeeFeedback;