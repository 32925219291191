import axios from 'axios';
import React, { useState } from 'react';
import { Underline,Floatinmenu } from "../../widgets";
import { admin_menu } from "../../constants/dashboard";
import './ForgetPassword.css';

function ForgetPassword() {
  const [regno, setRegno] = useState('');

  const handleChangeRegno = (e) => setRegno(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!regno) {
      alert("Please enter a register number");
      return;
    }

    const data = { regno };

    try {
      const response = await axios.post('/backend/resetforgetpass', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      alert(response.data.message);
    } catch (error) {
      console.error("Error:", error);
      alert(error.response?.data?.message || "Something went wrong!");
    }
  };
  

  return (
    <div className='forgetpassword'>
     <Underline heading={"Forgot Password"} />
      <Floatinmenu head="Menu" links={admin_menu} />
      <div className='forgetpass_div'>
      <input 
        className='password_regno' 
        type="number" 
        placeholder='Enter Regno' 
        value={regno}
        onChange={handleChangeRegno}
      />
      <button onClick={handleSubmit}>Submit</button>
    </div>
    <div className="logout_button">
      <a className="log_link" href="/logout">
        Logout
      </a>
    </div>
    </div>
  );
}

export default ForgetPassword;
