export const academic_cals=[



    {
        'id':"5",
        "head":"2024-2025",
        "house":"left",
        "pdf":"https://drive.google.com/file/d/16naf08ToVf0Hjx2S9f0rNaOqfaJH-656/view?usp=sharing"
    },
    {
        'id':"4",
        "head":"2023-2024",
        "house":"right",
        "pdf":"https://drive.google.com/file/d/17fXQlc0LIvynXcFGgBdRtE4KsC7EgsIu/view?usp=sharing"
    },
    {
        'id':"1",
        "head":"2022-2023",
        "house":"left",
        "pdf":"https://drive.google.com/file/d/1TnGT1GWi8DhQYioIUJkOs_lbs6IQXH1O/view?usp=drive_link"
    },
    {
        'id':"2",
        "head":"2021-2022",
        "house":"right",
        "pdf":"https://drive.google.com/file/d/1Qv13Zr9O8p55xiHEYdYkKX91eurEnnFD/view?usp=drive_link"
    },
    {
        'id':"3",
        "head":"2020-2021",
        "house":"left",
        "pdf":"https://drive.google.com/file/d/1GB6yrkLkEM-Djm5HzaGQhsKjVpPxHsgx/view?usp=drive_link"
    },
]