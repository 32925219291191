// reccarousal
// import zoho from "../assets/pictures/zoho.webp";
// import hcl from "../assets/pictures/hcl.webp";
// import lt from "../assets/pictures/lt.webp";
// import gofrugal from "../assets/pictures/gofrugal.webp";
// import wipro from "../assets/pictures/wipro.webp";
// import whiteblue from "../assets/pictures/whiteblue.webp";
import zoho from "../assets/pictures/zoho.webp"
//const zoho="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/about/home/zoho.webp"
const lt="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/about/home/lt.webp"
//const gofrugal="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/about/home/gofrugal.webp"
const whiteblue="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/about/home/whiteblue.webp"
export const reccarousal=[
  zoho,
  // hcl,
  lt,
  // wipro,
  whiteblue
]