const acs =
  "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/placement/company_logo/acs.webp";
const admindroid =
  "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/placement/company_logo/admindroid.webp";
const base =
  "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/placement/company_logo/base_automation.webp";
const brakes =
  "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/placement/company_logo/brakes.webp";
const centizen =
  "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/placement/company_logo/Centizen.webp";
const data_aces =
  "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/placement/company_logo/data_aces.webp";
const edify =
  "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/placement/company_logo/edify.webp";
const face =
  "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/placement/company_logo/face.webp";
const finsurge =
  "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/placement/company_logo/finsurge.webp";
const flsmidth =
  "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/placement/company_logo/flsmidth.webp";
const fourbends =
  "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/placement/company_logo/fourbends.webp";
const geakminds =
  "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/placement/company_logo/Geakminds.webp";
const gofrugal =
  "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/placement/company_logo/gofrugal.webp";
const hand =
  "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/placement/company_logo/hand.webp";
const hcl =
  "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/placement/company_logo/hcl.webp";
const hexawave =
  "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/placement/company_logo/hexawave.webp";
const infofaces =
  "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/placement/company_logo/Infofaces.webp";
const jetz =
  "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/placement/company_logo/jetz.webp";
const lightcast =
  "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/placement/company_logo/lightcast.webp";
const lt =
  "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/placement/company_logo/lt.webp";
const lucas_tvs =
  "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/placement/company_logo/lucas_tvs.webp";
const mistral =
  "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/placement/company_logo/mistral.webp";
const p =
  "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/placement/company_logo/p.webp";
const pentagon =
  "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/placement/company_logo/pentagon_space.webp";
const pentalpha =
  "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/placement/company_logo/pentalpha.webp";
const propal =
  "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/placement/company_logo/propal.webp";
const saintgobain =
  "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/placement/company_logo/saint_gobain.webp";
const scas =
  "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/placement/company_logo/scas.webp";
const schneider =
  "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/placement/company_logo/schneider.webp";
const survey =
  "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/placement/company_logo/survey_sparrow.webp";
const mahindra =
  "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/placement/company_logo/tech_mahindra.webp";
const titan =
  "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/placement/company_logo/titan.webp";
const tns =
  "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/placement/company_logo/Tns.webp";
const valeo =
  "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/placement/company_logo/valeo.webp";
const vi =
  "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/placement/company_logo/vi_microsystems.webp";
const wafer =
  "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/placement/company_logo/wafer_space.webp";
const whiteblue =
  "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/placement/company_logo/whiteblue.webp";
const wipro =
  "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/placement/company_logo/wipro.webp";
const yamaha =
  "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/placement/company_logo/yamaha.webp";
const zf =
  "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/placement/company_logo/zf.webp";
const zoho =
  "https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/placement/company_logo/zoho.webp";
const baskaran="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Eee/faculties/teachingfac/1-Baskaran-_3_.webp";
const suganthi="";
const cover="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/placement/placement-bg.webp";
const dpf="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Eee/girl.webp"

const placement_menu = [
  {
    id: "1",
    path: "/placement",
    link: "Overview",
    arl: "l",
  },
  {
    id: "2",
    path: "/placement/Placement_wk_training",
    link: "Training and Workshops",
    arl: "l",
  },
  {
    id: "7",
    path: "/placement/placement_statistics",
    link: "Placement Statistics",
    arl: "l",
  },
  // {
  //     id: "2",
  //     path: "/placement/alumni_stories",
  //     link: "Alumni Success Stories",
  //     arl:'l'
  // },
  {
    id: "5",
    path: "/placement/our_recruiters",
    link: "Our Recruiters",
    arl: "l",
  },
  {
    id: "4",
    path: "/placement/faqs",
    link: "FAQs",
    arl: "l",
  },
];

const placement_faqs = [
  {
    id: 1,
    heading:
      "Are all students in the institute eligible to participate in campus placements?",
    component: "para",
    data: "Yes, all students interested to go for jobs after graduation are eligible for campus placements.",
  },
  {
    id: 2,
    heading:
      "Does the college organize any pre-placement training for students participating in campus placements?",
    component: "para",
    data: "Training on specific core domains, communication skills, facing interviews are provided to students participating in campus placements.",
  },
  {
    id: 3,
    heading: "To what extent do projects enhance the value of a resume?",
    component: "para",
    data: "Student projects play a major role in understanding their potential and skills such as problem solving, creative and critical thinking, technical expertise etc, which is what any organisation looks for to recruit. So, projects can easily fetch job offers for students.",
  },
  {
    id: 4,
    heading:
      "What steps should a student take to prepare for placement or internship opportunities?",
    component: "para",
    data: "A student should have a flair for learning new trends and technologies, self learning attitude and participation in various competitions, hackathons, project presentations, engaging in co-curricular and extra curricular activities are the various ways to prepare for placement opportunities.",
  },
  {
    id: 5,
    heading:
      "Can you provide tips or guidelines for crafting an effective resume for job placements?",
    component: "listformat",
    data: [
      {
        id: 1,
        point: "A creative, own crafted resume that is true in all aspects.",
      },
      {
        id: 2,
        point:
          "Objective and skill set to be meaningful, defining one’s personality.",
      },
      {
        id: 3,
        point:
          "To carry all information about the candidate in a concise manner.",
      },
      {
        id: 4,
        point:
          "All information to be consolidated in a single page in simple language.",
      },
      {
        id: 5,
        point: "Avoid jargons, highly sophisticated/ fancy language.",
      },
    ],
  },
  {
    id: 6,
    heading:
      "In what ways does the college support students in securing internship opportunities?",
    component: "para",
    data: "The student is provided internship opportunities through Naan Mudhalvan, alumni referrals and recruiters. The college also communicates various organisations through the department heads and gets internship opportunities for the students.",
  },
  {
    id: 7,
    heading:
      "What measures does the college take to sustain strong connections with industry professionals?",
    component: "para",
    data: "MoUs, guest lectures by industrial experts, inviting them as guests for the various  college programmes, campus visits by industry personnel, industrial visits, innovative projects help the college to sustain strong connections with the industry. The industries also provide their R&D problems for students to work on real time projects and find solutions.",
  },
  {
    id: 8,
    heading:
      "Whom should the student contact from the placement team for assistance, and what are their contact details?",
    component: "para",
    data: "The website carries the details of the Placement Officer and the Training & Placement Coordinator who can be communicated for placement related queries or assistance by the student.",
  },
  {
    id: 9,
    heading:
      "How does the college assist students facing challenges or uncertainties during the placement journey?",
    component: "para",
    data: "Students facing challenges are given training programmes, identifying the skill sets they are weak in. Mentoring by seniors and alumni who are successful in the respective areas of interest are connected with the students for continuous interactive sessions that improves the student’s self confidence and strengthens the skills.",
  },
  {
    id: 10,
    heading:
      "Is it possible for students to seek guidance from the placement team for career counselling and personalized advice?",
    component: "para",
    data: "Sure, we have a strong network of mentors who could guide the students in choosing their career paths and provide confidential, personal counselling to overcome their psychological and emotional problems.",
  },
  {
    id: 11,
    heading:
      "How do internships contribute to improving a student's chances during placements?",
    component: "para",
    data: "If a student undergoes an internship in his/her area of interest, the hands on experience helps to relate to the fundamental concepts that provides an edge over other students in campus interviews. Internships help students understood the real scenario of workplace, expectation of the industry which improves the confidence to face interviews. A good performer in internship can bag an offer at the same organisation itself.",
  },
  {
    id: 12,
    heading:
      "How does the college assist students in cultivating essential soft skills for job interviews?",
    component: "para",
    data: "Our college provides soft skill development sessions in the third year as part of the curriculum itself. Each student attends around 90 hours of soft skills training sessions to improve communication skills, team building skills and writing resumes. Mock interviews are conducted by industry people and group discussion sessions are organised as part of the activities to cultivate soft skills.",
  },
  {
    id: 13,
    heading:
      "Are there events or seminars where students can interact with industry professionals?",
    component: "para",
    data: "Very much. Students can interact with industrial professionals through various  technical events, symposiums, webinars and seminars on the latest trends and technologies that industries adapt and the interaction with clients.",
  },
  {
    id: 14,
    heading:
      "Are there provisions for students who prefer pursuing higher education instead of immediate placements?",
    component: "para",
    data: "The students who prefer higher education are provided guidance and coaching sessions for GATE, higher studies abroad etc. They are also provided guidance on various higher education opportunities , how to crack competitive examinations and also  awareness on scholarships and financial assistance of various universities in India and abroad.",
  },
  {
    id: 15,
    heading:
      "How do faculty members contribute to mentoring students for placements and career development?",
    component: "para",
    data: "The faculty motivate and guide students to identify problems in the society and find solutions to them. They also provide mentoring as per recruiting organisations requirements.",
  },
  {
    id: 16,
    heading:
      "How does the college incorporate emerging trends in the job market into its placement strategy?",
    component: "listformat",
    data: [
      {
        id: 1,
        point: "Real time industrial projects",
      },
      {
        id: 2,
        point: "Internships",
      },
      {
        id: 3,
        point: "Industrial visits",
      },
      {
        id: 4,
        point: "Curriculum revision as per job market",
      },
      {
        id: 5,
        point: "Value added courses as per emerging trends in the job market",
      },
    ],
  },
  {
    id: 17,
    heading:
      "Can you identify the companies that consistently recruit from our college and the industries in which they are prominent?",
    component: "para",
    data: "We have listed companies on our website, and all are our ardent recruiters",
  },
  {
    id: 18,
    heading:
      "What academic criteria must students fulfill to be eligible for the placement process?",
    component: "listformat",
    data: [
      {
        id: 1,
        point: "No history of arrears",
      },
      {
        id: 2,
        point: "More than 7 CGPA",
      },
      {
        id: 3,
        point: "Average to good communication skills",
      },
      {
        id: 4,
        point:
          "Participation in various co curricular and extra curricular activities",
      },
      {
        id: 5,
        point: "Online/Offline value added courses on areas of interest",
      },
      {
        id: 6,
        point: "Strong programming skills / core concept knowledge",
      },
      {
        id: 7,
        point: "Strong Aptitude and logical reasoning skills",
      },
      {
        id: 8,
        point: "Sincere, dedicated preparation for a period of two years",
      },
    ],
  },
  {
    id: 19,
    heading:
      "What makes Pre-Placement Talks (PPTs) crucial, and how can students participate in them?",
    component: "para",
    data: "All organisations organise a PPT before the campus drive. A student should attend this to understand the organisational profile, work culture, do’s and don’ts, career upliftment policies etc. to perform better in the face to face interviews. It helps to understand the process of recruitment and the expectations of the organisation from the freshers.",
  },
];
const placement_mock_drive = [
  {
    sno: "1",
    dept: "EEE",
    interviewer: "S. Anubama",
    company: "TVS Training and Services",
  },
  {
    sno: "2",
    dept: "EEE",
    interviewer: "S. Muthukumar",
    company: "M&M Automotive Division",
  },
  {
    sno: "3",
    dept: "Civil",
    interviewer: "Venkatesh Prabhakar",
    company: "JENO Maran Builders Pvt Ltd",
  },
  {
    sno: "4",
    dept: "Civil",
    interviewer: "Jayakumar",
    company: "Oasis Grace, Muscat",
  },
  {
    sno: "5",
    dept: "Mechanical",
    interviewer: "Swarnam J Vasudevan",
    company: "India Yamaha Motors",
  },

  {
    sno: "6",
    dept: "Mechanical",
    interviewer: "Sakthivel",
    company: "Sri Vigneswara Cold forge",
  },
  {
    sno: "7",
    dept: "Mechanical",
    interviewer: "R Kannan",
    company: "Maktec Engineering corporation",
  },
  {
    sno: "8",
    dept: "Mechanical",
    interviewer: "K Rajmohan",
    company: "BHEL",
  },
  {
    sno: "9",
    dept: "ECE",
    interviewer: "Meenakshi Shanmugam",
    company: "Career Coach",
  },
  {
    sno: "10",
    dept: "IT",
    interviewer: "Muneeswaran",
    company: "Zoho Corp with a team of 11 members from Zoho Corp, Karaikudi.",
  },
];
const placement_logo = [
  { id: "1", logo_img: acs, logo_desc: "acs" },
  { id: "2", logo_img: admindroid, logo_desc: "admindroid" },
  { id: "3", logo_img: base, logo_desc: "base" },
  { id: "4", logo_img: brakes, logo_desc: "brakes" },
  { id: "5", logo_img: centizen, logo_desc: "centizen" },
  { id: "6", logo_img: data_aces, logo_desc: "data_aces" },
  { id: "7", logo_img: edify, logo_desc: "edify" },
  { id: "8", logo_img: face, logo_desc: "face" },
  { id: "9", logo_img: finsurge, logo_desc: "finsurge" },
  { id: "10", logo_img: flsmidth, logo_desc: "flsmidth" },
  { id: "11", logo_img: fourbends, logo_desc: "fourbends" },
  { id: "12", logo_img: geakminds, logo_desc: "geakminds" },
  { id: "13", logo_img: gofrugal, logo_desc: "gofrugal" },
  { id: "14", logo_img: hand, logo_desc: "hand" },
  { id: "15", logo_img: hcl, logo_desc: "hcl" },
  { id: "16", logo_img: hexawave, logo_desc: "hexawave" },
  { id: "17", logo_img: infofaces, logo_desc: "infofaces" },
  { id: "18", logo_img: jetz, logo_desc: "jetz" },
  { id: "19", logo_img: lightcast, logo_desc: "lightcast" },
  { id: "20", logo_img: lt, logo_desc: "lt" },
  { id: "21", logo_img: lucas_tvs, logo_desc: "lucas_tvs" },
  { id: "22", logo_img: mistral, logo_desc: "mistral" },
  { id: "23", logo_img: p, logo_desc: "p" },
  { id: "24", logo_img: pentagon, logo_desc: "pentagon" },
  { id: "25", logo_img: pentalpha, logo_desc: "pentalpha" },
  { id: "26", logo_img: propal, logo_desc: "propal" },
  { id: "27", logo_img: saintgobain, logo_desc: "saintgobain" },
  { id: "28", logo_img: scas, logo_desc: "scas" },
  { id: "29", logo_img: schneider, logo_desc: "schneider" },
  { id: "30", logo_img: survey, logo_desc: "survey" },
  { id: "31", logo_img: mahindra, logo_desc: "mahindra" },
  { id: "32", logo_img: titan, logo_desc: "titan" },
  { id: "33", logo_img: tns, logo_desc: "tns" },
  { id: "34", logo_img: valeo, logo_desc: "valeo" },
  { id: "35", logo_img: vi, logo_desc: "vi" },
  { id: "36", logo_img: wafer, logo_desc: "wafer" },
  { id: "37", logo_img: whiteblue, logo_desc: "whiteblue" },
  { id: "38", logo_img: wipro, logo_desc: "wipro" },
  { id: "39", logo_img: yamaha, logo_desc: "yamaha" },
  { id: "40", logo_img: zf, logo_desc: "zf" },
  { id: "41", logo_img: zoho, logo_desc: "zoho" },
];

const placement_officers = [
  {
    id: "1",
    cover: cover,
    dp: baskaran,
    name: "Dr.K.Baskaran",
    Designation: "Placement Officer",
    mailid: "drbaskaran@accetedu.in",
    pdf: "",
  },
  {
    id: "1",
    cover: cover,
    dp: dpf,
    name: "Dr. X. Hyacinth Suganthi",
    Designation: "Training & Placement Coordinator",
    mailid: "placementcell@accet.ac.in",
    pdf: "",
  }
];

export { placement_menu, placement_faqs, placement_mock_drive, placement_logo,placement_officers };
