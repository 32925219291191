import React, { useEffect } from 'react';
import { Underline, Content_card } from "../../widgets";
import { Coe_mou_data } from '../../constants/Coe_mou';
import './Coe_mou.css';

const Coe_mou = () => {
  useEffect(() => {
    document.title = "ACCET-COE | MoU";
  }, []);

  return (
    <div className='Coe_mou'>
      <div > 
        <Underline heading="MoU" />
      </div>
      <div className="stdudent_fb_card_holder">
        {Coe_mou_data.map((item) => (
          <Content_card key={item.id} heading={item.program} view={item.pdf} />
        ))}
      </div>
    </div>
  );
};

export default Coe_mou;
