// Thiran_heroimage_carousalinfo

// import image1 from "../assets/pictures/thiran_grp.JPG";
// import image2 from "../assets/pictures/thiran_grp1.jpg";
// import image3 from "../assets/pictures/thiran_grp3.webp";




// import cover from "../assets/pictures/pangu auto.webp";
// import cover1 from "../assets/pictures/thiran_water.webp";
// import cover2 from "../assets/pictures/thiran_home.webp";
// import cover3 from "../assets/pictures/thiran_driver.webp";
// import cover4 from "../assets/pictures/compass.webp";
// import cover5 from "../assets/pictures/thiran_civil.webp";
const cover="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/cocurricular/thiran/pangu+auto.webp"
const cover1="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/cocurricular/thiran/thiran_water.webp"
const cover2="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/cocurricular/thiran/thiran_home.webp"
const cover3="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/cocurricular/thiran/thiran_driver.webp"
const cover4="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/cocurricular/thiran/compass.webp"
const cover5="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/cocurricular/thiran/thiran_civil.webp"
const cover6="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/cocurricular/thiran/download.webp"
const cover7="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/cocurricular/thiran/WHAT-IS-THE-PURPOSE-OF-A-LIBRARY-MANAGEMENT-SYSTEM-min.webp"
const cover8="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/cocurricular/thiran/ECG.webp"
const cover9="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/cocurricular/thiran/WIFO_solar_panels_IvanKmit.webp"
const cover10 ="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/cocurricular/thiran/Electromagnetic-active-suspension-system.webp"

const image1="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/thiran/thiran_grp.webp"
const image2="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/thiran/thiran_grp1.webp"
const image3="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/thiran/thiran_grp3.webp"





const Thiran_heroimage_carousalinfo = {
    id: 1,
    title: "Thiran",
    img_list: [image1, image2 ,image3],
  };

const vision= {

    id:'1',
    visiondata:'To enhance the knowledge of an individual in particular concern that is considered and improve the leadership skills  of an every individual by a set of achievable actions.'
  };

const mission_data=[
    {
      id:'1',
      mission:'To provide the standard approach for the porject execution.'
    },
    {
      id:'2',
      mission:'To bring forth the complete and accurate visibility of the project status.'
    },
    {
      id:'3',
      mission:'To set a effective prioritisation for the project management to support the fellowing teams to direct the projects.'
    },
    {
      id:'4',
      mission:'To improvise the  professional skills of an individual in project management.'
    }

  ]



const jury_awards=[
  {
      id:'1',
      coverpic:cover,
      batch:"CSE 2021-25 Batch",
      name:"Share Auto App",
      desc:"Sathiyan and Harish are the individuals responsible for making the project a reality.",
      date:"Thiran 2023"
  },
  {
    id:'2',
    coverpic:cover1,
    batch:"EEE 2020-24 Batch",
    name:"Smart Public Water Distribution System",
    desc:"K.Madhavan,S.Vinothkumar & M.M.Nidhish are the individuals responsible for making the project a reality.",
    date:"Thiran 2023"
  },
  {
    id:'3',
    coverpic:cover2,
    batch:"ECE 2020-24 Batch",
    name:"Home Automation",
    desc:"S.Thevi Srinidhi,J.Joselin Shinita & S.G.Jayaprakash are the individuals responsible for making the project a reality.",
    date:"Thiran 2023"
  },
  {
    id:'4',
    coverpic:cover3,
    batch:"EEE 2022-26 Batch",
    name:"Anti Sleep Alarm for Drivers",
    desc:"M.Hari Prasath, P.Ganesh, S.Sivasanagaran & V.Prathiksha Aara are the individuals responsible for making the project a reality.",
    date:"Thiran 2023"
  },
  {
    id:'5',
    coverpic:cover4,
    batch:"MECH 2020-24 Batch",
    name:"Compass",
    desc:"I.Jeffrey, S.Sathyan & S.Bala Mani Arasu are the individuals responsible for making the project a reality.",
    date:"Thiran 2023"
  },
  {
    id:'6',
    coverpic:cover5,
    batch:"CIVIL 2020-24 Batch",
    name:"Bacterial Concrete",
    desc:"B.Sruthi, S.Nivetha, B.Hamsini, S.Pavithra, R.Dinesh Raja & L.Nagarajan are the individuals responsible for making the project a reality.",
    date:"Thiran 2023"
  },
  {
    id:'7',
    coverpic:cover6,
    batch:"CIVIL 2021-25 Batch",
    name:"Biochar an Organic Charcoal",
    desc:"N.Mohana Priyan, M.Karthika, P.Huvisha, are the individuals responsible for making the project a reality.",
    date:"Thiran 2024"
  },
  {
    id:'8',
    coverpic:cover7,
    batch:"CSE 2022-26 Batch",
    name:"Library Management System",
    desc:"K.Anitha, K.Divya Bharathi, S.Vishali, AN.Elakkiya are the individuals responsible for making the project a reality.",
    date:"Thiran 2024"
  },
  {
    id:'9',
    coverpic:cover8,
    batch:"ECE 2022-26 Batch",
    name:"Low Cost ECG",
    desc:"S.Archana Parameshwari, S.Jatasri, R.Keerthika are the individuals responsible for making the project a reality.",
    date:"Thiran 2024"
  },
  {
    id:'10',
    coverpic:cover9,
    batch:"EEE 2022-26 Batch",
    name:"Environmental Power Generation",
    desc:"A.Subash Raj, S.Yogeshwaran, S.Vigneshwaran,R.Vijayalakshmi are the individuals responsible for making the project a reality.",
    date:"Thiran 2024"
  },
  {
    id:'11',
    coverpic:cover10,
    batch:"MECH 2021-25 Batch",
    name:"Magnetic Suspension System",
    desc:"A.Sivabala, P.Rajaprashana, V.Bharathan,G.Gobika are the individuals responsible for making the project a reality.",
    date:"Thiran 2024"
  }
]

export{ Thiran_heroimage_carousalinfo,vision,mission_data,jury_awards}