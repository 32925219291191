const Auto_reports=[
    
    {
        'id':"1",
        "head":"2022 - 2026",
        "house":"left",
        "pdf":"https://drive.google.com/file/d/1KmStKg_UZ4Bx1Do310ZNkYxBvrkSw23l/view?usp=sharing"
    },
    {
        'id':"2",
        "head":"Autonomous 23",
        "house":"right",
        "pdf":"https://drive.google.com/file/d/1ixEXEfnowOSKmX3ksv3EfSagWI1omPLI/view?usp=sharing"
    },
    {
        'id':"3",
        "head":"2010 to 2016",
        "house":"left",
        "pdf":"https://drive.google.com/file/d/1s2O5gv3ZY_UFnatRDD-tI1OodPEEI864/view?usp=drive_link"
    },
    
]
export{Auto_reports}